import React, { useState, useEffect } from 'react'
import './style.scss'
import { useLocation } from 'react-router-dom';
import InputField from '../../components/InputField'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import Booking from '../Booking';
import Summary from '../Summary';
import TopUp from '../TopUp';
import PassengerDetails from '../PassengerDetails';
import Payment from '../Payment';
import Done from '../Done';
import PrivateRoute from '../../PrivateRoute';
import Dashboard from '../Dashboard';
import Wallet from '../Wallet';
import { Typography, Button, CircularProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import { addUserRole } from '../../Redux/actions/userActions';
import EditTrip from '../EditTrip';

function createData(menu, create, edit, view, control) {
	return { menu, create, edit, view, control };
}

const agents = [
	{
		id: 1,
		menu: 'My Bookings',
		create: {
			routes: [
				{
					path: '/newbooking',
					element: <Booking head="ADD BOOKING" />,
					exact: true
				},
				{
					path: '/summary',
					element: <Summary head="ADD BOOKING" />,
					exact: true
				},
				{
					path: '/passengerdetails',
					element: <PassengerDetails head="ADD BOOKING" />,
					exact: true
				},
				{
					path: '/payment',
					element: <Payment head="ADD BOOKING" />,
					exact: true
				},
				{
					path: '/done',
					element: <Done head="ADD BOOKING" />,
					exact: true
				},
			],
			selected: false
		},
		view: {
			routes: [
				{
					path: '/dashboard',
					element: <PrivateRoute> <Dashboard head="MY BOOKINGS" /> </PrivateRoute>,
					exact: true
				},
			],
			selected: false
		},
		edit: {
			routes: [
				{
					path: '/edittrip/:id',
					element: <PrivateRoute> <EditTrip head="EDIT TRIP" /> </PrivateRoute>,
        			exact: true
				}
			],
			selected: false,
		},
		delete: {
			routes: [],
			selected: false,
			disabled: true
		},
		full: false
	},
	{
		id: 2,
		menu: 'Wallet',
		create: {
			routes: [
				{
					path: '/topup',
					element: <PrivateRoute> <TopUp head="TOP UP" /> </PrivateRoute>,
					exact: true
				},
			],
			selected: false
		},
		view: {
			routes: [
				{
					path: '/wallet',
					element: <PrivateRoute><Wallet head="MY WALLET" /></PrivateRoute>,
					exact: true
				},
			],
			selected: false
		},
		edit: {
			routes: [],
			selected: false,
			disabled: true
		},
		delete: {
			routes: [],
			selected: false,
			disabled: true
		},
		full: false
	}





	// createData('My Bookings', 'No', 'No', 'Yes', 'Yes'),
	// createData('Wallet', 'No', 'Yes', 'Yes', 'Yes'),
	// createData('Payments', 'Yes', 'Yes', 'No', 'No'),
	// createData('Profile', 'Yes', 'Yes', 'Yes', 'No'),
	// createData('Markup Management', 'No', 'No', 'No', 'No'),
];

const corporate = [
	{
		id: 1,
		menu: 'My Bookings',
		create: {
			routes: [
				{
					path: '/newbooking',
					element: <Booking head="ADD BOOKING" />,
					exact: true
				},
				{
					path: '/summary',
					element: <Summary head="ADD BOOKING" />,
					exact: true
				},
				{
					path: '/passengerdetails',
					element: <PassengerDetails head="ADD BOOKING" />,
					exact: true
				},
				{
					path: '/payment',
					element: <Payment head="ADD BOOKING" />,
					exact: true
				},
				{
					path: '/done',
					element: <Done head="ADD BOOKING" />,
					exact: true
				},
			],
			selected: false
		},
		view: {
			routes: [
				{
					path: '/dashboard',
					element: <PrivateRoute> <Dashboard head="MY BOOKINGS" /> </PrivateRoute>,
					exact: true
				},
			],
			selected: false
		},
		edit: {
			routes: [
				{
					path: '/edittrip/:id',
					element: <PrivateRoute> <EditTrip head="EDIT TRIP" /> </PrivateRoute>,
        			exact: true
				}
			],
			selected: false,
		},
		delete: {
			routes: [],
			selected: false,
			disabled: true
		},
		full: false
	},
];






const Permissions = ({ route }) => {

	const { state } = useLocation()
	const dispatch = useDispatch()

	const { user, roleUpdate, error, loading } = useSelector(state => state.auth)

	useEffect(() => {
		if (roleUpdate) {
			toast.success("Role Updated Successfully");
			dispatch({
				type: RESET_ERROR
			})
		}

		if (error) {
			toast.error(error)
			dispatch({
				type: RESET_ERROR
			})
		}
	}, [roleUpdate, error])


	const [rows, setRows] = useState(state?.rolesinfo?.roles_json?.permissions ? state?.rolesinfo?.roles_json?.permissions : user?.role === "agent" ?  agents : corporate)


	const enableCreate = (id, status) => {
		let rowss = rows.map(row => {
			if (row.id === id) {
				row.create.selected = status
				return row;
			}
			else {
				return row;
			}
		})
		setRows(rowss)
	}

	const enableView = (id, status) => {
		let rowss = rows.map(row => {
			if (row.id === id) {
				row.view.selected = status
				return row;
			}
			else {
				return row;
			}
		})
		setRows(rowss)
	}

	const enableEdit = (id, status) => {
		let rowss = rows.map(row => {
			if (row.id === id) {
				row.edit.selected = status
				return row;
			}
			else {
				return row;
			}
		})
		setRows(rowss)
	}

	const enableFull = (id, status) => {
		let rowss = rows.map(row => {
			if (row.id === id) {
				if (!row.create.disabled) {
					row.create.selected = status
				}
				if (!row.create.disabled) {
					row.view.selected = status
				}
				if (!row.edit.disabled) {
					row.view.selected = status
				}
				row.full = status
				return row;
			}
			else {
				return row;
			}
		})
		setRows(rowss)
	}

	const saveRole = () => {
		let routes = [];

		rows.map(row => {
			if (row.create.selected) {
				routes = [...routes, ...row.create.routes]
			}

			if (row.edit.selected) {
				routes = [...routes, ...row.edit.routes]
			}

			if (row.view.selected) {
				routes = [...routes, ...row.view.routes]
			}
		})

		let datas = {
			user_id: state?.id,
			roles_json: {
				routes: routes,
				permissions: rows
			}
		}

		dispatch(addUserRole(datas))
	}

	return (
		<>
			<TableContainer >
				<Table sx={{ minWidth: 650, border: '1px solid #ccc' }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell align="center" className='tableHead'>Menu</TableCell>
							<TableCell align="center" className='tableHead'>Create</TableCell>
							<TableCell align="center" className='tableHead'>View</TableCell>
							<TableCell align="center" className='tableHead'>Edit</TableCell>
							<TableCell align="center" className='tableHead'>Full Control</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((permission) => (
							<TableRow
								key={permission.menu}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell align="center">
									{permission.menu}
								</TableCell>
								<TableCell align="center">
									{permission.create.selected ?
										<span >
											<CheckBoxIcon style={{ cursor: 'pointer' }} onClick={() => enableCreate(permission?.id, false)} />
										</span> :
										<span>
											{!permission.create?.disabled ? <CheckBoxOutlineBlankIcon style={{ cursor: 'pointer' }} onClick={() => enableCreate(permission?.id, true)} /> : '-'}
										</span>}
								</TableCell>
								<TableCell align="center">
									{permission.view.selected ?
										<span >
											<CheckBoxIcon style={{ cursor: 'pointer' }} onClick={() => enableView(permission?.id, false)} />
										</span> :
										<span >
											{!permission.view?.disabled ? <CheckBoxOutlineBlankIcon style={{ cursor: 'pointer' }} onClick={() => enableView(permission?.id, true)} /> : '-'}
										</span>}
								</TableCell>
								<TableCell align="center">
									{permission.edit.selected ?
										<span >
											<CheckBoxIcon style={{ cursor: 'pointer' }} onClick={() => enableEdit(permission?.id, false)} />
										</span> :
										<span>
											{!permission.edit?.disabled ? <CheckBoxOutlineBlankIcon style={{ cursor: 'pointer' }} onClick={() => enableEdit(permission?.id, true)} /> : '-'}
										</span>}
								</TableCell>

								<TableCell align="center">
									{permission.full ?
										<span >
											<ToggleOnIcon sx={{ fontSize: 40 }} className='yes' style={{ cursor: 'pointer' }} onClick={() => enableFull(permission?.id, false)} />
										</span> :
										<span>
											<ToggleOffIcon sx={{ fontSize: 40 }} className='no' style={{ cursor: 'pointer' }} onClick={() => enableFull(permission?.id, true)} />
										</span>}
								</TableCell>
							</TableRow>
						))}
					</TableBody>

				</Table>
			</TableContainer>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 20 }}>

				<Button type="submit" variant="filled" disabled={loading} style={{ backgroundColor: '#333333', borderRadius: 4, padding: 5, height: 30 }} onClick={saveRole}>
					{loading ? <CircularProgress /> : <Typography className='buttonText'>SAVE</Typography>}
				</Button>
			</div>
		</>
	)
}

export default Permissions
