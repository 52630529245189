import React, { useState, useEffect } from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import CutomPages from '../CutomPages';
import { API_URL, BASE_URL } from '../../config/Constants'
import axios from 'axios';
import CartPageDetails from './CartPageDetails';

const ContactUs = () => {
     const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(true)
    
    // useEffect(() => {
        
    //     localStorage.removeItem("cartData")
    // }, [])
    return (
        <Layout head={"My Cart"} details={details}>
            
            <CartPageDetails/>
           
        </Layout>
    )
}

export default ContactUs
