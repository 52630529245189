import React, { useState, useEffect } from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import GolfHolidayDetails from './GolfHolidayDetails';

const SearchDetailsPage = () => {
    const [details, setDetails] = useState({'title':'GolfHoliday','meta_desc':'GolfHoliday details','meta_keyword':'GolfHoliday , golftripz , GolfHoliday'})
    return (
        <Layout head={"GolfHoliday Details"} type='golfHoliday' details={details}>
            <GolfHolidayDetails count={5}/>
        </Layout>
    )
}

export default SearchDetailsPage;
