import React from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import CustomerForm from './CustomerForm';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Margin } from '@mui/icons-material';
import { Button, Grid, Typography, Stack } from '@mui/material'


const CustomerRegistration = () => {

    return (
        <div>
          
            <CustomerForm/>   
        
        </div>
    )
}

export default CustomerRegistration
