import React, { useRef, useState } from 'react'
import { FormGroup } from '@mui/material';
import { TextField, Typography } from '@mui/material'
import { Controller } from "react-hook-form";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { getValue } from '@mui/system';
import { makeStyles, styled, withStyles } from '@mui/styles';
import { indexOf, throttle } from 'lodash';



const useStyles = makeStyles(() => ({
	noBorder: {
		borderRadius: 0,
		border: 0,
	},
	resize: {
		fontSize: 12,
		paddingLeft: 2
	}
}));
const SearchBox = styled(TextField)(() => ({
	'& input': {
		paddingLeft: '4px',
		height: '0px',
		paddingLeft: 10
	},
	'& fieldset': {
		borderRadius: '4px',
	},
}));

const 	PlacesInput = ({ setClear, fieldName, clear, fieldType, fieldLabel, placeholder,mode, width, fontSize, control, error, children, onPlaceChange, marginRight }) => {

	const classes = useStyles();

	const inputRef = useRef();
	const input = useRef(null)
	const [address, setAddress] = useState('')
	
	//const throttledAutocomplete = throttle(onchangeValue, 500);


	const handlePlaceChanged = () => {

		

		const [place] = inputRef.current.getPlaces();


			let index = place?.address_components?.map((res)=> res).map((res)=> res?.types.includes('country')).indexOf(true)
			let result = place?.address_components?.[index]

		

		if (place) {
		
			let details = {
				address:  address,
				latitude: place.geometry.location.lat(),
				longitude: place.geometry.location.lng(),
				type: place.types.includes('airport') ? 'airport' : 'point',
				countryCode:result?.short_name,
				countryName:result?.long_name,
				offset: place?.utc_offset,
				place_id: place?.place_id
			}
			onPlaceChange(details)
		}
	}


	const onchangeValue = (e) => {
		let { value } = e.target

		onPlaceChange(null)
		setAddress(value)
	}


	React.useEffect(() => {
	if(clear){
      setAddress('');
	  setClear(false)
	}
	}, [clear])



	

	return (
		<FormGroup style={{ marginRight: 5, marginLeft: 5, marginBottom: 1 }} >
			<Typography sx={{ fontSize: '12px' }} >{fieldLabel}</Typography>
			<Controller
				name={fieldName}
				control={control ? control : null}
				render={({ field: { onChange, onBlur, value } }) => {
			
					return (
						<StandaloneSearchBox
							onLoad={ref => inputRef.current = ref}
							onPlacesChanged={handlePlaceChanged}
						>
							<TextField
								fullWidth
								onChange={onchangeValue}
								onBlur={onchangeValue}
								ref={input}
								value={mode &&  mode === 'vip' ? address :  value?.address}
								error={error?.message ? true : false}
								defaultValue={ value?.address}
								variant='standard'
								InputProps={{
									disableUnderline: true,
									classes: {
										input: classes.resize,
										//root: classes.root
									}
								}}
								className='formText'
								style={{ backgroundColor: '#fff', paddingLeft: 5, border: '1px solid #ccc', borderRadius: 4, width: '98%', height: 30, justifyContent: 'center' }}
								sx={{
									"& .MuiFilledInput-underline:before": {
										borderBottom: "none"
									},
									"& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
										borderBottom: "none"
									},
									borderRadius: '0px'
								}}
								type={fieldType} placeholder={fieldLabel}
							>
								{children}
							</TextField>
						</StandaloneSearchBox>
					)
				}}
			/>
			{error && <Typography color={"red"} fontSize={10}>{error?.message}</Typography>}
		</FormGroup>
	)
}

export default PlacesInput