import React, { useEffect, useState ,useRef} from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'
import { faArrowLeft, faArrowRight, faStar, faLessThan, faGreaterThan, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faPlane, faBuilding, faCar, faHotTub, faHamburger, faGolfBall, faHotel, faUtensils, faCalendarAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { API_URL, BASE_URL } from '../../config/Constants'
import { useDispatch, useSelector } from 'react-redux'
import Noimage from "../../assets/images/no-img.png";
import { toast } from 'react-toastify';
const GolfHolidayReviewForm = () => {

    const { user } = useSelector(state => state.auth)
    const { selectedGolfHoliday, bookingData } = useSelector(state => state.booking)
    const [activeBox, setActiveBox] = useState(null);
    const [hoverRating, setHoverRating] = useState(0);
    
    const [name, setName] = useState(user?.name);
    const [headline, setHeadline] = useState('');
    const [rating, setRating] = useState(0);
    const [file, setFile] = useState('');
    const [reviewText, setReviewText] = useState('');

    const handleBoxClick = (index) => {
        setActiveBox(index);
    };
    const handleStarClick = (value) => {
        setRating(value);
      };
    
      const handleStarMouseEnter = (value) => {
        setHoverRating(value);
      };
    
      const handleStarMouseLeave = () => {
        setHoverRating(0);
      };
      
 
    const handleSubmit = () => {
       
        // if (activeBox !== null) {
        //     alert(`You have selected review score: ${activeBox}`);
        // } else {
        //     alert('Please select a review score before submitting.');
        // }

        // if (reviewText.trim()) {
        //     alert(`Review submitted: ${reviewText}`);
        //     setReviewText('');
        // } else {
        //     alert('Please enter a review before submitting.');
        // }
        if(!user){
            alert("Please Login to Review");
        }else{
            console.log(user)

            let formData = new FormData();
            if (file && file !=='') {
                formData.append("file", file);
            }
    
            formData.append("name", name);
            formData.append("contact_no", user.mobile);
            formData.append("email", user.email);
            formData.append("rating", rating);
            formData.append("event_id", selectedGolfHoliday.golf_holidays.id);
            formData.append("user_id", user.id);
            formData.append("type_id", 4);
            formData.append("message", reviewText);
            formData.append("headline", headline);


            axios(API_URL+'/add-testimonial',{
                method: 'POST',
                data: formData,
                headers: {
                 // 'Authorization': `bearer ${token}`,
                 'Content-Type': 'application/json'
               }
              })
                .then(function(response) {
                    // return response.json()
                  //   console.log(response.data)
                  toast.success(response.data.message)
                  //   alert(golfContents[0].body);
                  }).then(function(body) {
                    console.log(body);
                  });
        }
    };
    const handleInputChange = (e) => {
        if(!user){
            alert("Please Login to Review");
        }else{
            setReviewText(e.target.value);
        }
        
    };
    const fileRef = useRef(null)
    const [preview, setPreview] = useState(null);
    const [preview2, setPreview2] = useState(null);

    const handleFileChange = (e) => {
      let image = e.target.files && e.target.files[0];
      
      if (!image) return;
      
      if (image.size > 2e6) {
        alert('Please upload a file less than 2 MB');
        return; 
      }
      
      const fileExtension = image.name.split('.').pop().toLowerCase();
      const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
      const isVideo = ['mp4', 'mov', 'avi', 'mkv'].includes(fileExtension);
      const objectUrl = URL.createObjectURL(image);
      
      setFile(image);
      
      if (isImage) {
        setPreview2(null);
        setPreview(objectUrl);
      } else if (isVideo) {
        setPreview(null);
        setPreview2(objectUrl);
      }
    };

    return (
        <>
       <div className="unique-review-section">
                        <h4>Write a Review</h4>
                        <div className="unique-input-container">
                          <div className="unique-input-wrapper">
                            <label>Add Your Name</label>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter your name here..." />
                          </div>
                          <div className="unique-input-wrapper">
                            <label>Add a Headline</label>
                            <input type="text" onChange={(e) => setHeadline(e.target.value)} value={headline} placeholder="Enter your headline here..." />
                          </div>
                        </div>
                        <div className='combo-divmerge'>
                          <div className="unique-rating-container">
                            <label>Overall Ratings</label>
                            <div className="unique-star-wrapper">
                              {[1, 2, 3, 4, 5].map((value) => (
                                <FontAwesomeIcon
                                  key={value}
                                  icon={faStar}
                                  onClick={() => handleStarClick(value)}
                                  onMouseEnter={() => handleStarMouseEnter(value)}
                                  onMouseLeave={handleStarMouseLeave}
                                  className={value <= (hoverRating || rating) ? 'unique-star selected' : 'unique-star'}
                                />
                              ))}
                            </div>
                          </div>
                          <div className="unique-upload-container">
                            <label>Add a Photo or Video</label>
                            <div onClick={() => fileRef.current.click()} className="unique-upload-box">
                              <FontAwesomeIcon icon={faPlus} />
                              <input
                                  style={{ display: 'none' }}
                                  ref={fileRef}
                                  type="file"
                                  onChange={handleFileChange}
                              />
                            </div>
                            {preview && preview !== '' && (
                              <div style={{'height':'50%' , 'width':'50%'}}>
                              <img src={preview }  alt='alt' className="profile-img" onError={(e) => { e.target.onerror = null; e.target.src = Noimage; }} />
                              </div>
                            )}
                            {preview2 && preview2 !== '' && (
                              <div style={{'height':'50%' , 'width':'50%'}}>
                              <video 
                              src={preview2} 
                              className="profile-video" 
                              controls 
                              style={{ maxHeight: '100%', maxWidth: '100%' }}
                              >
                                Your browser does not support the video tag.
                              </video>
                              </div>
                            )}
                            </div>
                        </div>
                        <div className="unique-textarea-container">
                          <textarea placeholder="Enter your review here..." onChange={handleInputChange}/>
                        </div>
                        <button className="unique-submit-button" onClick={handleSubmit}>Submit Review</button>
                      </div>
    </>
    )
}
export default GolfHolidayReviewForm;