import React, { useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
    IconButton, Modal, Box, Badge
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTimes ,faDownload ,faChevronUp ,faChevronDown ,faUser, faEdit} from '@fortawesome/free-solid-svg-icons';
import { CURRENCY_CODE } from '../../config/Constants'
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const IPBookingList = () => {
    const { ipLists, deleteSuccess, error } = useSelector(state => state.trip)
    const [selectedRow, setSelectedRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    useEffect(()=>{
       
    },[])
    const getFormattedPhone = (number) => {
        const phoneNumberObj = parsePhoneNumberFromString(`+${number}`);
        
        if (phoneNumberObj) {
            const countryCode = phoneNumberObj.countryCallingCode;
            const mobileNumber = phoneNumberObj.nationalNumber;
            return `+${countryCode} ${mobileNumber}`;
        } else {
            return number;
        }
    };
    
    const calculateBasePrice = () => {
        var baseprice = 0
        var courebaeprice = selectedRow?.details.map(item => {
            baseprice += parseInt(item.golf_course_price) * parseInt(item.total_qolfers)
        })
        return baseprice?.toFixed(2);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const [gatewaylist, setGateways] = useState([]);
    const handleOpen = (row) => {
        setSelectedRow(row);
        setOpen(true);
        var gateways=[]
        row?.transaction_details?.map(item =>{
            gateways.push(item?.payment_gateway_name)
        })
        const uniqueGateways = Array.from(new Set(gateways));
        setGateways(uniqueGateways)
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedRow(null);
    };
    const mailtabletotal = (details)=>{
        var total =0;
      
        details?.map(det=>{
            total += parseInt(det?.golf_course_price) * parseInt(det.total_qolfers);
        })
       
        return total?.toFixed(2);
    }
     
    const calculateConvineanceFeeTotal = () => {
        var C_price = 0
        var courebaeprice = selectedRow?.details.map(item => {
            C_price += parseInt(item.convenience_fee)
        })
        return C_price?.toFixed(2);
    }
    const DownloadPdf = (url) => {
      
        var link = document.createElement('a');
                link.href = url;
                link.target = '_blank'
                link.setAttribute('download', 'invoice.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
    }

    return(
        <div className='mybook-con'>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440, overflowX: 'auto' }}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 1200 }}>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                            <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Booking ID</TableCell>
                            <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Booking Ref No</TableCell>
                            <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Pack Name</TableCell>
                            <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Booking Date</TableCell>
                            <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Price</TableCell>
                            <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Booked By</TableCell>
                            <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Phone</TableCell>
                            <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Courses</TableCell>
                            <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Hotels</TableCell>
                            <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Payment Status</TableCell>
                            <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Booking Status</TableCell>
                            <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ipLists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }} className='book-tr-td'
                            >
                                <TableCell className='book-td-grey' sx={{ whiteSpace: 'nowrap' }}>{row.id}</TableCell>
                                <TableCell className='book-td-grey' sx={{ whiteSpace: 'nowrap' }}>{row?.booking_ref_number}</TableCell>
                                <TableCell className='book-td-grey' sx={{ whiteSpace: 'nowrap' }}>{row?.pack_name}</TableCell>
                                <TableCell className='book-td-black' sx={{ whiteSpace: 'nowrap' }}>{row?.booking_date}</TableCell>
                                <TableCell className='book-td-black' sx={{ whiteSpace: 'nowrap' }}>{row?.currency} {row?.total_price?.toFixed(2)}</TableCell>
                                <TableCell>
                                    {row?.customer_name}
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}>{getFormattedPhone(row?.customer_phone)}</TableCell>
                                <TableCell className='tb-user-icon' sx={{ whiteSpace: 'nowrap' }}><FontAwesomeIcon icon={faUser} /> {row?.selected_course_relation?.golf_course_name}</TableCell>
                                <TableCell className='tb-user-icon' sx={{ whiteSpace: 'nowrap' }}><FontAwesomeIcon icon={faUser} /> {row?.selected_hotel_relation?.hotel_name}</TableCell>
                               
                                <TableCell >
                                    <span style={{color:"blue"}}>
                                        {row.payment_status === 1 ? (
                                            <Badge className="badge-format unconformed"  badgeContent="Pending" />
                                        ) : row.payment_status === 2 ? (
                                            <Badge className="badge-format p-conformed"  badgeContent="Partially Paid" />
                                        ) : row.payment_status === 3 ? (
                                            <Badge className="badge-format conformed"  badgeContent="Paid" />
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </TableCell>

                                <TableCell className='book-td-green' sx={{ whiteSpace: 'nowrap' }}>
                                    <span style={{ color: 'primary' }}>
                                        {row.status === 1
                                            ?<Badge className='badge-format unconformed'  badgeContent="Unconfirmed">
                                           
                                          </Badge>
                                            : row.status === 2
                                                ? <Badge className='badge-format p-conformed' badgeContent="Partially Confirmed">
                                              </Badge>
                                                : row.status === 3
                                                    ? <Badge className='badge-format conformed'  badgeContent="Confirmed"></Badge>
                                                    : <Badge className='badge-format cancelled'  badgeContent="Cancelled"></Badge>}
                                    </span>
                                </TableCell>

                                <TableCell style={{ cursor: "pointer" }}className='book-td-green' sx={{ whiteSpace: 'nowrap' }} onClick={() => handleOpen(row)}>
                                    <FontAwesomeIcon icon={faEye}  />{row.actions}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={ipLists.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </Paper>

        <Modal open={open} onClose={handleClose}>
            <div className='dashboard-popup-box'>
                <Box className="modal-box">
                    {selectedRow && (
                        <>
                            <h2 className="modal-header">Booking History</h2>
                            <button className="close-button" onClick={handleClose}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <div className="modal-content">
                                <div className="modal-section">
                                    <p><strong>Name:</strong> {selectedRow?.customer_name || 'N/A'}</p>
                                    <p><strong>Nationality:</strong> {selectedRow?.customer_nationality || ''}</p>
                                </div>
                                <div className="modal-section">
                                    <p style={{'textTransform' : 'unset'}}><strong>Email:</strong> {selectedRow?.customer_email || 'N/A'}</p>
                                  
                                </div>
                                <div className="modal-section">
                                    <p><strong>Mobile No:</strong> {getFormattedPhone(selectedRow?.customer_phone) || 'N/A'}</p>
                                   
                                </div>
                                <div className="modal-section">
                                    <p><strong>Booking Ref No:</strong> {selectedRow?.booking_ref_number || ''}</p>
                                </div>
                               
                            </div>
                            <div className="modal-details">
                                <h3>Course Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>Course ID</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Confirmation No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Status</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Tee Time</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Course Name</TableCell>
                                                <TableCell sx={{ color: 'white' }}>No of Golfers</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Golf Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Total Price</TableCell>
                                                
                                              
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedRow?.selected_course_relation?.map((course) => (
                                                <TableRow>
                                                    <TableCell>{course?.id || 'N/A'}</TableCell>
                                                   
                                                    <TableCell>{course?.booking_confirmation_number}</TableCell>
                                                    <TableCell><span style={{ color: 'primary' }}>
                                                        {course.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : course.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : course.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                    </span>
                                                    </TableCell>
                                                    <TableCell>{moment(course?.date).format("DD-MM-YYYY")}</TableCell>
                                                    <TableCell>{course?.tee_time}</TableCell>
                                                    <TableCell>{course?.golf_course_name}</TableCell>
                                                    <TableCell>{course?.no_of_golfers}</TableCell>
                                                    <TableCell>{selectedRow?.currency} {course?.price_of_golfers?.toFixed(2)}</TableCell>
                                                    <TableCell>{selectedRow?.currency} {(course?.price_of_golfers * course?.no_of_golfers).toFixed(2)}</TableCell>
                                                   
                                                   
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <h3>Hotel Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>Course ID</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Confirmation No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Status</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Check-In-Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Check-Out-Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Hotel Name</TableCell>
                                                <TableCell sx={{ color: 'white' }}>No of Adults</TableCell>
                                                <TableCell sx={{ color: 'white' }}>No of Child</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Room Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Total Price</TableCell>
                                                
                                              
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedRow?.selected_hotel_relation?.map((hotel) => (
                                                <TableRow>
                                                    <TableCell>{hotel?.id || 'N/A'}</TableCell>
                                                   
                                                    <TableCell>{hotel?.booking_confirmation_number}</TableCell>
                                                    <TableCell><span style={{ color: 'primary' }}>
                                                        {hotel.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : hotel.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : hotel.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                    </span>
                                                    </TableCell>
                                                    <TableCell>{moment(hotel?.check_in_date).format("DD-MM-YYYY")}</TableCell>
                                                    <TableCell>{moment(hotel?.check_out_date).format("DD-MM-YYYY")}</TableCell>
                                                    <TableCell>{hotel?.hotel_name}</TableCell>
                                                    <TableCell>{hotel?.adults}</TableCell>
                                                    <TableCell>{hotel?.child}</TableCell>
                                                    <TableCell>{selectedRow?.currency} {hotel?.room_price?.toFixed(2)}</TableCell>
                                                    <TableCell>{selectedRow?.currency} {(hotel?.total_price).toFixed(2)}</TableCell>
                                                   
                                                   
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                
                                <h3>Invoice Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>S.no</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Reference No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Service Type</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Customer Name</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Email</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Total Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedRow?.payment_details?.length >0 ?selectedRow?.payment_details?.map((invoice ,index) => (
                                                <TableRow>
                                                    <TableCell>{index +1}</TableCell>
                                                    <TableCell>{invoice?.booking_reference_number}</TableCell>
                                                    <TableCell>{invoice?.invoice_number}</TableCell>
                                                    <TableCell>{moment(invoice?.invoice_date).format("DD-MM-YYYY")}</TableCell>
                                                    <TableCell>{invoice?.service_type}</TableCell>
                                                    <TableCell>{invoice?.customer_name}</TableCell>
                                                    <TableCell>{invoice?.customer_email}</TableCell>
                                                    <TableCell>{selectedRow?.currency} {invoice?.invoice_price?.toFixed(2)}</TableCell>
                                                    <TableCell>{selectedRow?.currency} {invoice?.total_price?.toFixed(2)}</TableCell>
                                                    <TableCell>
                                                    <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => DownloadPdf(invoice?.invoice_pdf)} icon={faDownload} />
                                                    {' '}
                                                    {invoice?.payment_link && invoice?.payment_link !== '' && (
                                                        <a target='_blank' href={invoice?.payment_link}>Pay</a>
                                                    )}
                                                    </TableCell>
                                                </TableRow>
                                            )):
                                            <TableRow>
                                                <TableCell colSpan={9} style={{ textAlign: 'center' }}>No invoice Informations found</TableCell>
                                            </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <h3>Transaction Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>S.no</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice Number</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Ref ID</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Date	</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Payment Gateway</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Currency</TableCell>
                                                {/* <TableCell sx={{ color: 'white' }}>payment_remarks</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Action</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedRow?.transaction_details?.length >0 ?
                                            // selectedRow?.transaction_details?.map((transaction ,index) => (
                                                <TableRow>
                                                   <TableCell>1</TableCell>
                                                    <TableCell>{selectedRow?.transaction_details?.map(item => item.invoice_number).join(', ')}</TableCell>
                                                    <TableCell>{selectedRow?.transaction_details?.map(item => item.transaction_reference_number).join(', ')}</TableCell>
                                                  
                                                    <TableCell>{selectedRow?.transaction_details?.map(item => moment(item.transaction_date).format('DD-MM-YYYY')).join(', ')}</TableCell>
                                                    <TableCell style={{textTransform:"capitalize"}}>{gatewaylist?.map(item => item).join(', ')}</TableCell>
                                                    <TableCell>{selectedRow?.payment_details[0]?.paid_amount.toFixed(2)}</TableCell>
                                                    <TableCell>{selectedRow?.payment_details[0].currency}</TableCell>
                                                    {/* <TableCell>{transaction?.payment_remarks}</TableCell> */}
                                                    {/* <TableCell style={{cursor: "pointer"}} onClick={()=>DownloadPdf(transaction?.receipt_pdf)}> <FontAwesomeIcon icon={faDownload}  /></TableCell> */}
                                                </TableRow>
                                            :
                                            <TableRow>
                                                <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Transaction Information found</TableCell>
                                            </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className='myacc-floatright'>
                                    <h4>Fare Details</h4>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th colSpan="4">Base Price</th>
                                                {/* <td>${selectedRow?.details?.reduce((sum, prod) => sum + prod.total_price, 0) || 'N/A'}</td> */}
                                                <td style={{"textAlign":"right"}}>{selectedRow?.currency} {selectedRow?.total_price.toFixed(2)}</td>
                                            </tr>
                                            {/* <tr>
                                                <th>Convenience Fee</th>
                                                <td>{CURRENCY_CODE} {calculateConvineanceFeeTotal()}</td>
                                            </tr> */}
                                            <tr>
                                                <th colSpan="4">Total</th>
                                                {/* <td>{CURRENCY_CODE} {(parseInt(calculateBasePrice()) + parseInt(calculateConvineanceFeeTotal()))?.toFixed(2)}</td> */}
                                                <td style={{"textAlign":"right"}}>{selectedRow?.currency} {selectedRow?.total_price.toFixed(2)}</td>
                                            </tr>
                                            {selectedRow?.transaction_details && selectedRow?.transaction_details?.length >0 && (
                                            <tr>
                                                <th colSpan="4">Paid Price</th>
                                                <td style={{"textAlign":"right"}}>{selectedRow?.currency} {selectedRow?.transaction_details.reduce((total, detail) => total + detail?.transaction_price, 0).toFixed(2)}</td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}
                </Box>
            </div>
        </Modal>
    </div>
    )
}
export default IPBookingList