import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import './style.scss'
import DatePicker from 'react-datepicker';
import successpg from '../../assets/images/successpg.gif'
import cached from '../../assets/images/cached.png'
import { useForm } from 'react-hook-form';


import contactus_sideimg from '../../assets/images/contactus_sideimg.png'


const ContactContent = ({ pageContents, loading }) => {
    const {courseBookingInputs,coureBookingSuccess,coursebookingresponce,holidaybookingresponce ,instantbookingresponce} =useSelector(state => state.booking)
    const [bookingDetails, setbookingDetails] = useState();
    const [bookingId, setbookingId] = useState();
    const navigation = useNavigate();
    const Home = () => {
        setAnchorElNav(null);
        navigation('/Home')
    };
    useEffect(() => {
       console.log("Coure",coursebookingresponce)
       console.log("holiday",holidaybookingresponce)
       console.log("instant",instantbookingresponce)
        if( coursebookingresponce !==undefined){
            setbookingDetails(coursebookingresponce)
            setbookingId(coursebookingresponce?.data?.booking?.booking_ref_number)
        }

        if( holidaybookingresponce !==undefined){
            setbookingDetails(holidaybookingresponce)
            setbookingId(holidaybookingresponce?.data?.booking_details?.booking_ref_number)
           }
        
        if( instantbookingresponce !==undefined){
            setbookingDetails(instantbookingresponce)
            setbookingId(instantbookingresponce?.data?.booking_details?.booking_ref_number)
        }
        if(coursebookingresponce === undefined && holidaybookingresponce === undefined && instantbookingresponce === undefined ){
            navigation('/')
        }
       }, [coursebookingresponce,holidaybookingresponce,instantbookingresponce])
    
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const { user } = useSelector(state => state.auth);


    // -------------------------------------------
    
    // ----date--------------
    const [startDate, setStartDate] = useState(new Date());


    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        console.log(data);
        alert('Form submitted successfully!');
    };

    return (
        <div className='listitem'>
            {/* <div className="home-content">
                <div className="search-box">
                    <div className="search-option">
                        <label className='search-lab' for="#"><span><svg xmlns="http://www.w3.org/2000/svg" width="16"
                            height="16" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M12.0009 13.4304C13.724 13.4304 15.1209 12.0336 15.1209 10.3104C15.1209 8.5873 13.724 7.19043 12.0009 7.19043C10.2777 7.19043 8.88086 8.5873 8.88086 10.3104C8.88086 12.0336 10.2777 13.4304 12.0009 13.4304Z"
                                stroke="white" stroke-width="1.5" />
                            <path
                                d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z"
                                stroke="white" stroke-width="1.5" />
                        </svg></span></label>
                        <input type="text" placeholder='Australia, Sydney Golf Course' />
                    </div>
                    <div className="date-pick">
                        <label htmlFor="date-picker" id='date-picker'>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                    <path d="M8 2V5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M16 2V5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M3.5 9.08997H20.5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M15.6937 13.7H15.7027" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M15.6937 16.7H15.7027" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M11.9945 13.7H12.0035" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M11.9945 16.7H12.0035" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M8.29529 13.7H8.30427" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M8.29529 16.7H8.30427" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </span>
                        </label>
                        <DatePicker
                            // id="date-picker"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd - MM - yy"
                            placeholderText="12 - 04 - 24"
                        />
                    </div>
                    <div className="search-btn">
                        <button>Search</button>
                    </div>
                </div>
            </div> */}
            <div>
                <div className='successpg-img'>
                    <img src={successpg} alt="gif-img" />
                </div>
                <div className='successpg-con'>
                    <h4>Thank you for Enquiry</h4>
                    <h5>Your Booking reference ID is <span>{bookingId}</span></h5>
                    <p>Check out more of our packages or just build your own package</p>
                    <button onClick={Home}>Keep Golfing</button>
                </div>
            </div>
        </div>
    )
}

export default ContactContent
