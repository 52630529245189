import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants'
import {getGolfHolidayResults } from '../../Redux/actions/bookingActions'
import Layout from '../Layout';
import GolfExperianceView from './GolfExperianceView';
import { useLocation, useNavigate , useParams } from 'react-router-dom';

const GolfExperiencesList = () => {
    const [details, setDetails] = useState({'title':'Golftripz Events','meta_desc':'Golftripz Events','meta_keyword':'golftripz , events'})
    const dispatch = useDispatch();
    const location = useLocation()
    const { country } = useParams();
    useEffect(() => {
        if (location.pathname.startsWith("/golf-experiences/")) {
            const country = location.pathname.split("/golf-experiences/")[1];
            functionForCountry(country);
        }
        else{
            getExperiances()
        }
       
     }, [country]);

     const functionForCountry = async(country) => {
        const Apidata = {
            search: country,
        };
             await  dispatch(getGolfHolidayResults(Apidata));
     }
     
     const getExperiances=async()=>{
       let bookinData = JSON.parse(localStorage.getItem("holidaysearch"));
       var searchdate=new Date();
       if(bookinData?.holidaystartdate){
           searchdate =bookinData?.holidaystartdate;
       }
       const ApiData = {
           'search':bookinData?.holidaysearch,
           'date':new Date(searchdate).toISOString().split('T')[0]
       }
        dispatch({
            type: BOOKING_DATA,
            payload: ApiData
        })
        await  dispatch(getGolfHolidayResults(ApiData));
     }
    return (
        <Layout head={"Golf Experiences"} details={details} type="golfExperiance">
            <GolfExperianceView/>
        </Layout>
    )
}

export default GolfExperiencesList;
