import axios from 'axios';
import { API_URL, BASE_URL } from './config/Constants'
import { RESET_USER } from './Redux/constants/userConstants';
import store from './Redux/store'
// const axios = require('axios');

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const customAxios = axios.create({
    baseURL: API_URL,
    timeout: 30000
});


// Step-2: Create request, response & error handlers
const requestHandler = request => {
    let token = localStorage.getItem("token");

    // // Token will be dynamic so we can use any app-specific way to always   
    // // fetch the new token before making the call
    if (token) {
        request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
};

const responseHandler = response => {

    if (response.status === 403) {
        localStorage.removeItem("user");
        localStorage.removeItem("profileData");
        store.dispatch({
            type: RESET_USER
        })
    }


    return response;
};

const errorHandler = async error => {

    let err = {
        message: '',
        status: error.response.status
    }
    if (error.response) {
        err.message = JSON.stringify(error.response.data)

        if (error.response.status === 403 || error.response.data.message === 'Unauthenticated.') {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            store.dispatch({
                type: RESET_USER
            })
        }
        if (error.response.data.message) {
            err.message = error.response.data.message
        }
        return Promise.reject(err);

    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        err.message = JSON.stringify(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        err.message = error.message;
    }
    return Promise.reject(err);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);


// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;