import BookingDetails from '../Booking/BookingDetails'
import React from 'react'
import { Container, Grid, Button, Card } from '@mui/material';

const BookingForm = () => {

  return (
    <Container maxWidth="lg" className='searchbx-con'>
      <Grid xs={12} md={12} boxShadow={0} >
        <BookingDetails />
      </Grid>
    </Container>
  )
}

export default BookingForm