import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { Button, Grid, Typography, FormGroup, Snackbar, Alert } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';
import reg_bg from '../../assets/images/reg_bg.png'
import logo from '../../assets/images/golfnewlogo.png'
import goo from '../../assets/images/goo.png'
import fb from '../../assets/images/fb.png'
import insta from '../../assets/images/insta.png'

import Banner from '../../components/Banner';


const CustomerForm = () => {

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [firstname, setFirstname] = React.useState(false);
    const [lastname, setLastname] = React.useState(false);
    const [email, setEmail] = React.useState(false);
    const [phone, setPhone] = React.useState(false);
    const [password, setPassword] = React.useState(false);
    const [country, setCountry] = useState(null);
    const [lead_nationality, setleadNationality] = useState("");
    const [nationalities , setNationalities] = useState([]);
    const {userCurrencyCode , currencyRate , dbCountry} = useSelector(state => state.currency);
    const [showerror, setShowError] = useState(null);      

    useEffect(() => {
        if(dbCountry){
            setNationalities(dbCountry)
    }
      }, [dbCountry]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const { registerSuccess, error } = useSelector(state => state.auth)

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const schema = yup.object({
        firstname: yup.string().required('First name is required').max(100, 'Maximum character exceeded'),
        lastname: yup.string().required('Last name is required').max(100, 'Maximum character exceeded'),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required').max(30, 'Maximum character exceeded'),
        email: yup.string().email('Invalid email format').required('Email is required').max(150, 'Maximum character exceeded'),
        password: yup.string().required('Password is required').min(8, 'Password should contain atleast 8 characters').max(30, 'Maximum character exceeded'),
        confirm_password: yup.string().when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref("password")], "Both password need to be the same"),
        })
            .required("Confirm Password Required"),
    }).required();

    const { register, handleSubmit, control, reset, formState: { errors },setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            is_corporate: 1,
            phone: '',
            mobile: '',
            password: '',
            email: '',
            firstname: '',
            lastname: '',
            confirm_password: ''
        },
        mode: 'onChange'
    });
    const navigate = useNavigate();
    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (registerSuccess) {
            setOpen(true)
            reset();
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, registerSuccess])

    const changePhone = (value) => {
        setCountry(value)
    }

    const onSubmit = (data) => {

        let formData = new FormData();
        formData.append("email", data.email);
        formData.append("name", data.firstname);
        formData.append("lastname", data.lastname);
        formData.append("phone", country.phonenumber);
        formData.append("country_code", country.callingCode);
        formData.append("mobile", country.phonenumber);
        formData.append("password", data.password);
        formData.append("user_type", 3);
        formData.append("is_corporate", 1);
        //formData.append("userID", user?.id);

        dispatch(userRegister(formData))
    };
    return (
        <div>
            <Grid className='signup-con'>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Customer successfully registered
                    </Alert>
                </Snackbar>
                <Grid container justifyContent="center" style={{ paddingTop: 30 }} className='cus-log-bg-con' >
                    <Grid className='log-bg-img' item xs={12} md={6} lg={6}>
                        <img src={reg_bg} alt="" />
                    </Grid>
                    <Grid xs={12} md={6} lg={6} padding='1%' sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }} className="customer-form-con">
                        <div className='head_section'>
                            {/* <Grid className='log-ico'>
                                <img src={logo} alt="" />
                            </Grid> */}
                            <div onClick={() => navigate('/')} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: 10 }}><ArrowBackIcon style={{ fontSize: 30 }} /> Back to Home</div>
                        </div>
                        <div className='singup-pg'>
                        <Grid className='log-ico'>
                                <img src={logo} alt="" />
                            </Grid>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid className='reg-sub-h'>
                                    <h2>Sign Up</h2>
                                    {/* <Typography>Be a part of <span className='color-h'>GolfTripz</span> Today</Typography> */}
                                    <Typography>Book 50% Faster using your login details</Typography>
                                </Grid>
                                <FormGroup >
                                    <Grid className='log-input-con' container spacing={0.5} >
                                        <Grid item xs={12} md={6}>
                                            <InputField
                                                placeholder="Enter your First name here"
                                                fieldLabel="First Names"
                                                fieldName="firstname"
                                                control={control}
                                                error={errors.firstname}
                                                fieldChange={(e) => {
                                                    setFirstname(e.target.value)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <InputField
                                                placeholder="Enter your Last name here"
                                                fieldLabel="Last Name"
                                                fieldName="lastname"
                                                control={control}
                                                error={errors.lastname}
                                                fieldChange={(e) => {
                                                    setLastname(e.target.value)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <InputField
                                                placeholder="Enter your Email here"
                                                fieldLabel="Email Address"
                                                fieldName="email"
                                                control={control}
                                                error={errors.email}
                                                fieldType="email"
                                                fieldChange={(e) => {
                                                    setEmail(e.target.value)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} className='signup-ph'>
                                            <PhoneInputs
                                                placeholder="Enter your phone number here"
                                                fieldLabel="Phone"
                                                fieldName="phone"
                                                control={control}
                                                error={errors.phone}
                                                onChangePhone={changePhone}
                                                fieldChange={(e) => {
                                                    setPhone(e.target.value)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <InputField
                                                placeholder="Enter your Password here atleast 8 characters"
                                                fieldLabel="Password"
                                                fieldName="password"
                                                control={control}
                                                error={errors.password}
                                                fieldType="password"
                                                fieldChange={(e) => {
                                                    setPassword(e.target.value)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <InputField
                                                fieldLabel="Confirm Password"
                                                fieldName="confirm_password"
                                                control={control}
                                                fieldType="password"
                                                error={errors.confirm_password}
                                                fieldChange={(e) => {
                                                    setPassword(e.target.value)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <div className="form-group signup-cou">
                                                    <label>Country</label>
                                                    <select
                                                        name="lead_nationality"
                                                        onChange={(e) => setleadNationality(e.target.value)}
                                                    >
                                                        <option value="">Select Country</option>
                                                        {nationalities.map((nation, index) => (
                                                            <option key={index} value={nation?.name}>{nation?.name}</option>
                                                        ))}
                                                    </select>
                                                    <div className='dropdownicon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                                            <path d="M17.9202 8.17969H11.6902H6.08024C5.12024 8.17969 4.64024 9.33969 5.32024 10.0197L10.5002 15.1997C11.3302 16.0297 12.6802 16.0297 13.5102 15.1997L15.4802 13.2297L18.6902 10.0197C19.3602 9.33969 18.8802 8.17969 17.9202 8.17969Z" fill="#292D32" />
                                                        </svg>
                                                    </div>
                                                    {showerror && lead_nationality ==='' ? <span className="error">This field is required</span>:'' }
                                                </div>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '2%', paddingBottom: '5%' }}>
                                    <Button type="submit" variant="filled" style={{ backgroundColor: '#333333', borderRadius: 4, padding: 5, height: 30 }}>
                                    <Typography className='buttonText'>Sign Up</Typography></Button>
                                </div>
                                <div className='sing-bot-line'>
                                    <div className='empty-line'></div>
                                    <p>or</p>
                                    <div className='empty-line'></div>
                                </div>
                                <div className='signup-with-go'>
                                    <p>Signup With</p>
                                    <div className='signup-icon'>
                                        <div>
                                            <img src={goo} alt="image"/>
                                        </div>
                                        <div>
                                            <img src={fb} alt="image"/>
                                        </div>
                                        <div>
                                            <img src={insta} alt="image"/>
                                        </div>
                                    </div>
                                    <h5><span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM18.36 16.83C16.93 15.09 13.46 14.5 12 14.5C10.54 14.5 7.07 15.09 5.64 16.83C4.62 15.49 4 13.82 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.82 19.38 15.49 18.36 16.83ZM8.5 9.5C8.5 7.56 10.06 6 12 6C13.94 6 15.5 7.56 15.5 9.5C15.5 11.44 13.94 13 12 13C10.06 13 8.5 11.44 8.5 9.5Z" fill="#684B2A" />
                                    </svg></span>Continue as Guest</h5>
                                    <div className='already-acc'>
                                          <p>Already Have a Account<a href="">Login?</a></p>      
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Grid>
                </Grid>
                </Grid>
        </div>
    )
}

export default CustomerForm
