import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import '../Login/login.scss'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Box } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { BookingLogin, userRegister } from '../../Redux/actions/userActions';
import GuestsImg from '../../assets/images/Icons/guests.png'
import { useNavigate } from "react-router-dom";
import { googleSignIn, loginUsers } from '../../Redux/actions/userActions'
import GoogleImg from '../../assets/images/google.png'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'
import BorderBox from '../../components/BorderBox'

const GuestLogin = () => {
	const dispatch = useDispatch();

	const { loading, isAuthenticated, error } = useSelector(state => state.auth)

	const schema = yup.object({
		email: yup.string().email('Invalid email format').required('Email required').max(30,'Maximum character exceeded'),
		password: yup.string()
			.required('Password required.').max(30,'Maximum character exceeded')
			.min(8, 'Password should contain atleast 8 characters')
	}).required();

	const navigate = useNavigate();
	const { register, handleSubmit, control, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		mode:'onChange'
	});

	const login = useGoogleLogin({
		onSuccess: tokenResponse => {
			getUserProfile(tokenResponse.access_token)
		},
	});


	const getUserProfile = async (token) => {
		await axios
			.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'application/json'
				}
			})
			.then((res) => {
				//setProfile(res.data);
				let data = {
					email: res?.data?.email,
					name: res?.data?.name
				}
				localStorage.setItem("loginType", "google");
				dispatch(googleSignIn(data))
			})
			.catch((err) => toast.error({err}));
	}

	useEffect(() => {
	
		if (error) {
	
			toast.error(error)
			dispatch({
				type: RESET_ERROR
			})
		}
		if (isAuthenticated) {
			navigate("/payment")
		}
	}, [error, isAuthenticated])
	

	const onSubmit = data => {
		localStorage.setItem("loginType", "form");
		dispatch(BookingLogin(data))
	};


	useEffect(() => {
		window.addEventListener('popstate', (e) => {
		  window.history.go(1);
		});
	  }, []);

	return (
		<Grid container justifyContent="center" alignItems={"center"} alignSelf="center" >
			{/* <form onSubmit={handleSubmit(onSubmit)}>
				<BorderBox title={"Login"}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container>
							<Grid item xs={12} md={12}>
								<InputField
									fieldLabel="Email Address"
									fieldName="email"
									control={control}
									error={errors.email}
									width={{ xs: "100%", md: '100%' }}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<InputField
									fieldLabel="Password"
									fieldName="password"
									control={control}
									error={errors.password}
									width={{ xs: "100%", md: '100%' }}
									fieldType={"password"}
								/>
							</Grid>
						</Grid>
					</form>
				</BorderBox>
				<Grid xs={12} md={12} style={{ display: 'flex', justifyContent: 'center', gap: 5 }} paddingTop={2}>
					<Button variant="filled" onClick={() => login()} style={{ backgroundColor: '#4286F5', borderRadius: 4, height: 30, width: 135, padding: 5, margin: 0, paddingLeft: 0.4 }}
						startIcon={<img src={GoogleImg} style={{ width: 22, height: 25, margin: 0, paddingLeft: 5 }} />}>
						<Typography className='buttonText'> Google Sign In</Typography>
					</Button>
					<Button type="submit"
						sx={{ boxShadow: 2 }}
						variant="filled" style={{ backgroundColor: '#333333', borderRadius: 4, width: 135, height: 30, padding: 5 }}
					>
						<Typography className='buttonText'>Login</Typography>
					</Button>

					

				</Grid>
				

			</form> */}
			<BorderBox title={"Login"}>
				<Grid container style={{ display: 'flex', justifyContent: 'center', gap: 5 }}>
					<p>If you a registered customer, kindly <a onClick={() => navigate('/signin')}  href="#">Login here</a> and book again</p>
				</Grid>
				<Grid container style={{ display: 'flex', justifyContent: 'center', gap: 5 }}>
					<p className="loginOr">Or</p>
				</Grid>
			<div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
					<Button onClick={() => navigate('/payment')} variant="filled" style={{ backgroundColor: '#333333', borderRadius: 4, padding: 5, height: 30 }}>
						<Typography className='buttonText'>Proceed as a Guest</Typography></Button>
				</div>
				</BorderBox>
		</Grid>

	)
}

export default GuestLogin
