import React, { Fragment, useContext, useEffect } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import './style.scss'
import { Card, Typography } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { height } from '@mui/system';
import LocationImg from '../../assets/images/Icons/placeholder.png'
import TimeImg from '../../assets/images/Icons/timetable.png'
import DistanceImg from '../../assets/images/Icons/measure-distance.png'
import { useLocation, useNavigate, UNSAFE_NavigationContext } from 'react-router-dom';
import SelectedVehicle from '../../components/SelectedVehicle'
import TopBar from '../../components/TopBar'
import PaymentDetails from './PaymentDetails'
import { useDispatch, useSelector } from 'react-redux'
import AllTripsPayments from './AllTripsPayments'
import Bookings from '../../components/Bookings'
import AddedVehicle from '../../components/AddedVehicle'
import { RESET_BOOKING_DATA } from '../../Redux/constants/bookingConstants'
const Payment = ({ head, history }) => {
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    //const { selectedVehicle, orderData } = useSelector(state => state.booking)
    const { selectedBooking: booking, currentVehicle: data, orderData } = useSelector(state => state.booking)


    useEffect(() => {
        dispatch({
            type: RESET_BOOKING_DATA
        })
    }, [])


    useEffect(() => {
        if (!orderData) {
            navigate("/newbooking");
        }
    }, [orderData])
    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        window.onpopstate = () => {
            navigate("/payment");
        }
    }, []);

    return (
        <Layout head={head}>
            {(user) &&
                <WorkFlow activeStep={3} />
            }
            {(!user) &&
                <WorkFlow activeStep={3} />
            }
            {/* <SelectedVehicle data={data} booking={booking} /> */}
            {/* <AllTripsPayments /> */}
            <Grid container  spacing={2} className="container_topspace" >
             
                <Grid item xs={12} md={12}>
                   <h4 class='underline'>Payment Details</h4>
                   
                    <PaymentDetails />
                </Grid>

            </Grid>


        </Layout>
    )
}

export default Payment
