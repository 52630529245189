import React, { Fragment } from 'react'
import './style.scss'
import banner from '../assets/images/Logo_mockup.jpg'
import { Box, Stack } from '@mui/material'
const Banner = ({heading}) => {
    return (
            <Stack className="banner__items" px={{xs:3, sm:8, md:8, lg: 10, xl: 11}} height={320} justifyContent="center" borderRadius={0} paddingLeft={{ xs:4, sm:8, md:10, lg: 10, xl: 10 }}>
               <h1 className='heading' >{heading}</h1>
            </Stack>
           
    )
}

export default Banner

