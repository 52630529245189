import React, {useState} from 'react'
import {useSelector } from 'react-redux'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
     Modal, Box, Badge
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye,  faTimes ,faDownload   ,faUser} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const TransactionList = () => {


    
    const { paymentHistory } = useSelector(state => state.booking)
    const [selectedRow, setSelectedRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;

    const getFormattedPhone = (number) => {
        const phoneNumberObj = parsePhoneNumberFromString(`+${number}`);
        
        if (phoneNumberObj) {
            const countryCode = phoneNumberObj.countryCallingCode;
            const mobileNumber = phoneNumberObj.nationalNumber;
            return `+${countryCode} ${mobileNumber}`;
        } else {
            return number;
        }
    };
    
    const calculateBasePrice = () => {
        var baseprice = 0
        return baseprice?.toFixed(2);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleOpen = (row) => {
        setSelectedRow(row);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedRow(null);
    };
    const mailtabletotal = (details)=>{
        var total =0;
      
        details?.map(det=>{
            total += parseInt(det?.golf_course_price) * parseInt(det.total_qolfers);
        })

        return total;
    }
     
 
    const DownloadPdf = (url) => {
      
        var link = document.createElement('a');
                link.href = url;
                link.target = '_blank'
                link.setAttribute('download', 'invoice.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
    }
    console.log("paymentHistory",paymentHistory)
    return(
        <div className='mybook-con'>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                <TableCell sx={{ color: 'white' }}>Booking Ref No</TableCell>
                                <TableCell sx={{ color: 'white' }}>Invoice Number</TableCell>
                                <TableCell sx={{ color: 'white' }}>Date</TableCell>
                                <TableCell sx={{ color: 'white' }}>Gateway Name</TableCell>
                                <TableCell sx={{ color: 'white' }}>Payment Mode</TableCell>
                                <TableCell sx={{ color: 'white' }}>Transaction Price</TableCell>
                                <TableCell sx={{ color: 'white' }}>Transaction Status</TableCell>
                                <TableCell sx={{ color: 'white' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        {paymentHistory && paymentHistory.length > 0 && (
                             <TableBody>
                             {paymentHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                 <TableRow
                                     key={index}
                                     sx={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }} className='book-tr-td'
                                 >
                                     <TableCell className='book-td-grey'>{row?.booking_reference_number}</TableCell>
                                     <TableCell className='book-td-grey'>{row?.invoice_number}</TableCell>
                                     <TableCell className='book-td-black'>{moment(row?.transaction_date).format("DD-MM-YYYY")}</TableCell>
                                     <TableCell className='book-td-black'>{row?.payment_gateway_name}</TableCell>
                                     <TableCell className='book-td-black'>{row?.payment_mode}</TableCell>
                                     <TableCell className='book-td-black'>{row?.currency.toUpperCase()} {row?.transaction_price.toFixed(2)}</TableCell>
                                     <TableCell className='book-td-black'>{row?.transaction_status}</TableCell>
                                     <TableCell style={{ cursor: "pointer" }}className='book-td-green' sx={{ whiteSpace: 'nowrap' }} >
                                    <FontAwesomeIcon onClick={() => handleOpen(row)} icon={faEye}  />{row.actions} {' '}
                                    <FontAwesomeIcon onClick={()=>DownloadPdf(row?.receipt_pdf)} icon={faDownload}  />
                                    </TableCell>
                                 </TableRow>
                             ))}
                         </TableBody>
                        )}
                       
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={paymentHistory.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                />
            </Paper>

        <Modal open={open} onClose={handleClose}>
            <div className='dashboard-popup-box'>
                <Box className="modal-box" style={{top:'45%'}}>
                    {selectedRow && (
                        <>
                            <h2 className="modal-header">Transaction History</h2>
                            <button className="close-button" onClick={handleClose}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <div className="modal-content">
                                <div className="modal-section">
                                    <p><strong>Booking Ref No:</strong> {selectedRow?.booking_reference_number || ''}</p>
                                    <p><strong>Gateway Name:</strong> {selectedRow?.payment_gateway_name || ''}</p>
                                    <p><strong>Price:</strong> {selectedRow?.currency.toUpperCase()} {selectedRow?.transaction_price.toFixed(2) || ''}</p>
                                    <p><strong>Transaction Date:</strong> {moment(selectedRow?.transaction_date).format("DD-MM-YYYY")}</p>
                                </div>
                                <div className="modal-section">
                                    <p><strong>Invoice Number:</strong> {selectedRow?.invoice_number || ''}</p>
                                    <p><strong>Payment Mode:</strong> {selectedRow?.payment_mode || ''}</p>
                                    <p><strong>Transaction Status:</strong> {selectedRow?.transaction_status || ''}</p>
                                    <p><strong>Transaction Ref Number:</strong> {selectedRow?.transaction_reference_number || ''}</p>
                                </div>
                                <div className="modal-section">
                                    <p><strong>Receipt Number:</strong> {selectedRow?.receipt_number || ''}</p>
                                    <p><strong>Transaction Type:</strong> {selectedRow?.transaction_type || ''}</p>
                                    <p><strong>Payment Remark:</strong> {selectedRow?.payment_remarks || ''}</p>
                                </div>
                            </div>
                        </>
                    )}
                </Box>
            </div>
        </Modal>
    </div>
    )
}
export default TransactionList