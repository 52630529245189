import React, { Fragment, useState } from 'react'
import { FormGroup } from '@mui/material';
import { TextField, Typography } from '@mui/material'
import { Controller } from "react-hook-form";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles, styled } from '@mui/styles';
import './style.scss'
import { setHours, setMinutes } from 'date-fns';



const useStyles = makeStyles(() => ({
    noBorder: {
        borderRadius: 0,
        border: 0,
    },
    resize: {
        fontSize: 12,
        paddingLeft: 3,
        paddingTop:4,
        height:30   
    },
}));


const TimesPicker = ({ fieldName, fieldType, fieldLabel, minDate, width, fontSize, control, minTime, error, children, marginRight, maxDate, mode }) => {



    const [value, setValue] = React.useState(null);



    const classes = useStyles();

    const changeDateValue = (value) => {
        setValue(value)
    }




    return (
        <FormGroup style={{ marginRight: 5, marginLeft: 5, marginBottom: 1, position: 'relative' }} id='root' >
            <Typography className='formText' sx={{ fontSize: '12px' }} >{fieldLabel}</Typography>

            <Controller
                name={fieldName}
                control={control ? control : null}
                render={({ field: { value, onChange } }) => 
                <TimePicker value={value} onChange={onChange} />
                } />
            {error && <Typography color={"red"} fontSize={10}>{error?.message}</Typography>}
        </FormGroup>
    )

}

export default TimesPicker