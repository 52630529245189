import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'
import profile from '../../assets/images/profile.png'
import Slider from "react-slick";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import rectangle1 from '../../assets/images/Rectangle 18.png'
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Noimage from "../../assets/images/no-img.png";
const Reviews = ({ courses }) => {

// ---------------------slider-------------------------------
const [reviewlist ,setReview] = useState([])
useEffect(() => {
    setReview(courses?.golf_courses?.reviews)
}, [])
console.log(reviewlist)
    return(
      <div className='final-de-reviewcon'>
      { reviewlist?.length >0 ?
     reviewlist.slice(0, 5).map((review,i) => (
   <div className="review-section">
     <div className="review-header">
       <div className="review-title">Reviews</div>
       <div className="review-rating">
         <p className="rating-badge"><span><FontAwesomeIcon icon={faStar} /></span> {review?.rating ? review?.rating.toFixed(1) : ''}</p>
       </div>
     </div>
     <div className="review-card">
       <div className="reviewer-info">
         <div className='final-deheader'>
           <div className='final-review-imgcircle'>
             <img src={review?.image ? review?.image : rectangle1} onError={(e) => {e.target.onerror = null;e.target.src = Noimage; }} alt="Profile" className="reviewer-image" />
           </div>
           <div>
             <h3 className="reviewer-name">{review.name}</h3>
             <div className='reviewer-details'>
               {/* <p className="reviewer-location">Kuala Lumpur, Malaysia</p> */}
               <p className="review-date">{moment(review?.date, "DD-MM-YYYY").format("MMM Do YYYY")}</p>
             </div>
           </div>
         </div>

         <div className="review-rating-stars">
         {[...Array(review?.rating)].map((_, i) => (
           <FontAwesomeIcon icon={faStar} className="star-icon" />
          ))}
         </div>
       </div>
       <div className="review-content">
         <h4>{review?.rating >= 4 ? 'Excellent' : `Average: ${review?.rating}`} service...</h4>
         <p>
         {review?.message}
         </p>

         <div>
          {review?.file && (
            review.file.match(/\.(mp4|mov|avi|mkv)$/i) ? 
            <video src={review.file} controls style={{ maxHeight: '50%', maxWidth: '50%' }}>Your browser does not support the video tag.</video> : 
            <img src={review.file} alt="Review file" style={{ maxHeight: '50%', maxWidth: '50%' }} onError={(e) => { e.target.onerror = null; e.target.src = Noimage; }} />
          )}
        </div>

       </div>
     </div>
   </div>
  )) 
  :"No Reviews Yet!"
 }
 </div>
 )
}
export default Reviews;