import { CURRENCYDB , USERDEFAULTCURRENCYCODE , CURRENCYRATE,ERRORCURRENCYRATE , COUNTRYDB , TESTIMONIALDB} from '../constants/currencyConstant'

export const currencyReducer = (state = {}, action) => {
  switch (action.type) {

    case CURRENCYDB:
      return {
        ...state,
        dbCurrencyCodes: action.payload,
      };
      case USERDEFAULTCURRENCYCODE:
        return {
        ...state,
        userCurrencyCode: action.payload,
      };
    case CURRENCYRATE:
      return {
        ...state,
        currencyRate: action.payload,
      };
    case ERRORCURRENCYRATE:
      console.log(action.payload)
      return {
        ...state,
        userCurrencyCode: action.payload,
      };
      case COUNTRYDB:
        return {
          ...state,
          dbCountry: action.payload,
        };
      case TESTIMONIALDB:
        return {
          ...state,
          dbTestimonial: action.payload,
        };
      default:
          return state;
  }
}