import {TESTIMONIALDB , HOMEOTHERDETAIL , GETSEARCHDATA , SETSEARCHDATA} from '../constants/homeConstant'
import { LOADING } from '../constants/bookingConstants'
import axios from '../../CustomAxios'
import { API_URL } from '../../config/Constants'

//get testimonials
export const getTestimonials = (params) => async (dispatch, getState) => {
    dispatch({
        type: LOADING,
        payload: true
    });

    try {
        const response = await axios.get(API_URL + '/get-admin-testimonial', {
            headers: {
                'Content-Type': 'application/json'
            },
            params: params 
        });

        dispatch({
            type: LOADING,
            payload: false
        });

        const data = response.data;

        if (data) {
            dispatch({
                type: TESTIMONIALDB,
                payload: data.data
            });
        }
    } catch (error) {
        dispatch({
            type: LOADING,
            payload: false
        });
        console.error("Error fetching currencies:", error);
        // Optionally, you can dispatch an error action here if needed.
    }
};
//get testimonials.
export const otherDataForHome = (params) => async (dispatch, getState) => {
    dispatch({
        type: LOADING,
        payload: true
    });

    try {
        const response = await axios.get(API_URL + '/homepage-details', {
            headers: {
                'Content-Type': 'application/json'
            },
            params: params 
        });

        dispatch({
            type: LOADING,
            payload: false
        });

        const data = response.data;
        if (data) {
            dispatch({
                type: HOMEOTHERDETAIL,
                payload: data.data
            });
        }
    } catch (error) {
        dispatch({
            type: LOADING,
            payload: false
        });
        console.error("Error fetching currencies:", error);
        // Optionally, you can dispatch an error action here if needed.
    }
};

export const getSearchData = (data) => async (dispatch, getState) => {
           
    dispatch({
        type: LOADING,
        payload: true
    })
    axios(API_URL+'/homepage-search',{
        method: 'POST',
        data: data,
        headers: {
         'Content-Type': 'application/json'
       }
      })
        .then(function(response) {
            dispatch({
            type: LOADING,
            payload: false
            }) 
            const {data} = response.data;       
            dispatch({
                type: GETSEARCHDATA,
                payload:data
            })  
                        
          }).then(function(body) {
            // console.log(body);
          });
};

export const setSearchData = (data) => async (dispatch, getState) => {
            dispatch({
                type: SETSEARCHDATA,
                payload:data
            })  
};