import React, { useState, useEffect,useRef } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import GolfCourseList from '../../screens/GolfCourseList/index'
import { API_URL } from '../../config/Constants'
const GolfHolidaysList = () => {


    const { currencyRate} = useSelector(state => state.currency);
    const [currencyRateList , setCurrencyRateList] = useState([]);
   
    const { availableCourses }  = useSelector(state => state.booking)
    const [golfCourses, setGolfCourses] = useState([]);
    const [golfContents, setGolfcontents] = useState([]);


    useEffect(() => {
      if(currencyRate){
        setCurrencyRateList(currencyRate)
  }
    }, [currencyRate]);
    
    useEffect(() => {

        if(golfCourses && golfCourses.length > 0 ){
            const allPrices = []
    
            golfCourses.forEach((course) => {
                const currencyPerPrice = currencyRateList[course?.rate_sheets?.currency?.currencyCode];
                const convertedPrice =  course.rate_sheets.start_from_cost / currencyPerPrice
                allPrices.push(convertedPrice > 0 ? convertedPrice : 0)
            });
        }
       
      }, [currencyRateList])

      useEffect(()=>{
        if(availableCourses !==undefined && availableCourses.length >0){
          setGolfCourses(availableCourses)
          functiongetCountries();
        }
      },[availableCourses])
      console.log(availableCourses)

      useEffect(() => {
        GetGolfContent(); 
      }, [])

      const functiongetCountries = () =>{
        const newLocations = availableCourses.map(course => course?.golf_courses?.country_id);
        const newCities = availableCourses.map(course => course.golf_courses.city_id);
        const newTypes = availableCourses.map(course => course?.golf_courses?.course_type_name);
        setLocations(Array.from(new Set(newLocations)));
        setCities(Array.from(new Set(newCities)));
        setTypes(Array.from(new Set(newTypes)));
      }
      const GetGolfContent = () => {
      
          const payload = ["golf-holidays","golf-courses"];
          axios(API_URL+'/get-single-page',{
              method: 'POST',
              data: {slug:payload},
              headers: {
            'Content-Type': 'application/json',
             'Access-Control-Allow-Origin':'*'
             }
            })
              .then(function(response) {
                  setGolfcontents(response.data);
                //   alert(golfContents[0].body);
                }).then(function(body) {
                });
      }

      // --------new-----------------
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedSorts, setSelectedSorts] = useState();
  
  const [locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect(()=>{
    if(availableCourses && availableCourses.length > 0){
    if(selectedLocations.length > 0){
      const newCities = [];
      const newTypes = [];
      let newCourse = [];
    selectedLocations.forEach((each) => { 
        const filteredArray = availableCourses.filter((course) => {
            if (course.golf_courses.country_id === each) {
                newCities.push(course.golf_courses.city_id);
                newTypes.push(course.golf_courses.course_type_name);
            }
            return course.golf_courses.country_id === each;
        });
        newCourse = newCourse.concat(filteredArray);
    });
    setGolfCourses(Array.from(new Set(newCourse)));
    setCities(Array.from(new Set(newCities)));
    setTypes(Array.from(new Set(newTypes)));
    setSelectedTypes('');
    setSelectedCities('');
  }
  else {
    setGolfCourses(availableCourses) 
    const newTypes = availableCourses.map(course => course?.golf_courses?.course_type_name);
    const newCities = availableCourses.map(course => course.golf_courses.city_id);
    setTypes(Array.from(new Set(newTypes)));
    setCities(Array.from(new Set(newCities)));
    }
  }
  },[selectedLocations])

  useEffect(()=>{
    if(availableCourses && availableCourses.length > 0){
      if(selectedCities.length > 0){
    const newTypes = [];
    let newCities = [];

    selectedCities.forEach((city)=>{
      const filteredArray = availableCourses.filter((course)=>{
        if(course.golf_courses.city_id === city){
          newTypes.push(course.golf_courses.course_type_name); 
        }
        return course.golf_courses.city_id === city
      })
      newCities = newCities.concat(filteredArray);
    })
    setGolfCourses(newCities)
    setTypes(Array.from(new Set(newTypes)));
  }
  else if(selectedLocations.length > 0){
    const newTypes = [];
    let newCities = [];

    selectedLocations.forEach((location)=>{
      const filteredArray = availableCourses.filter((course)=>{
        if(course.golf_courses.country_id === location){
          newTypes.push(course.golf_courses.course_type_name); 
        }
        return course.golf_courses.country_id === location
      })
      newCities = newCities.concat(filteredArray);
    })
    setGolfCourses(newCities)
    setTypes(Array.from(new Set(newTypes)));
  }
  else{
    setGolfCourses(availableCourses)
  }
}
  },[selectedCities])


  useEffect(()=>{
    if(availableCourses && availableCourses.length > 0){
      if(selectedTypes.length >0){
      if (selectedLocations.length > 0 && selectedCities.length > 0) {
        let newCourseForLocation = [];
        let newCourseForCity = [];
        selectedTypes.forEach((type) => { 

          selectedLocations.forEach((location) => { 
            const filteredArray = availableCourses.filter((course) =>
              course.golf_courses.country_id === location &&
              course.golf_courses.course_type_name === type
            );
            newCourseForLocation = newCourseForLocation.concat(filteredArray);
          });

          selectedCities.forEach((city) => { 
            const filteredArray = availableCourses.filter((course) =>
              course.golf_courses.city_id === city &&
              course.golf_courses.course_type_name === type
            );
            newCourseForCity = newCourseForCity.concat(filteredArray);
          });


        });
        const combinedArray = [...newCourseForLocation, ...newCourseForCity];
        setGolfCourses(Array.from(new Set(combinedArray)));
  } 
  else if (selectedLocations.length > 0) {
        let newCourse = [];

        selectedTypes.forEach((type) => { 
          selectedLocations.forEach((each) => { 
            const filteredArray = availableCourses.filter((course) => 
              course.golf_courses.country_id === each &&
              course.golf_courses.course_type_name === type
            );
            newCourse = newCourse.concat(filteredArray);
        });
        })
        setGolfCourses(newCourse) 
      } 
  else if (selectedCities.length > 0) {
      let newCourse = [];

      selectedTypes.forEach((type) => { 
        selectedCities.forEach((each) => { 
          const filteredArray = availableCourses.filter((course) => 
            course.golf_courses.city_id === each &&
            course.golf_courses.course_type_name === type
          );
          newCourse = newCourse.concat(filteredArray);
      });
      })
      setGolfCourses(newCourse) 
      } 
  else {
    let newCourse = [];

    selectedTypes.forEach((type) => { 

      const filteredArray = availableCourses.filter((course) =>
          course.golf_courses.course_type_name === type
      );
          newCourse = newCourse.concat(filteredArray);
    })
        setGolfCourses(newCourse) 
    }
    }

    else{
       if (selectedCities.length > 0) {
        let newCourse = [];
  
          selectedCities.forEach((each) => { 
            const filteredArray = availableCourses.filter((course) => 
              course.golf_courses.city_id === each
            );
            newCourse = newCourse.concat(filteredArray);
        });
        setGolfCourses(newCourse) 
        } 
        else if (selectedLocations.length > 0) {
        let newCourse = [];

          selectedLocations.forEach((each) => { 
            const filteredArray = availableCourses.filter((course) => 
              course.golf_courses.country_id === each
            );
            newCourse = newCourse.concat(filteredArray);
        });
        setGolfCourses(newCourse) 
      } 
  else {
    setGolfCourses(availableCourses) 
    }
    }
  }
  },[selectedTypes])

  const [openDropdown, setOpenDropdown] = useState(null);
  const sorts = ["A to Z", "Z to A"];

 const handleCheckboxSelection = async (selectedOptions, setSelectedOptions, option , type) => {

    if (type === "sort" && option !== selectedSorts) {
      let sortedGolfCourses;
      
      if (option === "A to Z") {
          sortedGolfCourses = golfCourses.sort((a, b) => a.golf_courses.golf_course_name.localeCompare(b.golf_courses.golf_course_name));
      } else if (option === "Z to A") {
          sortedGolfCourses = golfCourses.sort((a, b) => b.golf_courses.golf_course_name.localeCompare(a.golf_courses.golf_course_name)); 
      }
      setSelectedSorts(option)
      setGolfCourses(sortedGolfCourses); 
    }
  else{
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
    );

  }
  };

  const dropdownRef = useRef(null);

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

 


    return (
      <div className='listitem'>
        <div className='listitems-sec'>
          <p className='final-list-path'>Home <span className='arrow-button'><FontAwesomeIcon icon={faChevronUp} className="arrow-icon left" /></span> Golf Course</p>
          <h2 className='final-list-head'>Golf Course Packages</h2>
          <p className='final-list-subtit'>{golfContents[0]?.body}</p>
          <Grid container spacing={2} className='filter-final-fullbox'>
            <Grid item xs={12} className='filter-inner-box-final'>
              <div className="filter-bar-grid-final">
              <Grid container spacing={2} className='d-s-b'>
                <Grid item xs={12} md={5} lg={6} className='filter-left-final'>
                  <div className="filter-options-group-final">
                    <div className="dropdown-final">
                      <label htmlFor="location-final">Location:</label>
                      <div className="custom-checkbox-dropdown-final">
                        <button className="dropdown-btn-final" name='dropdown' onClick={() => toggleDropdown("location")}>
                          {selectedLocations.length > 0 ? selectedLocations.join(", ") : "Select Locations"}
                          {/* {selectedLocations  !== '' ? selectedLocations : "Select Locations"} */}
                          <FontAwesomeIcon icon={openDropdown === "location" ? faChevronUp : faChevronDown} />
                        </button>
                        {openDropdown === "location" && (
                          <div className="checkbox-dropdown-content-final" ref={dropdownRef}>
                            {locations.map((location) => (
                              <label key={location}>
                                <input
                                  type="checkbox"
                                  checked={selectedLocations.includes(location)}
                                  onChange={() => handleCheckboxSelection(selectedLocations, setSelectedLocations, location , "location")}
                                />
                                {location}
                              </label>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="custom-checkbox-dropdown-final">
                        <button className="dropdown-btn-final" name='dropdown' onClick={() => toggleDropdown("city")}>
                          {selectedCities.length > 0 ? selectedCities.join(", ") : "Select Cities"}
                          {/* {selectedCities !== '' ? selectedCities : "Select Cities"} */}
                          <FontAwesomeIcon icon={openDropdown === "city" ? faChevronUp : faChevronDown} />
                        </button>
                        {openDropdown === "city" && (
                          <div className="checkbox-dropdown-content-final" ref={dropdownRef}>
                            {cities.map((city) => (
                              <label key={city}>
                                <input
                                  type="checkbox"
                                  checked={selectedCities.includes(city)}
                                  onChange={() => handleCheckboxSelection(selectedCities, setSelectedCities, city , "city")}
                                />
                                {city}
                              </label>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="dropdown-final">
                      <label htmlFor="duration-final">Duration:</label>
                      <div className="custom-checkbox-dropdown-final">
                        <button className="dropdown-btn-final" onClick={() => toggleDropdown("duration")}>
                          {selectedDurations.length > 0 ? selectedDurations.join(", ") : "Select Durations"}
                          <FontAwesomeIcon icon={openDropdown === "duration" ? faChevronUp : faChevronDown} />
                        </button>
                        {openDropdown === "duration" && (
                          <div className="checkbox-dropdown-content-final">
                            {durations.map((duration) => (
                              <label key={duration}>
                                <input
                                  type="checkbox"
                                  checked={selectedDurations.includes(duration)}
                                  onChange={() => handleCheckboxSelection(selectedDurations, setSelectedDurations, duration , "duration")}
                                />
                                {duration}
                              </label>
                            ))}
                          </div>
                        )}
                      </div>
                    </div> */}
                    
                  </div>
                </Grid>
                <Grid item xs={12} md={6.8} lg={6} className='filter-right-final'>
                  <div className="sort-by-final">
                    <div className="dropdown-final">
                      <label htmlFor="sort-by-final">Sort By:</label>
                      <div className="custom-checkbox-dropdown-final">
                        <button className="dropdown-btn-final" name='dropdown' onClick={() => toggleDropdown("sort")}>
                          {selectedSorts ? selectedSorts : "Select Sort Options"}
                          <FontAwesomeIcon icon={openDropdown === "sort" ? faChevronUp : faChevronDown} />
                        </button>
                        {openDropdown === "sort" && (
                          <div className="checkbox-dropdown-content-final" ref={dropdownRef}>
                            {sorts.map((sort) => (
                              <label key={sort}>
                                <input
                                  type="checkbox"
                                  checked={selectedSorts === sort}
                                  onChange={() => handleCheckboxSelection(selectedSorts, setSelectedSorts, sort , "sort")}
                                />
                                {sort}
                              </label>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="dropdown-final">
                      <label htmlFor="theme-final">Type:</label>
                      <div className="custom-checkbox-dropdown-final">
                        <button className="dropdown-btn-final" name='dropdown' onClick={() => toggleDropdown("theme")}>
                          {selectedTypes.length > 0 ? selectedTypes.join(", ") : "Select types"}
                          {/* {selectedTypes ? selectedTypes : "Select Type"} */}
                          <FontAwesomeIcon icon={openDropdown === "theme" ? faChevronUp : faChevronDown} />
                        </button>
                        {openDropdown === "theme" && (
                          <div className="checkbox-dropdown-content-final" ref={dropdownRef}>
                            {types.map((type) => (
                              <label key={type}>
                                <input
                                  type="checkbox"
                                  checked={selectedTypes.includes(type)}
                                  onChange={() => handleCheckboxSelection(selectedTypes, setSelectedTypes, type ,"type")}
                                />
                                {type}
                              </label>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
  
          <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="package-grid final-pkg">
                  {golfCourses ? golfCourses?.map((course, index) => (
                    <GolfCourseList
                    courses={course}
                  />
                  )): <div style={{ display: 'flex', justifyContent: 'center' }} >
                  <Typography fontFamily={'Nunito'} fontSize={20} fontWeight={'bold'} letterSpacing={1}>
                    No Golf Course Found !
                  </Typography>
                </div>}
                </div>
            </Grid>
          </Grid>
  
        </div>
      </div >
    )
}

export default GolfHolidaysList;
