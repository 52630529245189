import React, { useState, useEffect } from 'react'
import { Grid, Modal, Typography, Container, Box, FormControl, Stack } from '@mui/material'
import InputField from '../../components/InputField'
import ModalHeader from '../../components/ModalHeader';
import CloseIcon from '@mui/icons-material/Close';

import Divider from '@mui/material/Divider'
import { useLocation } from 'react-router-dom';
import AdultImg from '../../assets/images/IconsNew/avatar.png'
import BabyImg from '../../assets/images/IconsNew/baby-boy.png'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faUser ,faCartShopping} from '@fortawesome/free-solid-svg-icons';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
    IconButton
} from '@mui/material';
const ViewTrip = ({ openModal, closeModal, trips }) => {
    const [booking, setBooking] = useState(null);
    const [courseInfo, setCourseInfo] = useState({});

    // console.log("trips",trips);


    const { user } = useSelector(state => state.auth)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#F6F6F6',
        borderRadius: '15px',
        border: '0px solid #fff',
        height: 'auto',
        width: '75%',
    };

    useEffect(() => {
        console.log(trips)
        TotalGolfers()
        // setBooking(JSON.parse(trips?.row))
    }, [])

    const TotalGolfers =()=>{
        var totalfare=0;
        var totalGolfers=0;
        
    trips?.row?.details?.forEach(function (coures) {
        totalGolfers +=coures?.total_qolfers;
        totalfare +=coures?.golf_course_price * coures?.total_qolfers;
    })
    const CourseDetails={
        totalFare:totalfare,
        totalGolfers:totalGolfers,
    }
    setCourseInfo(CourseDetails)
    }


    let addOns = [];//trips.row?.addons ? JSON.parse(trips?.row?.addons) : [];
    let addonitems = [];//trips.row?.addonitems ? JSON.parse(trips?.row?.addonitems) : [];

   



    return (
        
        <Modal open={openModal} onClose={closeModal}   >

            <Box sx={style} className='modalScroll' >
                <Stack className='dashboard-header' direction={"row"} justifyContent="space-between" alignItems={"center"} style={{ display: 'flex', height: '35px', flexDirection: 'row', backgroundColor: '#51960e', alignItems: 'center', 
                    borderRadius: '6px 6px 0px 0px' }} pl={2}>
                    {/* <Typography className='modalHeadStyle' >Trip ID : #{trips.id}</Typography> */}
                    <Typography className='vehiclename'>{trips?.row?.vehicletype ?trips?.row?.vehicletype.vehicle_type : ''}</Typography>
                    <Typography className='modalDate'  > {moment(trips?.row?.created_at, "YYYY-MM-DD").format("DD-MM-YYYY")} 
                        <IconButton onClick={closeModal} style={{ alignSelf: 'flex-end', flex: 0.05 }}>
                            <CloseIcon style={{ color: 'black' }} />
                        </IconButton></Typography>
                </Stack>



                <Grid container spacing={0} style={{ paddingTop: '0%', paddingLeft: '0%', paddingRight: 0 }}>
                    <Grid xs={12} md={8} sx={{ bgcolor: '#fff', flexGrow: 1, paddingBottom: 1 }}>
                        {/* {(user?.role === "agent" || user?.role === "corporate") && <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                            <span>
                            <Typography className='companytext' >Company Name :</Typography>
                            </span>
                            <span><Typography className='newText' >{user?.companyinfo?.company_name}</Typography> </span>
                        </div>} */}
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', 
                            paddingLeft: '1%',gap: 5}}>
                            <span>
                                <Typography className='modalHeadText'> Passenger Details</Typography>
                            </span>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography className='namesText' >Name </Typography>
                                    <Typography className='storeText' > : {trips?.row?.details[0].customer_name}</Typography>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography className='namesText' > Nationality </Typography>
                                    <Typography className='storeText' > : {trips?.row?.details[0]?.customer_nationality}</Typography>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography className='namesText' >Mobile </Typography>
                                    <Typography className='storeText' > : {trips?.row?.details[0]?.customer_phone}</Typography>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} md={4} sx={{ bgcolor: '#fff', height: 'auto', flexGrow: 1 }} padding={1}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', gap: 2 }}>
                            <Stack style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <img src={AdultImg} style={{ width: 25, height: 25 }} />
                                <Typography className='capacityText'>Total Golfers</Typography>
                                <Typography className='capacityText'>{courseInfo?.totalGolfers}</Typography>
                            </Stack>
                        </div>


                    </Grid>
                    <Grid xs={12} md={12} sx={{ bgcolor: '#fff', height: 'auto', flexGrow: 1 }}>
                       
                       
                       <TableContainer sx={{ maxHeight: 440 }} style={{ paddingTop: '0%', paddingLeft: '0%', paddingRight: "0%" }} className='dashboard-table-golf'>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow md={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                        <TableCell md={{ color: 'black' }}>ID</TableCell>
                                                        <TableCell md={{ color: 'black' }}>Golf Course name</TableCell>
                                                        <TableCell md={{ color: 'black' }}>Selected Date</TableCell>
                                                        <TableCell md={{ color: 'black' }}>Tea Time</TableCell>
                                                        <TableCell md={{ color: 'black' }}>No of Golfers</TableCell>
                                                        <TableCell md={{ color: 'black' }}>Price</TableCell>
                                                       
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {trips?.row?.details?.map((row, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }} className='book-tr-td'
                                                        >
                                                            <TableCell className='book-td-grey'>{row.id}</TableCell>
                                                            <TableCell className='book-td-grey'>{row.golf_course_name}</TableCell>
                                                           
                                                            <TableCell className='book-td-black'>{row.date}</TableCell>
                                                            <TableCell className='book-td-black'>{row.tee_time}</TableCell>
                                                            <TableCell className='tb-user-icon'><FontAwesomeIcon icon={faUser} /> {row.total_qolfers}</TableCell>
                                                            <TableCell className='book-td-black'>{row.golf_course_price * row.total_qolfers}</TableCell>
                                                            <TableCell className='book-td-green'>{row.bookingStatus}</TableCell>
                                                           
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                        {/* <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                            <span>
                            <img src={Loc1Img} style={{ width: 28, height: 30,paddingRight:10  }} />
                            </span>
                            <span>
                            <Typography className='loctext' >{trips.row.location_from}</Typography>
                            </span>
                        </div>
                        <Divider orientation="vertical" flexItem sx={{ width:'0px',height: '10%',marginLeft:2.5,marginTop:-3,marginBottom:-1 ,paddingBottom:2,
                        borderRight:'1.5px dashed' ,borderLeft:'none',borderRightColor: "#EDBA37"}} > </Divider>
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                            <span>
                            <img src={Loc2Img} style={{ width: 28, height: 30,paddingRight:10 }} />
                            </span>
                            <span>
                            <Typography className='loctext'>{trips.row.location_to}</Typography>
                            </span>
                        </div> */}

                    </Grid>

                    {/* <Grid xs={12} md={3} sx={{ bgcolor: '#F6F6F6', height: 'auto',flexGrow:1}}>
                        
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'center'}}>
                            <span>
                            <img src={trips.row.vehicle_image} width="auto" height="120" />
                            </span>
                            
                        </div>
                        
                    </Grid> */}
                    {/* <Grid xs={12} md={12} sx={{ bgcolor: '#fff', height: 'auto', flexGrow: 1, paddingBottom: '1%' }}>
                        <Divider color='#EDBA37' flexItem sx={{ borderWidth: 1 }} />
                    </Grid> */}
                    {/* <Grid xs={12} md={12} sx={{ bgcolor: '#F6F6F6', height: 'auto', flexGrow:1}}>
                        
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                        <span>
                            <Typography className='companytext' >Transaction ID :</Typography>
                            </span>
                            <span><Typography className='newText' ></Typography> </span> 
                           
                        </div>
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                        <span>
                            <Typography className='companytext' >Payment Status :</Typography>
                            </span>
                            <span><Typography className='completeText' >{trips?.row?.payment_status}</Typography> </span> 
                           
                        </div>
                    </Grid> */}

                    <Grid xs={12} md={7} sx={{ bgcolor: '#fff', height: 'auto', flex: 0.3, paddingLeft: 1 }}>
                        {/* <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                            <span>
                            <Typography className='modalHeadText'> Payment Details</Typography>
                            </span>
                    </div> */}
                        {/* <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography className='priceText' >All prices are in SGD</Typography>
                    </div> */}
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', paddingTop: '1%' }}>
                            <span>
                                <Typography className='companytext' >Booking ID :</Typography>
                            </span>
                            <span><Typography className='newText' style={{ paddingLeft: 4 }}> {`${trips?.row?.id}`}</Typography> </span>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', paddingTop: '1%', gap: 5 }}>
                             
                            {/* <div style={{ display: 'flex' }}>
                                <span>
                                    <Typography className='companytext' >Payment Status : </Typography>
                                </span>
                                <span><Typography className='completeText' style={{ paddingLeft: 4 }}>{trips?.row?.paymentdetails?.status}</Typography> </span>

                            </div> */}


                        </div>
                    </Grid>
                    <Grid xs={12} md={5} sx={{ bgcolor: '#fff', height: 'auto', flex: 0.3 }} className='dash-pad-box'>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                            <Typography className='modalHeadText'> Total Fare</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '76%' }}>
                            <Typography style={{
                                fontWeight: 600,
                                paddingRight: '10%',
                                flex: 0.5,
                                fontSize: 15,
                                color:'#684b2a'
                            }}>Base Price</Typography>
                            <Typography style={{
                                fontSize: 15,
                                fontWeight: 600,
                                textAlign: 'right',
                                paddingRight: 10,
                                flex: 0.5,
                                color:'#51960e'
                            }} >${courseInfo?.totalFare}</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                            <Typography className='modalHeadText'> Convineance Fee</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '76%' }}>
                            <Typography style={{
                                fontWeight: 600,
                                paddingRight: '10%',
                                flex: 0.5,
                                fontSize: 15,
                                color:'#684b2a'
                            }}>Convineance Fee</Typography>
                            <Typography style={{
                                fontSize: 15,
                                fontWeight: 600,
                                textAlign: 'right',
                                paddingRight: 10,
                                flex: 0.5,
                                color:'#51960e'
                            }} >${courseInfo?.convineance_fee}</Typography>
                        </div>
                       
                        {/* <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography  style={{
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            paddingRight: '30%', 
                            flex: 0.5,
                            fontSize: 12
                        }}>Extra Charges</Typography>
                        <Typography style={{ 
                            fontFamily: 'Nunito',
                            fontSize: 12,
                            paddingRight: 10, 
                            flex: 0.5  }}  >S${parseFloat(trips?.row?.pricebreakups?.extraCharges).toFixed(2)}</Typography>
                    </div> */}
                       
                       


                        <Divider orientation="horizontal" sx={{ bgcolor: "#F6F6F6", height: '0px', width: '100%', marginTop: 1 }} flexItem />

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '76%', marginBottom: 5, marginTop: 8 }}>
                            <Typography style={{ color: '#333333', fontWeight: 'bold', paddingRight: '28%', flex: 0.5, fontSize: 14 }} >Total</Typography>
                            <Typography style={{ color: '#333333', fontWeight: 'bold', flex: 0.5, fontSize: 14, textAlign: 'right' }} >$ {trips?.row?.pricebreakups?.currencySymbol}{parseInt(courseInfo?.totalFare)}</Typography>
                        </div>
                    </Grid>
                </Grid>

            </Box>

        </Modal>
    )
}

export default ViewTrip
