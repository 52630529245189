import { 
    LOADING, 
    UPDATE_PROFILE_SUCCESS, 
    UPDATE_PROFILE_FAIL,
    UPDATE_PASSWORD_SUCCESS, 
    UPDATE_PASSWORD_FAIL,
    PROFILE_LIST_SUCCESS,
    PROFILE_LIST_FAIL,
    SUBUSER_UPDATE_SUCCESS,
    SUBUSER_UPDATE_FAIL

} from "../constants/profileConstants";
import axios from '../../CustomAxios'
import { getUserList, getUserProfile } from "./userActions";

//Update Profile
export const updateProfile = (formData) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`auth/update-profile`, formData)
    .then(async response => {

        dispatch({
            type: LOADING,
            payload: false
        })
        let data = response.data.data

        dispatch(getUserProfile(data?.id))
        dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload: response.data.data
        })
        

    

        // if( data?.customer_type === 3){
        //     data.role = 'customer'
        //     dispatch({
        //         type: UPDATE_PROFILE_SUCCESS,
        //         payload: response.data.data
        //     })
        //     await localStorage.setItem("user", JSON.stringify(data));
        // }
        // else if(data?.is_corporate === 2){
        //     if(data?.customer_type === 1){
        //         data.role = 'corporate';
        //         dispatch({
        //             type: UPDATE_PROFILE_SUCCESS,
        //             payload: response.data.data
        //         })
        //         await localStorage.setItem("user", JSON.stringify(data));
        //     }
        //     else if(data?.customer_type === 2){
        //         data.role = 'agent'
        //         dispatch({
        //             type: UPDATE_PROFILE_SUCCESS,
        //             payload: response.data.data
        //         })
        //         await localStorage.setItem("user", JSON.stringify(data));
        //     }
        // }
       
       
    })
    .catch(async error => {
    

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: UPDATE_PROFILE_FAIL,
            payload: error
        })

    });
}


//Edit Password
export const updatePassword = (formData) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`auth/update-password`, formData)
    .then(async response => {
       
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: UPDATE_PASSWORD_SUCCESS,
            payload: response.data
        })
       
    })
    .catch(async error => {
   

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: UPDATE_PASSWORD_FAIL,
            payload: error
        })

    });
}



//Get ProfileLIST
export const getProfile = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`auth/profile/${id}`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        let data = response.data.data
       
        if( data?.customer_type === 3){
            data.role = 'customer'
            dispatch({
                type: PROFILE_LIST_SUCCESS,
                payload: data
            })
            await localStorage.setItem("user", JSON.stringify(data));
        }
        else if(data?.is_corporate === 2){
            if(data?.customer_type === 1){
                data.role = 'corporate';
                dispatch({
                    type: PROFILE_LIST_SUCCESS,
                    payload: data
                })
                await localStorage.setItem("user", JSON.stringify(data));
            }
            else if(data?.customer_type === 2){
                data.role = 'agent'
                dispatch({
                    type: PROFILE_LIST_SUCCESS,
                    payload: data
                })
                await localStorage.setItem("user", JSON.stringify(data));
            }
        }

        
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: PROFILE_LIST_FAIL,
            payload: error
        })
    });
}

//update Sub User Profile
export const updateSubUserProfile = (data, userData) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`auth/update-profile`, data)
    .then(async response => {
   
        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch(getUserList(userData))
        dispatch({
            type: SUBUSER_UPDATE_SUCCESS
        })
       
       
    })
    .catch(async error => {
   

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: SUBUSER_UPDATE_FAIL,
            payload: error
        })

    });
}



