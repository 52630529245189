import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout';
import axios from 'axios';
import { API_URL, BASE_URL } from '../../config/Constants'
import PageContent from './PageContent';
import ErrorPage from "../ErrorPage"
export default function Pages() {
    const { slug } = useParams();
    const [details, setDetails] = useState({})
     const [loading, setLoading] = useState(true)
     const [errorpage, setErrorPage] = useState(false)
     const {footerMenus } =useSelector(state => state.booking)
    const [menus, setMenus] = useState(footerMenus);

    
    useEffect(() => {
          if( footerMenus !==undefined){
         setMenus(footerMenus)
        }
        getData();
             
      }, [footerMenus,slug])
   
      const getData = async() => {
        if(slug){
            const payload = {
                slug: slug
              }
            axios(API_URL+'/get-single-page',{
                method: 'POST',
                data: payload,
                headers: {
                 'Content-Type': 'application/json'
               }
              })
                .then(function(response) {
                    // return response.json()
                    
                    if(response.data.length === null || response.data.length ===0){
                       
                        setErrorPage(true)
                    }else{
                        
                    }
                    setDetails(response.data);
                        setLoading(false)
                  }).then(function(body) {
                    console.log(body);
                  });
           }
      }
return (
    <>
    {
        errorpage === false ? (
            <Layout head={"About Us"} details={details}>
            <PageContent pageContents={details} loading={loading}/>
            </Layout>
        ) : (
            <ErrorPage />
        )
    }
    </>
)
}