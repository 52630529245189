import React, { useState, useEffect } from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import GolfCourseDetails from './GolfCourseDetails';
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet';
import {getGolfResults } from '../../Redux/actions/bookingActions'

const CourseDetailsPage = () => {
    const { selectedCourse, bookingData } = useSelector(state => state.booking)
    const [details, setDetails] = useState({'title':'Golfcourses -Golftripz','meta_desc':'Golfcourses list','meta_keyword':'services , golftripz , golfcourses'})
    const dispatch = useDispatch();

    useEffect(()=>{
        getCourse();
      },[])
      const getCourse = async() =>{
        await dispatch(getGolfResults());
      }


    return (
        <Layout details={details} >
             <Helmet>
            <title>{selectedCourse?.golf_courses?.meta_title}</title>
            <meta name="description" content={selectedCourse?.golf_courses?.meta_description} />
            <meta name="keywords" content={selectedCourse?.golf_courses?.meta_keywords} />
            </Helmet>
            <GolfCourseDetails count={5}/>
            
        </Layout>
    )
}

export default CourseDetailsPage;
