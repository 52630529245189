import React, { useState, useEffect ,useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import axios from 'axios';
import { API_URL, BASE_URL } from '../../config/Constants'
import filreset from '../../assets/images/lock_reset (1).png'
import Golfholidayimg from '../../assets/images/image 84.png'
import EventsList from './EventsList';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp,faArrowRight,faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import rectangle1 from '../../assets/images/Rectangle 18.png'
import rectangle2 from '../../assets/images/Rectangle 19.png'
import rectangle3 from '../../assets/images/Rectangle 20.png'
import rectangle4 from '../../assets/images/Rectangle 21.png'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const GolfEventsView = () => {
  const { userCurrencyCode, currencyRate } = useSelector(state => state.currency);
  const [currencyRateList, setCurrencyRateList] = useState([]);
  const { availableHolidays } = useSelector(state => state.booking)
  const [availableEvents,setAvailableEvents ] = useState([])
  const [golfEvents, setGolfEvents] = useState([]);
  const [searchdata, setSearchdata] = useState("");
  const navigation = useNavigate();
  const [countriess, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [minimumPrice, setMinimumPrice] = useState(0);
  const [maximumPrice, setMaximumPrice] = useState(0);
  const [filteredminimumPrice, setFilMinimumPrice] = useState(0);
  const [filteredmaximumPrice, setFilMaximumPrice] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState('All');
  const [golfContent, setGolfcontents] = useState();
  const GolfHolidayDetailsPage = () => {
    setAnchorElNav(null);
    navigation('/EventsDetailsPage')
};

  useEffect(() => {
    preFunction()
  }, [availableHolidays, currencyRateList]);



  useEffect(() => {
  
    var eventData=availableHolidays?.filter(events=> events?.golf_holidays?.event_checkbox===1);
    console.log("eventData",eventData)
    setAvailableEvents(eventData)
    setGolfEvents(eventData)
    if (currencyRate) {
      setCurrencyRateList(currencyRate)
    }
    GetGolfContent()
  }, [currencyRate]);
  const GetGolfContent = () => {
   
    const payload = ["golf-events"];
    axios(API_URL+'/get-single-page',{
        method: 'POST',
        data: {slug:payload},
        headers: {
      'Content-Type': 'application/json',
       'Access-Control-Allow-Origin':'*'
       }
      })
        .then(function(response) {
            // return response.json()
            var value=response.data;
            setGolfcontents(value[0].body);
           
          }).then(function(body) {
          });
      }


  const preFunction = () => {
    // Retrieve search data from local storage and parse it
    const searchData = localStorage.getItem("search");
    if (searchData) {
      setSearchdata(JSON.parse(searchData));
    }
 
    if (availableHolidays) {
      var eventData=availableHolidays?.filter(events=> events?.golf_holidays?.event_checkbox===1);
      console.log("eventData",eventData)
      setAvailableEvents(eventData);
      setGolfEvents(eventData);
 
      // Find the lowest price among all up_golfer_single prices
      const allPrices = []
      if (availableEvents && availableEvents?.length > 0 ) {

        availableEvents.forEach((holiday) => {
          holiday.holiday_price.forEach((each) => {
            const currencyPerPrice = currencyRateList[each?.currency_id?.currencyCode];
            const convertedPrice = each.up_golfer_single / currencyPerPrice
            allPrices.push(convertedPrice > 0 ? convertedPrice : 0)
          });
        });

      }

      //   const allPrices = golfHolidays.flatMap(holiday =>
      //   holiday.holiday_price?.map(item => item.up_golfer_single) || []
      // );

      if (allPrices.length > 0) {
        const highestPrice = Math.max(...allPrices);
        const roundedPrice = Math.ceil(highestPrice + 10);
        setMaximumPrice(roundedPrice);
        setFilMaximumPrice(roundedPrice);
      }

      // Filter countries (assuming this is a function to update some state)
      filterCountries();
      filterCities();
    }
  }

  const filterCountries = () => {
    var lists = [];
    if (availableEvents && availableEvents?.length > 0 ) {
    availableEvents.forEach((holiday) => {
      if (Array.isArray(holiday?.golf_holidays?.country_id)) {
        holiday?.golf_holidays?.country_id.forEach((country) => {
          if (country.name && !lists.includes(country.name)) {
            lists.push(country.name);
          }
        });
      }
    });
  }
    setCountries(lists)

  }
  
  const filterCities = () => {
    var lists = [];
    if (availableEvents && availableEvents?.length > 0 ) {
    availableEvents.forEach((holiday) => {
      if (Array.isArray(holiday?.golf_holidays?.city_id)) {
        holiday?.golf_holidays?.city_id.forEach((city) => {
          if (city.name && !lists.includes(city.name)) {
            lists.push(city.name);
          }
        });
      }
    });
  }
    setCities(lists)

  }
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const { user } = useSelector(state => state.auth);


  // ----date--------------
  const [startDate, setStartDate] = useState(new Date());


  // --------new-----------------
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedSorts, setSelectedSorts] = useState("");

  const [openDropdown, setOpenDropdown] = useState(null);
  const handleLocationChange = (event) => {
    var value = event.target.value;
    var checked =event.target.checked;
    if (checked) {
    var checkedlocation=selectedLocations;
      checkedlocation.push(value)
      console.log(selectedLocations)
    }else{
      if(selectedLocations?.length ===1){
        setSelectedLocations([])
        // setGolfHolidays(availableHolidays);
      }
        var index = selectedLocations.indexOf(value);
        if (index > -1) { 
          selectedLocations.splice(index, 1);
        }
        console.log(selectedLocations);

    }

    var filteredData=[];
      
      if (checked) {
         filteredData = availableEvents.filter((item) =>
          item.golf_holidays.country_id.some((country) => selectedLocations.includes(country.name))
        );
        setGolfEvents(filteredData);
      } else {
          if(selectedLocations?.length <=0){
            filterCities()
            setGolfEvents(availableEvents);
          }else{
            filteredData = availableEvents.filter((item) =>
            item.golf_holidays.country_id.some((country) => selectedLocations.includes(country.name))  
        );
        setGolfEvents(filteredData);  
        } 
             
      }
      
      var lists = [];
      filteredData.forEach((holiday) => {
    if (Array.isArray(holiday?.golf_holidays?.city_id)) {
      holiday?.golf_holidays?.city_id.forEach((city) => {
        if (city.name && !lists.includes(city.name)) {
          lists.push(city.name);
        }
      });
    }
  });
      // alert(lists)
      setCities(lists)
    // console.log(filteredData)
    
    };

    const handleCityChange = (event) => {
      var value = event.target.value;
      var checked = event.target.checked;
      if (checked) {
        var checkedcities=selectedCities;
        checkedcities.push(value)
          console.log(checkedcities)
        }else{
          if(selectedCities?.length ===1){
            setSelectedCities([])
          }
          var removecities=selectedCities.filter(country =>country !=value)
          // alert(JSON.stringify(removecities))
          setSelectedCities(removecities)
          console.log(selectedCities)
        }
     
   
      var filteredData=[];
    
        if (checked) {
         
           filteredData = availableEvents.filter((item) =>
            item.golf_holidays.city_id.some((city) => selectedCities.includes(city.name))
          );
          setGolfEvents(filteredData);
        } else {
          if(selectedCities?.length <=0){
            setGolfEvents(availableEvents);
          }else{
              filteredData = availableEvents.filter((item) =>
                item.golf_holidays.city_id.some((country) => selectedCities.includes(country.name))  );
              setGolfEvents(filteredData);
        }
        }
    };


    const handlePackageChange = (event) => {
      var value = event.target.value;
      var checked = event.target.checked;
        if (checked) {
          var checkedpackage=selectedPackages;
              checkedpackage.push(value)
            console.log(selectedPackages)
          }else{
            if(selectedPackages?.length ===1){
              setSelectedPackages([])
              // setGolfHolidays(availableHolidays);
            }
            // var removecities=selectedPackages.filter(country =>country !=value)
            // setSelectedPackages(removecities)
              var index = selectedPackages.indexOf(value);
              if (index > -1) { 
                selectedPackages.splice(index, 1);
              }
              console.log(selectedPackages);
          }
     
      
      if (checked) {
        
        var  filteredData = availableEvents.filter((item) =>
          selectedPackages.includes(item.golf_holidays.price_type)
          );
          console.log(filteredData)
          setGolfEvents(filteredData);
      } else {
       
          if(selectedPackages?.length <=0){
            setGolfEvents(availableEvents);
          }else{
            var  filteredData = availableEvents.filter((item) =>
              selectedPackages.includes(item.golf_holidays.price_type) 
			                    );
            console.log(filteredData)
            setGolfEvents(filteredData);
          }
      }
    // console.log(filteredData)
    // setGolfHolidays(filteredData);
    };

  const locations = ["Asia", "Europe", "Africa", "North America", "South America", "Australia"];
  // const cities = ["New York", "London", "Paris", "Tokyo", "Sydney"];
  const durations = ["1-3 Days", "3-6 Days", "1 Week"];
  const packages = ["customizable", "non-customizable"];
  const sorts = ["A-Z", "Z-A"];

  const handleCheckboxSelection = (selectedOptions, setSelectedOptions, option,type) => {
    console.log(selectedLocations)
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
    );
    
  };
  
  const handleSortChange = (value) => {
   
    if (value === "A-Z") {
      var ortedData = availableEvents.sort((a, b) => {
        return a.golf_holidays.holiday_name.localeCompare(b.golf_holidays.holiday_name)
      })
    } else {
      var ortedData = availableEvents.sort((a, b) => {
        return b.golf_holidays.holiday_name.localeCompare(a.golf_holidays.holiday_name)
      })
    }
    setGolfEvents(ortedData)
    // setCurrentSortOrder(value);
    // setIsDropdownOpen(false);
    setOpenDropdown(false)
    setSelectedSorts(value)
  };
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };


  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className='listitem'>
      <div className='listitems-sec'>
        <p className='final-list-path'>Home <span className='arrow-button'><FontAwesomeIcon icon={faChevronUp} className="arrow-icon left" /></span> Golf Events</p>
        <h2 className='final-list-head'>Golf Event Packages</h2>
        <p className='final-list-subtit'>{golfContent}</p>
        <Grid container spacing={2} className='filter-final-fullbox'>
          <Grid item xs={12} className='filter-inner-box-final'>
            <div className="filter-bar-grid-final">
            <Grid container spacing={2} className='d-s-b'>
              <Grid item xs={12} md={5} lg={6} className='filter-left-final'>
                <div className="filter-options-group-final" ref={dropdownRef}>
                  <div className="dropdown-final">
                    <label htmlFor="location-final">Location:</label>
                    <div className="custom-checkbox-dropdown-final">
                    <button className="dropdown-btn-final" onClick={() => toggleDropdown("location")}>
  {selectedLocations.length > 0 ? 
    `${selectedLocations.slice(0, 1).join(", ")}${selectedLocations.length > 1 ? ` +${selectedLocations.length - 1} more` : ""}`
    : "Select Locations"}
  <FontAwesomeIcon icon={openDropdown === "location" ? faChevronUp : faChevronDown} />
</button>
                      {openDropdown === "location" && (
                        <div className="checkbox-dropdown-content-final" ref={dropdownRef}>
                          {countriess.map((location) => (
                            <label key={location}>
                              <input
                                type="checkbox"
                                checked={selectedLocations.includes(location)}
                                value={location}
                                // onChange={() => handleCheckboxSelection(selectedLocations, setSelectedLocations, location , "location")}
                                onChange={handleLocationChange}
                              />
                              {location}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="custom-checkbox-dropdown-final">
                      
                      <button className="dropdown-btn-final" onClick={() => toggleDropdown("city")}>
  {selectedCities.length > 0 ? 
    `${selectedCities.slice(0, 1).join(", ")}${selectedCities.length > 1 ? ` +${selectedCities.length - 1} more` : ""}`
    : "Select City"}
  <FontAwesomeIcon icon={openDropdown === "city" ? faChevronUp : faChevronDown} />
</button>
                      {openDropdown === "city" && (
                        <div className="checkbox-dropdown-content-final" ref={dropdownRef}>
                          {cities.map((city) => (
                            <label key={city}>
                              <input
                                type="checkbox"
                                value={city}
                                checked={selectedCities.includes(city)}
                                onChange={handleCityChange}
                              />
                              {city}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="dropdown-final">
                    <label htmlFor="duration-final">Duration:</label>
                    <div className="custom-checkbox-dropdown-final">
                      <button className="dropdown-btn-final" onClick={() => toggleDropdown("duration")}>
                        {selectedDurations.length > 0 ? selectedDurations.join(", ") : "Select Durations"}
                        <FontAwesomeIcon icon={openDropdown === "duration" ? faChevronUp : faChevronDown} />
                      </button>
                      {openDropdown === "duration" && (
                        <div className="checkbox-dropdown-content-final">
                          {durations.map((duration) => (
                            <label key={duration}>
                              <input
                                type="checkbox"
                                checked={selectedDurations.includes(duration)}
                                onChange={() => handleCheckboxSelection(selectedDurations, setSelectedDurations, duration)}
                              />
                              {duration}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div> */}
                  <div className="dropdown-final">
                    <label htmlFor="theme-final">Package:</label>
                    <div className="custom-checkbox-dropdown-final">
                      <button className="dropdown-btn-final" onClick={() => toggleDropdown("theme")}>
  {selectedPackages.length > 0 ? 
    `${selectedPackages.slice(0, 1).join(", ")}${selectedPackages.length > 1 ? ` +${selectedPackages.length - 1} more` : ""}`
    : "Select Package"}
  <FontAwesomeIcon icon={openDropdown === "theme" ? faChevronUp : faChevronDown} />
    </button>
                      {openDropdown === "theme" && (
                        <div className="checkbox-dropdown-content-final" ref={dropdownRef}>
                          {packages.map((theme) => (
                            <label key={theme}>
                              <input
                                type="checkbox"
                                value={theme}
                                checked={selectedPackages.includes(theme)}
                                onChange={handlePackageChange}
                              />
                              {theme}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6.8} lg={6} className='filter-right-final'>
                <div className="sort-by-final">
                  <div className="dropdown-final">
                    <label htmlFor="sort-by-final">Sort By:</label>
                    <div className="custom-checkbox-dropdown-final">
                      <button className="dropdown-btn-final" onClick={() => toggleDropdown("sort")}>
                        {selectedSorts.length > 0 ? selectedSorts : "Select Sort Options"}
                        <FontAwesomeIcon icon={openDropdown === "sort" ? faChevronUp : faChevronDown} />
                      </button>
                      {openDropdown === "sort" && (
                        <div className="checkbox-dropdown-content-final" ref={dropdownRef}>
                          {sorts.map((sort) => (
                            <label key={sort}>
                              <input
                                type="checkbox"
                                name="sort"
                                value={sort}
                                checked={selectedSorts.includes(sort)}
                                onChange={() => handleSortChange(sort)}
                              />
                              {sort}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
        <Grid item xs={12} lg={12} md={12}>
          <div className="package-grid final-pkg">
                {golfEvents ? golfEvents.map((events, index) => (
                  <EventsList
                  events={events}
                />
                )): <div style={{ display: 'flex', justifyContent: 'center' }} >
                <Typography fontFamily={'Nunito'} fontSize={20} fontWeight={'bold'} letterSpacing={1}>
                  No Golf Events Found !
                </Typography>
              </div>}
              </div>
          </Grid>
        </Grid>

      </div>
    </div >
  )
}

export default GolfEventsView;
