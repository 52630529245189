import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { Typography } from '@mui/material';
import footerlogo from '../assets/images/footerlogonew.png'
import foot1 from '../assets/images/foot1.png'
import foot2 from '../assets/images/foot2.png'
import foot3 from '../assets/images/foot3.png'
import foot4 from '../assets/images/foot4.png'
import foot5 from '../assets/images/foot5.png'
import twitlogo from '../assets/images/footer-twi.png'
import car from '../assets/images/car.gif'
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider'
import { useLocation, useNavigate ,Redirect } from 'react-router-dom'
import { SET_PREVIOUS_ROUTE } from '../Redux/constants/userConstants'
import { Stack } from '@mui/system';
import { Avatar, Grid } from '@mui/material'
import RegisterMenu from './RegisterMenu';
import Link from '@mui/material/Link';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/Constants'
import { toast } from 'react-toastify';
const Footer = () => {

  // const { user } = useSelector(state => state.auth)
  // const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  // let history = useHistory();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [validation, setValidation] = React.useState(false);
  const {footerMenus } =useSelector(state => state.booking)
  const [menus, setMenus] = useState(footerMenus);
  
  
  useEffect(() => {
    
     if( footerMenus !==undefined){
      setMenus(footerMenus)
     }
   }, [footerMenus])

   
  function  NavigationPath(slug){
    navigate('/'+slug)
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
    // var url ="/"+slug;
    // history.push(url);
  }
 
  const Contactus = () => {
    setAnchorElNav(null);
    navigate('/contactus')
  }; 
 
  const PaymentRefund = () => {
    setAnchorElNav(null);
    navigate('/payment-cancellations')
  };
 
  const PrivacyPolicy = () => {
    setAnchorElNav(null);
    navigate('/privacy-policy')
  };
 
  const CustomerRegister = () => {
    setAnchorElNav(null);
    navigate('/customerregistration')
  };

  const CompanyRegister = () => {
    setAnchorElNav(null);
    navigate('/b2bregistration')
  };
  const [golfContents, setGolfcontents] = useState([]);

  useEffect(() => {
    GetGolfContent(); 
  }, [])
  const GetGolfContent = () => {
      
    const payload = ["golftripz"];
    axios(API_URL+'/get-single-page',{
        method: 'POST',
        data: {slug:payload},
        headers: {
      'Content-Type': 'application/json',
       'Access-Control-Allow-Origin':'*'
       }
      })
        .then(function(response) {
            console.log("response",response)
            setGolfcontents(response.data);
          //   alert(golfContents[0].body);
          }).then(function(body) {
          });
}

  function  Subscribe(){
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
        setValidation(true);
    }else{
        setValidation(false);
        axios(API_URL+'/subscribe-newsletter',{
        method: 'POST',
        data: {email:email},
        headers: {
       'Content-Type': 'application/json'
     }
    })
      .then(function(response) {
        toast.success(response.data.message)
        }).then(function(body) {
          console.log(body);
        });
    }
  }

  const functionnavigate = (path) =>{
    navigate(path)
    window.scrollTo({
        top: 0,
        behavior: 'smooth' 
      });
  }



  return (
    <footer id="footer" className='footer'>
      <div className='full-con-width'>
        <Grid container spacing={2} className='footer-details-con'>
          <Grid item md={3} xs={12} className='footer-details-subcon'>
            <div className='footer-logo'>
                <img src={footerlogo} alt="logo" />
            </div>
            <div className='nfooter-details'>
                <p>{golfContents[0]?.body}</p>
              <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22" fill="none">
                <path d="M16.227 22C15.473 21.8936 14.7289 21.7442 14.0106 21.4872C11.6957 20.657 9.60385 19.4321 7.70316 17.8844C5.05649 15.7297 2.92893 13.1489 1.39691 10.0971C0.768689 8.84447 0.265619 7.54146 0.0695702 6.14252C-0.0857895 5.03321 -0.0155077 3.95957 0.638606 2.99353C0.706422 2.8933 0.776087 2.79123 0.859316 2.70391C1.57631 1.95002 2.27913 1.1783 3.07751 0.50804C3.90239 -0.184356 5.08547 -0.159759 5.91529 0.525258C6.23279 0.787213 6.5207 1.0793 6.80799 1.37261C7.56876 2.14864 8.37392 2.88162 9.09338 3.697C9.86217 4.56772 9.86833 5.73975 9.11989 6.62769C8.62915 7.2094 8.06813 7.72471 7.52314 8.25353C7.39244 8.38021 7.37024 8.48044 7.45286 8.64708C7.88811 9.5258 8.47503 10.3 9.11496 11.0342C10.118 12.1853 11.2333 13.2122 12.5131 14.054C12.7869 14.2342 13.0828 14.375 13.367 14.5355C13.5218 14.6228 13.6216 14.5939 13.7437 14.474C14.2856 13.9415 14.8004 13.3813 15.3762 12.8832C16.211 12.1613 17.4421 12.1423 18.2547 12.8875C19.3194 13.8634 20.3317 14.8953 21.3187 15.9474C22.2262 16.914 22.2139 18.0744 21.3606 19.0927C21.278 19.1917 21.1868 19.2833 21.0968 19.3762C20.6837 19.8011 20.2374 20.194 19.8508 20.6447C19.2059 21.3968 18.3909 21.8432 17.4138 22H16.2264H16.227Z" fill="white" />
              </svg></span>12345 67890</p>
            <p style={{'textTransform':'none'}}>
              <a href="mailto:golftripz@gmail.com" style={{ textDecoration: 'none', color: 'inherit' , display:'flex',gap:'10px' }}>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 22 19" fill="none">
                      <path d="M15.8824 0.5H5.29412C2.11765 0.5 0 2.08824 0 5.79412V13.2059C0 16.9118 2.11765 18.5 5.29412 18.5H15.8824C19.0588 18.5 21.1765 16.9118 21.1765 13.2059V5.79412C21.1765 2.08824 19.0588 0.5 15.8824 0.5ZM16.38 6.94824L13.0659 9.59529C12.3671 10.1565 11.4776 10.4318 10.5882 10.4318C9.69882 10.4318 8.79882 10.1565 8.11059 9.59529L4.79647 6.94824C4.45765 6.67294 4.40471 6.16471 4.66941 5.82588C4.94471 5.48706 5.44235 5.42353 5.78118 5.69882L9.09529 8.34588C9.9 8.99177 11.2659 8.99177 12.0706 8.34588L15.3847 5.69882C15.7235 5.42353 16.2318 5.47647 16.4965 5.82588C16.7718 6.16471 16.7188 6.67294 16.38 6.94824Z" fill="white"/>
                    </svg>
                  </span>
                  golftripz@gmail.com
              </a>
                </p>
            </div>
            <div className="footer-social-links">
              <div className="fb">
                <a href='https://www.facebook.com/golftripz'><img src={foot1} alt="img" /></a>
              </div>
              <div className="pin">
                <a href='https://in.pinterest.com/golftripz'><img src={foot2} alt="img" /></a>
              </div>
              <div className="twit">
                <a href='https://twitter.com/golftripz'><img src={foot3} alt="img" /></a>
              </div>
              <div className="linked">
                <a href='https://www.linkedin.com/company/golftripz'><img src={foot4} alt="img" /></a>
              </div>
              <div className="yout">
                <a href='https://www.youtube.com/golftripz'><img src={foot5} alt="img" /></a>
              </div>
            </div>
          </Grid>
          <Grid item md={2.5} xs={6} className='footer-details-subcon'>
            <h4>Golf Tripz</h4>
            <ul className="footer-links">

              {menus ?
                menus.map(item => (
                  <li style={{ "cursor": 'pointer' }}><span onClick={() => NavigationPath(item.slug)}>{item.name}</span></li>
                ))
                : ""}
            </ul>
            {/* <ul className="footer-links">  
              <li style={{ "cursor": 'pointer' }}><span onClick={Contactus} >Contact Us</span></li>
              <li style={{ "cursor": 'pointer' }}><span onClick={Testimonials} >Testimonials</span></li>
              <li style={{ "cursor": 'pointer' }}><span onClick={Deals} >Deals & Discounts</span></li>
              <li style={{ "cursor": 'pointer' }}><span onClick={GiftHolidays} >Gift a Holiday</span></li>
              <li style={{ "cursor": 'pointer' }}> <span onClick={Brochures} >Brochures </span></li>
              <li style={{ "cursor": 'pointer' }}><span onClick={News} >News</span></li>
              <li style={{ "cursor": 'pointer' }}><span onClick={EventTrips} >Upcoming Golf Events & Trips</span></li>
              <li style={{ "cursor": 'pointer' }}>Covid-19 Travel Updates</li>
              <li style={{ "cursor": 'pointer' }}>OCBC QR code</li>
              <li style={{ "cursor": 'pointer' }}>Quick Payments Razorpay</li>
              <li style={{ "cursor": 'pointer' }}>HDFC Bank & Golftripz Offer</li>
            </ul> */}
          </Grid>
          <Grid item md={2.5} xs={6} className='footer-details-subcon'>
            <h4>Useful Links</h4>
            <ul className="footer-links">
              <li onClick={() => {navigate('/GolfHoliday'); window.scrollTo(0, 0);}} style={{ "cursor": 'pointer' }}><span>Golf Holidays</span></li>
              <li onClick={() => {navigate('/SearchResult'); window.scrollTo(0, 0);}} style={{ "cursor": 'pointer' }}><span>Golf Courses</span></li>
              <li onClick={() => NavigationPath('golf-destination')} style={{ "cursor": 'pointer' }}>Golf Destination</li>
              <li onClick={() => NavigationPath('golf-lesson')} style={{ "cursor": 'pointer' }}>Golf Lessons</li>
              <li onClick={() => NavigationPath('hotel')} style={{ "cursor": 'pointer' }}>Hotel</li>
              <li onClick={() => NavigationPath('resorts')} style={{ "cursor": 'pointer' }}>Resorts</li>
              <li onClick={() => NavigationPath('sight-seeing')} style={{ "cursor": 'pointer' }}>Sightseeing</li>
              <li onClick={() => NavigationPath('transportaion')} style={{ "cursor": 'pointer' }}>Transportation</li>
            </ul>
          </Grid>
          <Grid item md={3} xs={12} className='footer-details-subcon'>
            <h4 className="mail-subscribe-sec">Subscribe to Our Newsletter & Receive Latest Updates </h4>
            <div className="foot-email-enter">
              <input type="email" name="" id="enter-email" onChange={(event) => setEmail(event.target.value)} placeholder="Your email address" />
              {validation == true ?
                <span className="emailvalidate" style={{ color: "red" }}>Please enter valid Email</span>
                : ""}
              <button onClick={() => Subscribe()}>Subscribe</button>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
          <div className="footer-line-tree">
            <div className="wh-line">
                <svg xmlns="http://www.w3.org/2000/svg" width="1450" height="57" viewBox="0 0 1823 57" fill="none">
                    <path d="M0 55.5444H1520C1534.33 55.8777 1569.9 51.9444 1597.5 33.5444C1632 10.5444 1681 -26.4556 1768.5 33.5444C1777 40.8777 1799.8 55.5444 1823 55.5444" stroke="#9FD650" stroke-width="2"/>
                </svg>
            </div>
            <div className="green-tree">
            <svg width="307" height="137" viewBox="0 0 418 193" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M239.808 87.8784L253.186 35L274.157 84.1259L268.011 82.42L283.196 111.759L274.519 109.03L288 139L238.589 125.509L241.977 110.395L238 115.512L246.694 84.5617L239.808 87.8784Z" fill="#5A7C29"/>
<path d="M190.315 67.6781L207.447 0L234.303 62.8751L226.432 60.692L245.879 98.2425L234.766 94.7494L247 127C227.693 121.792 207.942 118.374 188 116.788L193.093 96.4958L188 103.045L199.134 63.4328L190.315 67.6781Z" fill="#CFF19F"/>
<path d="M158.807 80.2083L172.184 27L193.153 76.4322L187.007 74.7158L202.192 104.238L193.515 101.492L204 120C188.438 117.682 172.643 117.366 157 119.061L160.977 102.865L157 108.014L165.694 76.8707L158.807 80.2083Z" fill="#5A7C29"/>
<path d="M109.28 78.564L126.152 11L152.6 73.7693L144.848 71.5897L164 109.077L153.056 105.59L162.547 117.078C139.682 120.099 125.199 122.423 107 130L112.016 107.333L107 113.872L117.965 74.326L109.28 78.564Z" fill="#CFF19F"/>
<path d="M17.5443 186.674C45.1913 172.506 73.4401 147.343 102.74 129.612C133.378 111.071 170.333 101.708 205.639 106.124C229.074 109.055 250.879 119.732 271.595 131.354C292.311 142.975 312.614 155.778 334.962 163.591C357.309 171.405 375.346 172.684 405.26 166.163L418 157C418 157 387.308 181 361.974 186.5C336.154 195 2.90794e-05 191.542 2.90794e-05 191.542L17.5443 186.674Z" fill="#9FD650"/>
</svg>

                <div className="flag-footer">
                    <svg width="30" height="109" viewBox="0 0 30 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="post" d="M26.1368 1.92019C26.1352 1.56786 26.231 1.22193 26.4136 0.920624C26.5962 0.619316 26.8586 0.374355 27.1717 0.212792C27.4848 0.051229 27.8364 -0.0206416 28.1878 0.0051147C28.5392 0.030871 28.8767 0.15325 29.1629 0.358741C29.4491 0.564233 29.6729 0.844838 29.8096 1.16956C29.9464 1.49427 29.9907 1.85046 29.9377 2.19879C29.8847 2.54712 29.7365 2.87402 29.5094 3.14339C29.2823 3.41276 28.9851 3.6141 28.6508 3.72519V108.47H27.4455V3.72519C27.0654 3.59991 26.7345 3.35813 26.4995 3.03413C26.2646 2.71013 26.1377 2.32039 26.1368 1.92019Z" fill="#E0E0E0"/>
                        <path className="flag" d="M7.0552 17.8175C9.78399 18.2379 11.7845 15.946 12.4223 13.9051C13.0111 12.0214 13.2912 10.0614 14.1183 8.26952C15.2746 5.76522 18.3372 4.60807 21.0857 4.8395C23.7743 5.09931 26.3791 5.91759 28.7332 7.24197V23.066C28.7332 23.066 23.0694 17.1854 20.5247 17.0827C16.2789 16.9113 16.51 23.8902 12.3873 24.9206C10.6087 25.3233 8.7521 25.2098 7.03589 24.5932C5.31968 23.9766 3.81525 22.8828 2.6996 21.4403C1.1727 19.3641 0.239501 16.9115 0 14.3455C0 14.3455 1.89974 17.0234 7.0552 17.8175Z" fill="#DB953F"/>
                    </svg>
                </div>
            </div>
            <div className="car">
               <img src={car} alt="" />
            </div>
        </div>
        <div className="copy-right">
            <p>© 2024 GOLFTRIPZ</p>
            <div>
              <p onClick={()=>functionnavigate('/privacy-policy')} style={{'position':'relative'}}>Privacy Policy</p>
              <p onClick={()=>functionnavigate('/termsconditions')}>Terms and Condition</p>
            </div>
        </div>
          </Grid>
      </Grid>
      </ div>
    </footer>
  )

}

export default Footer
