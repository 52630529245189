import React from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import InstantPackageDetailsPage from './InstantPackageDetailsPage';

const SearchDetailsPage = () => {

    return (
        <Layout type="inspackage" className="instantpackage">
            <InstantPackageDetailsPage count={5}/>
        </Layout>
    )
}

export default SearchDetailsPage;
