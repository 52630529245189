import React, { useState, useEffect, useCallback } from 'react'
import Layout from '../Layout'
import PassengersDetails from './PassengersDetails'
import { Grid } from '@mui/material'
import TravelDetails from './TravelDetails'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import VehicleDetails from './VehicleDetails'
import ViewMap from './ViewMap'
import ArrivalDetails from './ArrivalDetails'
import InputField from '../../components/InputField'
import { useParams } from 'react-router-dom'
import customAxios from '../../CustomAxios'
import { toast } from 'react-toastify'
import AutoComplete from '../../components/AutoComplete'
import moment from 'moment'
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux'
import { pricePlan } from '../../helper/PricePlan'
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { env } from '../../config/Constants'
import axios from 'axios'
import { getAddress, getDirection } from '../../helper/geoCode'
import AddOnServices from './AddOnServices'
import DepartureDetails from './DepartureDetails'
import PriceBreakup from './PriceBreakup'
import { LOADING } from '../../Redux/constants/userConstants'
import { tr } from 'date-fns/locale'
import reactotron from '../../ReactronConfig'
import './style.scss'


const EditTrip = ({ head }) => {

    const [contactNationality, setcontactNationality] = useState("");
    const nationalities = [
        'American',
        'British',
        'Canadian',
        'Chinese',
        'French',
        'German',
        'Indian',
        'Japanese',
        'Mexican',
        'Russian',
        'South African',
    ];



    const { id } = useParams()
    const [trip, setTrip] = useState(null)
    const [loading, setLoading] = useState(false)
    const [baseTrips, setBaseTrips] = useState(null)
    const [leadNationality, setleadNationality] = useState("");
    const [bookingData, setBookingData] = useState({
        "id": 16,
        "customer_id": 612,
        "total_price": 1648,
        "status": 1,
        "created_at": "2024-08-03 08:26:45",
        "updated_at": "2024-08-03 08:26:45",
        "details": [
            {
                "id": 51,
                "golf_course_id": 2,
                "supplier_id": 2,
                "customer_id": 612,
                "golf_course_name": "Cypress Golf Course",
                "golf_course_price": 150,
                "date": "2024-08-16",
                "start_time": "10:00:00",
                "end_time": "10:00:00",
                "tee_time": "10:00:00-10:00:00",
                "total_qolfers": 4,
                "total_price": 824,
                "additional_charges": [],
                "lead_golfer_name": "Shiva",
                "lead_golfer_nationality": "Indian",
                "lead_golfer_handicap": "0",
                "customer_name": "Kumar",
                "customer_phone": "17657575757",
                "customer_nationality": "Indian",
                "customer_remarks": "Demo remark",
                "promotions": null
            },
            {
                "id": 50,
                "golf_course_id": 1,
                "supplier_id": 2,
                "customer_id": 612,
                "golf_course_name": "Trent Golf Course",
                "golf_course_price": 100,
                "date": "2024-08-30",
                "start_time": "17:00:00",
                "end_time": "17:00:00",
                "tee_time": "17:00:00-17:00:00",
                "total_qolfers": 2,
                "total_price": 824,
                "additional_charges": [],
                "lead_golfer_name": "Shiva",
                "lead_golfer_nationality": "Indian",
                "lead_golfer_handicap": "0",
                "customer_name": "Kumar",
                "customer_phone": "17657575757",
                "customer_nationality": "Indian",
                "customer_remarks": "Demo remark",
                "promotions": null
            }
        ]})
    const [defaultValues, setDefaultValues] = useState({})
    const { user, additionalStops } = useSelector(state => state.auth)
    const { markupLists } = useSelector(state => state.markup)
    const [arrival, setArrival] = useState(null)
    const [departure, setDeparture] = useState(null)
    const [locationType, setLocationType] = useState('')
    const [locationfrom, setLocationFrom] = useState(null)
    const [locationto, setLocationTo] = useState(null)
    const [distance, setDistance] = useState(null)
    const [duration, setDuration] = useState(null)
    const [selectedVehicleId, setSelectedVehicleId] = useState('')
    const [selectedVehicle, setSelectedVehicle] = useState('')
    const [arrivalDetails, setArrivalDetails] = useState(null)


    const [balanceAmount, setBalanceAmount] = useState(0)

    const [base, setBase ] = useState('')
    const [addOnItem, setAddOnItem ] = useState('')
    const [addOnService, setAddOnService ] = useState([])
    const [adminDiscount, setAdminDiscount ] = useState('')
    const [couponDiscount, setCouponDiscount ] = useState('')
    const [extraSeats, setExtraSeats ] = useState('')
    const [tax, setTax ] = useState('')
    const [toll, setToll ] = useState('')
    const [total, setTotal ] = useState('')
    const [agentMarkup, setAgentMarkup] = useState('')
    const [additionalStopsArray, setAdditionalStopArray] = useState(null)
    const [suggList, setSuggList] = useState([])


    const dispatch = useDispatch()


    const {
        placesService,
    } = usePlacesService({
        apiKey: env === "client" ? process.env.REACT_APP_GOOGLE_KEY_LIVE : process.env.REACT_APP_GOOGLE_KEY_DEV
    });



    useEffect(() => {
        if(loading){
            dispatch({
                type: LOADING,
                payload: true
            })
        }
        else{
            dispatch({
                type: LOADING,
                payload: false
            })
        }
    }, [loading])

    


    useEffect(() => {
        searchAddress()
    }, [])

    const searchAddress = async () => {
        await customAxios.get(`search/address/${user?.id}`)
        .then(async response => {

            let sugList = [];
            response?.data?.data?.map((item, index) => {
                let place = JSON.parse(item?.geometry)
                sugList.push({
                    ...place,
                    description: place?.address
                })
            })
            setSuggList(sugList)

        })
        .catch(async error => {

        });

    }
    


    const [stops, setStops] = useState([])
    const [liveTrips, setLiveTrips] = useState([])
    const [oldTrip, setOldTrip] = useState({})
    const [priceList, setPriceList] = useState(null)


    const schema = yup.object({
        flight_no: yup.string().min(3).max(10).nullable()
    }).required();

    const { register, handleSubmit, control, formState: { errors }, getValues, setValue, setError, reset } = useForm({
        resolver: yupResolver(schema),
    });

    function calcCrow(lat1, lon1, lat2, lon2) {
        var R = 6371; // km
        var dLat = toRad(lat2 - lat1);
        var dLon = toRad(lon2 - lon1);
        var lat1 = toRad(lat1);
        var lat2 = toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;

        return d;
    }

    function toRad(Value) {
        return Value * Math.PI / 180;
    }


    const vehicleChange = (vehicleId) => {
        
        let selectedTrip = liveTrips?.find(trip => trip?.id === vehicleId)
        setBase(null);
        setAddOnService(null);
        setAddOnItem(null);
        setAdminDiscount(null)
        setCouponDiscount(null)
        setExtraSeats(null)
        //extra seat missing
        setTax(null)
        setToll(null)
        setTotal(null)
        setAgentMarkup(null)
        setSelectedVehicle(selectedTrip)
        setBase(selectedTrip?.baseRate + selectedTrip?.adminMarkup);
        setAddOnService(selectedTrip?.newAddOns);
        setAddOnItem(selectedTrip?.extraArray);
        setAdminDiscount(selectedTrip?.adminDiscount)
        setAgentMarkup(selectedTrip?.agentmarkup)
        setCouponDiscount(null)
        //extra seat missing
        setExtraSeats(selectedTrip?.extraSeat)
        setTax(selectedTrip?.tax)
        setToll(selectedTrip?.toll)
        setTotal(selectedTrip?.total)

    }


    const getPricePlans = async (bookingData, priceList) => {

        let extraseats = parseInt(bookingData?.baby) + parseInt(bookingData?.minors) + parseInt(bookingData?.childrens)

        if (bookingData.type === "point") {
            let data = {
                customer_type: user?.role === "customer" ? 3 : user?.role === "corporate" ? 1 : user?.role === "agent" ? 2 : 4,
                user_id: user?.id,
                date: moment(bookingData.pickupdate).format("DD-MM-YYYY HH:mm"),
                extraseats,
                fromLat: bookingData?.location_from?.latitude,
                fromLng: bookingData?.location_from?.longitude,
                location_type: (bookingData?.location_from?.type === 'airport' || bookingData?.location_to?.type === 'airport') ? 'airport' : 'point',
                noOfPassengers: bookingData?.passenger,
                stops: bookingData?.stops ? bookingData?.stops?.length : 0,
                toLat: bookingData?.location_to?.latitude,
                toLng: bookingData?.location_to?.longitude,
                user_type: user?.id ? user?.role === "customer" ? "Customer" : user?.role === "agent" ? 'Agent' : 'Corporate' : 'Guest',
            }

            try {
                let res = await customAxios.post(`verifycordinates`, data);

                if (res?.statusText === "OK") {
                    if (res?.data?.message === "Success") {

                        if (res?.data?.data?.length > 0) {
                            let data = res?.data?.data;
                            setBaseTrips(data)
                            let remainTrips = data?.filter(trip => trip?.id != priceList?.id)
                            //setLiveTrips([oldTrip, remainTrips])
                            let allTrips = [priceList, ...remainTrips];
                            setValue("vehicle", priceList?.id)
                            setSelectedVehicleId(priceList?.id)
                            generateVehicleTypes(allTrips, priceList?.id, bookingData)

                        }
                    }
                    else {
                        throw res?.data?.message
                    }
                }
                else {
                    throw "Network Error"
                }
            } catch (error) {
                toast.error(error)
            }


        }
        else {
            let distance;
            if (bookingData?.location_to !== null) {
                distance = calcCrow(bookingData?.location_from?.latitude, bookingData?.location_from?.longitude, bookingData?.location_to?.latitude, bookingData?.location_to?.longitude);
            } else {
                distance = 0;
            }
            let datas = {
                km: distance.toFixed(2),
                //hour: (bookinData?.durationFull?.value/3600).toFixed(1),
                hour: bookingData?.duration ? bookingData?.duration : 3,
                noOfDays: (bookingData?.duration / 24).toFixed(0),
                noOfPassengers: bookingData?.passenger,
                date: moment(bookingData.pickupdate).format("DD-MM-YYYY HH:mm"),
                user_type: user?.id ? user?.role === "customer" ? "Customer" : user?.role === "agent" ? 'Agent' : 'Corporate' : 'Guest',
                location_type: (bookingData?.location_from?.type === 'airport' || bookingData?.location_to?.type === 'airport') ? 'airport' : 'point',
                countryCode: bookingData?.location_from?.countryCode,
                countryName: bookingData?.location_from?.countryName,
                extraseats,
                customer_type: user?.role === "customer" ? 3 : user?.role === "corporate" ? 1 : user?.role === "agent" ? 2 : 4,
                user_id: user?.id
            }

            try {

                let res = await customAxios.post(`gethourlyrate`, datas);

                if (res?.statusText === "OK") {
                    if (res?.data?.message === "Success") {

                        if (res?.data?.data?.length > 0) {
                            let data = res?.data?.data;
                            setBaseTrips(data)
                            let remainTrips = data?.filter(trip => trip?.id != priceList?.id)
                            //setLiveTrips([oldTrip, remainTrips])
                            let allTrips = [priceList, ...remainTrips];
                            setValue("vehicle", priceList?.id)
                            generateVehicleTypes(allTrips, priceList?.id, bookingData)

                        }
                    }
                    else {
                        throw res?.data?.message
                    }
                }
                else {
                    throw "Network Error"
                }
            } catch (error) {
                toast.error(error)
            }


        }
    }

    const generateVehicleTypes = (trips, id, bookingData) => {
        let booking = {
            ...bookingData,
            //stops: additionalStopsArray
        }
        let old;
        if(oldTrip?.pricelist){
            old = JSON.parse(oldTrip?.pricelist)
        }
        let tripss = trips?.map(trip => {
            if(old){
                let trips;
                if(trip?.id === old?.id){
                    trips = {
                        ...trip,
                        services: JSON.parse(oldTrip?.addons)
                    }
                }
                else{
                    trips = trip
                }
                return pricePlan(trips, booking, additionalStops, user, markupLists)
            }
            else{
                return pricePlan(trip, booking, additionalStops, user, markupLists)
            }
        })


        let selected = null

        if(id){
            selected = tripss?.find(tri => tri?.id === id)
        }
        else{
            //let old = JSON.parse(oldTrip?.pricelist)
            selected = tripss?.find(tri => tri?.id === old?.id)
        }

        if(selected){
            setSelectedVehicle(selected)
            setSelectedVehicleId(selected?.id)
            setBase(selected?.baseRate + selected?.adminMarkup);
            setAddOnService(selected?.newAddOns);
            setAddOnItem(selected?.extraArray);
            setAdminDiscount(selected?.adminDiscount)
            setCouponDiscount(null)
            setExtraSeats(selected?.extraSeat)
            //extra seat missing
            setTax(selected?.tax)
            setToll(selected?.toll)
            setTotal(selected?.total)
            setAgentMarkup(selected?.agentmarkup)

        }

        setLiveTrips(tripss)

    }




    async function getTripDetails() {
        try {
            let res = await customAxios.get(`golfcourses/${id}`);
            if (res?.statusText === "OK") {
                let data = res?.data?.data;


                setBalanceAmount(data?.Balance < 0 ? data?.Balance : 0);


                //setTrip(data)
                let bookingData = JSON.parse(data?.booking_data)


                reactotron.log({bookingData})


                let priceList = JSON.parse(data?.pricelist);

                

                setSelectedVehicleId(priceList?.id)

                data.paidAmount = data?.amount

                let paid = {
                    ...data?.pricebreakups,
                    paidAmount: Math.ceil(data?.amount)
                }

                setPriceList(paid)

                setOldTrip(data)
                let locationFrom = bookingData?.location_from;
                let locationTo = bookingData?.location_to;


                setLocationFrom(locationFrom)
                setLocationTo(locationTo)
                setStops(bookingData?.stops)



                let values = {
                    pickupdate: new Date(moment(`${data?.pickupdate} ${data?.pickuptime}`, "YYYY-MM-DD hh:mm A")),
                    type: bookingData?.type,
                    location_from: locationFrom,
                    location_to: locationTo,
                    passenger: bookingData?.passenger,
                    adults: data?.adults,
                    baby: data?.baby_seats,
                    minors: data?.booster_seats,
                    childrens: data?.childrens,
                    specialinstructions: data?.specialinstructions && data?.specialinstructions != "0" ? data?.specialinstructions : "",
                    stops: bookingData?.stops ? bookingData?.stops : null,
                    duration: bookingData?.duration ? bookingData?.duration : null,
                    durationFull: bookingData?.durationFull ? bookingData?.durationFull : null,
                    arrival_method: data?.pickup_loc_type,
                    flight_type: data?.flight_type,
                    flight_no: data?.flightno,
                    flight_date: data?.flightdate ? new Date(moment(`${data?.flightdate} ${data?.flighttime}`, "DD-MM-YYYY hh:mm A")) : null,
                    time_related: data?.buffer_time,
                    passenger_name: data?.name,
                    passenger_lname: '',
                    email: data?.email,
                    country: data?.nationality,
                    mobile: data?.mobile,
                    luggage: data?.suitcase,
                    hand_carry: data?.carryon,
                    reference_number: data?.customer_reference,
                    itenary: data?.itenary,
                    departure_flight_no: data?.departure_flight_no,
                    departure_flight_date: data?.departure_flight_date ? new Date(moment(data?.departure_flight_date)) : new Date(),
                    bookingType: 'oneway',
                    returndate: null,
                    cruise_no: data?.cruise_no,
                    disembarkment: data?.disembarkment,
                    time_related: data?.buffer_time
                }

                //if(item?.arrival === "")

                
                let priceBreakup = paid;

                setBase(priceBreakup?.base_rate + parseFloat(priceBreakup?.markup));
                setAddOnService(JSON.parse(data?.addons));
                setAddOnItem(JSON.parse(data?.addonitems));
                setAdminDiscount(priceBreakup?.discount)
                setCouponDiscount(priceBreakup?.coupondiscount)
                setExtraSeats(priceBreakup?.extraSeat)
                //extra seat missing
                setTax(priceBreakup?.tax)
                setToll(priceBreakup?.toll)
                setAgentMarkup(priceBreakup?.agentmarkup)
                setTotal(priceBreakup?.total_price)

                priceList.services = JSON.parse(data?.addons)




                

                let arrivalDetails = {
                    arrival: data?.arrival,
                    buffer_time: data?.buffer_time,
                    cruise_no: data?.cruise_no,
                    pickup_loc_type: data?.pickup_loc_type,
                    disembarkment: data?.disembarkment,
                    flightno: data?.flightno,
                    flighttime: data?.flighttime,
                    flightdate: new Date(moment(data?.flightdate)),
                    flight_type: data?.flight_type,
                    airport_type: data?.departure_airport_type,
                    flightNumber: data?.departure_flight_no,
                    departure_flight_date: new Date(moment(data?.departure_flight_date))  
                }


                setArrivalDetails(arrivalDetails)

                if (bookingData?.location_from?.type === "airport") {
                    setLocationType("arrival")
                    setArrival(data?.pickup_loc_type)
                }
                else if (bookingData?.location_to?.type === "airport") {
                    setLocationType("departure")
                    setDeparture(data?.departure_airport_type)
                }
                else {
                    setLocationType(bookingData?.pickup_loc_type)
                }


                setBookingData(values)

                //getPricePlans(values, priceList)



                reset(values)
                setArrival(data?.flight_type)
                setTrip(values)


            }
            else {
                throw new Error("Network Error")
            }
        } catch (error) {
            toast.error(error)
        }

    }

    useEffect(() => {
        if(id && additionalStops){
            getTripDetails()
        }
    }, [id, additionalStops, markupLists,user])


    const changePricePlan = async () => {
        setSelectedVehicle(null)
        setSelectedVehicleId(null)
        setLiveTrips(null)
        setBase(null);
        setAddOnService(null);
        setAddOnItem(null);
        setAdminDiscount(null)
        setCouponDiscount(null)
        setExtraSeats(null)
        //extra seat missing
        setTax(null)
        setToll(null)
        setTotal(null)
        setAgentMarkup(null)
        setValue("vehicle", null)
        let data = getValues();

        reactotron.log({data})
        let bookingsData = {
            ...bookingData,
            type: data?.type,
            pickupdate: data?.pickupdate,
            location_from: data?.location_from,
            location_to: data?.location_to,
            passenger: data?.passenger,
            duration: data?.duration,
        }

        reactotron.log({bookingData, data})

        setBookingData(bookingsData)

        
        
        if (data?.location_from?.type === "airport") {
            setLocationType("arrival")
        }
        else if (data?.location_to?.type === "airport") {
            setLocationType("departure")
        }
        else {
            setLocationType(data?.pickup_loc_type)
        }
        let extraseats = parseInt(data?.baby) + parseInt(data?.minors) + parseInt(data?.childrens)
    
        if (data?.type === "point") {
            reactotron.log("in")
            if(!data?.location_from || !data?.location_to){
                return false
            }
            let inputData = {
                customer_type: user?.role === "customer" ? 3 : user?.role === "corporate" ? 1 : user?.role === "agent" ? 2 : 4,
                user_id: user?.id,
                date: moment(data.pickupdate).format("DD-MM-YYYY HH:mm"),
                extraseats,
                fromLat: data?.location_from?.latitude,
                fromLng: data?.location_from?.longitude,
                location_type: (data?.location_from?.type === 'airport' || data?.location_to?.type === 'airport') ? 'airport' : 'point',
                noOfPassengers: data?.passenger,
                stops: data?.stops ? data?.stops?.length : 0,
                toLat: data?.location_to?.latitude,
                toLng: data?.location_to?.longitude,
                user_type: user?.id ? user?.role === "customer" ? "Customer" : user?.role === "agent" ? 'Agent' : 'Corporate' : 'Guest',
            }


            reactotron.log({inputData, data})


            try {
                let res = await customAxios.post(`verifycordinates`, inputData);

                if (res?.statusText === "OK") {
                    if (res?.data?.message === "Success") {

                        if (res?.data?.data?.length > 0) {
                            let data = res?.data?.data;
                            setBaseTrips(data)
                            setValue("vehicle", null)
                            setSelectedVehicle(null)
                            setSelectedVehicleId(null)
                            setBase(null);
                            setAddOnService(null);
                            setAddOnItem(null);
                            setAdminDiscount(null)
                            setCouponDiscount(null)
                            setExtraSeats(null)
                            //extra seat missing
                            setTax(null)
                            setToll(null)
                            setTotal(null)
                            setAgentMarkup(null)
                            generateVehicleTypes(data, '', bookingsData)

                        }
                        else{
                            setLiveTrips(null)
                        }
                    }
                    else {
                        throw new Error(res?.data?.message)
                    }
                }
                else {
                    throw new Error("Network Error")
                }
            } catch (error) {
                toast.error(error)
            }
        }
        else{
            let distance = 0;
            let duration = data?.duration ? data?.duration : 3;
            let noOfDays = 0;


            if (data?.location_to !== null) {
                distance = calcCrow(data?.location_from?.latitude, data?.location_from?.longitude, data?.location_to?.latitude, data?.location_to?.longitude);
                if(data?.location_from?.countryCode !== "SG"){
                    let durations = await getDirection(data?.location_from?.place_id, data?.location_to?.place_id);
                    setDistance(durations?.distance)
                    //setDuration(durations?.duration)
                    noOfDays = parseFloat(durations?.duration?.value/86400).toFixed(2);
                    if(!data?.duration){
                        duration = durations?.duration?.value/3600
                    }
                    
                    distance = durations?.distance?.value/1000;
                }   
                
            } 
            else{
                if(data?.location_from?.countryCode !== "SG"){   
                    return false;
                }
                
            }
            


            let datas = {
                km: distance.toFixed(2),
                //hour: (bookinData?.durationFull?.value/3600).toFixed(1),
                hour: duration > 0 ? Math.ceil(duration) : 3,
                noOfDays: Math.round(noOfDays, 0),
                noOfPassengers: data?.passenger,
                date: moment(data.pickupdate).format("DD-MM-YYYY HH:mm"),
                user_type: user?.id ? user?.role === "customer" ? "Customer" : user?.role === "agent" ? 'Agent' : 'Corporate' : 'Guest',
                location_type: (data?.location_from?.type === 'airport' || data?.location_to?.type === 'airport') ? 'airport' : 'point',
                countryCode: data?.location_from?.countryCode,
                countryName: data?.location_from?.countryName,
                extraseats,
                customer_type: user?.role === "customer" ? 3 : user?.role === "corporate" ? 1 : user?.role === "agent" ? 2 : 4,
                user_id: user?.id
            }


   


            try {

                let res = await customAxios.post(`gethourlyrate`, datas);

                if (res?.statusText === "OK") {
                    if (res?.data?.message === "Success") {

                        if (res?.data?.data?.length > 0) {
                            let data = res?.data?.data;
                            setBaseTrips(data)
                            //let remainTrips = data?.filter(trip => trip?.id != priceList?.id)
                            //setLiveTrips([oldTrip, remainTrips])
                            //let allTrips = [priceList, ...remainTrips];
                            //setValue("vehicle", priceList?.id)
                            setSelectedVehicle(null)
                            setBase(null);
                            setExtraSeats(null)
                            setAddOnService(null);
                            setAddOnItem(null);
                            setAdminDiscount(null)
                            setCouponDiscount(null)
                            //extra seat missing
                            setTax(null)
                            setToll(null)
                            setTotal(null)
                            setAgentMarkup(null)
                            generateVehicleTypes(data, '', bookingsData)

                        }
                        else{
                            setLiveTrips(null)
                        }
                    }
                    else {
                        throw res?.data?.message
                    }
                }
                else {
                    throw "Network Error"
                }
            } catch (error) {
                toast.error(error)
            }

        }
    }


    const VehicleTypeChanged = (type) => {

        setTrip({
            ...trip,
            type
        })

        let data = getValues();
        if(type === "hour"){
            if(data?.locationFrom?.countryCode === "SG"){
                setValue("hour", 3);
            }
        }
    }


    const addonServiceChanged = (service) => {
        let base = baseTrips?.find(ba => ba?.id === selectedVehicle?.id)

        setAddOnService(service);
        let newTrip = {
            ...base,
            services: service?.filter(se => se.checked)
        }
        let newPrice = pricePlan(newTrip, bookingData, additionalStops, user, markupLists)


        setSelectedVehicle(newPrice)

        setBase(null);
        setAddOnService(null);
        setAddOnItem(null);
        setAdminDiscount(null)
        setCouponDiscount(null)
        //extra seat missing
        setExtraSeats(null)
        setTax(null)
        setToll(null)
        setTotal(null)
        setAgentMarkup(null)
        setBase(newPrice?.baseRate + newPrice?.adminMarkup);
        setAddOnService(newPrice?.newAddOns);
        setAddOnItem(newPrice?.extraArray);
        setAdminDiscount(newPrice?.adminDiscount)
        setCouponDiscount(null)
        setExtraSeats(newPrice?.extraSeat)
        //extra seat missing
        setTax(newPrice?.tax)
        setToll(newPrice?.toll)
        setTotal(newPrice?.total)
        setAgentMarkup(newPrice?.agentmarkup)

    }
    // const [nationalities, setNations ] = useState([
    //     'American',
    //     'British',
    //     'Canadian',
    //     'Chinese',
    //     'French',
    //     'German',
    //     'Indian',
    //     'Japanese',
    //     'Mexican',
    //     'Russian',
    //     'South African',
    // ])

    const stopsChanged = (stops) => {

        let base = baseTrips?.find(ba => ba?.id === selectedVehicle?.id)

        let newBase = {
            ...base,
            services: addOnService?.filter(se => se.checked)
        }

        let booking = {
            ...bookingData,
            stops: stops
        }

        setBookingData(booking)
        let newPrice = pricePlan(newBase, booking, additionalStops, user, markupLists)


        let allTrips = baseTrips?.map(trip => {
            if(trip?.id === base?.id){
                return newPrice
            }

            return pricePlan(trip, booking, additionalStops, user, markupLists)

            

        })

        setLiveTrips(allTrips)
        
       
        


        setSelectedVehicle(newPrice)

        setBase(null);
        setAddOnService(null);
        setAddOnItem(null);
        setAdminDiscount(null)
        setCouponDiscount(null)
        //extra seat missing
        setExtraSeats(null)
        setTax(null)
        setToll(null)
        setTotal(null)
        setAgentMarkup(null)
        setBase(newPrice?.baseRate + newPrice?.adminMarkup);
        setAddOnService(newPrice?.newAddOns);
        setAddOnItem(newPrice?.extraArray);
        setAdminDiscount(newPrice?.adminDiscount)
        setCouponDiscount(null)
        setExtraSeats(newPrice?.extraSeat)
        //extra seat missing
        setTax(newPrice?.tax)
        setToll(newPrice?.toll)
        setTotal(newPrice?.total)
        setAgentMarkup(newPrice?.agentmarkup)

        
    }


    const fromLocationChange = (value) => {
        //reactotron.log("in change", value)
        setSelectedVehicle(null)
        setSelectedVehicleId(null)
        let bookingDatas = {
            ...bookingData,
            location_from: value
        }

        setBookingData(bookingDatas)

        setLocationFrom(value)
    }


    const toLocationChange = (value) => {
        setSelectedVehicle(null)
        setSelectedVehicleId(null)
        let bookingDatas = {
            ...bookingData,
            location_to: value
        }

        setBookingData(bookingDatas)

        setLocationTo(value)
    }




    return (
        <Layout head={head}>
            <Grid container paddingY={5} >
                <Grid item md={12} xs={12} lg={6} marginY={2}>
                    <form action="">
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder='Enter your Name here'
                            />
                        </div>
                        <div className="form-group">
                                            <label htmlFor="nationality">Nationality</label>
                                            <select
                                                        onChange={(e) => setcontactNationality(e.target.value)}
                                                    >
                                                        <option value="">Select Nationality</option>
                                                        {nationalities.map((nation, index) => (
                                                            <option key={index} value={nation}>{nation}</option>
                                                        ))}
                                                    </select>
                                                    <div className='dropdownicon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                                            <path d="M17.9202 8.17969H11.6902H6.08024C5.12024 8.17969 4.64024 9.33969 5.32024 10.0197L10.5002 15.1997C11.3302 16.0297 12.6802 16.0297 13.5102 15.1997L15.4802 13.2297L18.6902 10.0197C19.3602 9.33969 18.8802 8.17969 17.9202 8.17969Z" fill="#292D32" />
                                                        </svg>
                                                    </div>
                                        </div>
                    </form>

                </Grid>
                <Grid item md={12} xs={12} lg={6} marginY={2}>
                   

                </Grid>
                <Grid item md={12} xs={12} lg={6}>
                  
                   
                </Grid>
                {bookingData?.type === "point" && <Grid item md={12} xs={12} lg={6}>
                  
                </Grid>}
                {locationto?.type === "airport" && <Grid item md={12} xs={12} lg={6}>
                   
                </Grid>}
                <Grid item md={12} xs={12} lg={6} marginY={2}>
                   
                </Grid>
                <Grid item md={12} xs={12} lg={6}>
                   
                </Grid> 
            </Grid>


        </Layout>
    )
}

export default EditTrip