import React, { useState, useEffect } from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import ServicesContent from './ServicesContent';

const Services = () => {
     const [details, setDetails] = useState({'title':'Golftripz services','meta_desc':'Golftripz services','meta_keyword':'services , golftripz , cutomer'})
    return (
        <Layout head={"Services"} details={details}>
            <ServicesContent/>   
        </Layout>
    )
}

export default Services
