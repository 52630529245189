import React, { useState, useEffect } from 'react'
import Layout from '../Layout';
import CutomPages from '../CutomPages';
import axios from 'axios';
import { API_URL, BASE_URL } from '../../config/Constants'
import './Voucher.scss'
import VoucherPage from './VoucherPage';

const Voucher = () => {
    const [details, setDetails] = useState({})
     const [loading, setLoading] = useState(true)
      useEffect(() => {
        GetContent();    
    }, [])

    const GetContent = () => {
   
        const payload = {
            slug: "about-us"
          }
        axios(API_URL+'/get-single-page',{
            method: 'POST',
            data: payload,
            headers: {
             'Content-Type': 'application/json'
           }
          })
            .then(function(response) {
                // return response.json()
                
                setDetails(response.data);
                setLoading(false)
              }).then(function(body) {
                console.log(body);
              });
          }
    return (
        <Layout head={"About Us"} details={details}>
            {/* <CutomPages pageContents={details} loading={loading} />    */}
            <VoucherPage pageContents={details} loading={loading}/>
        </Layout>
    )
}

export default Voucher;
