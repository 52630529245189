import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { API_URL, BASE_URL } from '../../config/Constants'
import Layout from '../Layout';
import CutomPages from '../CutomPages';
import MyprofileDetails from './MyprofileDetails';
import './style.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
const Myprofile = () => {

    const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(true)
    const {isAuthenticated, user } = useSelector(state => state.auth)
    const navigate = useNavigate();
    useEffect(() => {
     
      if(!isAuthenticated || user ===null){
          navigate("/");
      }
   
  }, [])
    return (
        <Layout head={"myaccount"} details={details}>
            {/* <CutomPages pageContents={details} loading={loading}/>    */}
            <MyprofileDetails/>
        </Layout>
    )
}

export default Myprofile;
