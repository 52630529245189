import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import Reactotron from '../ReactronConfig'
import { bookingReducer } from './reducers/bookingReducer'
// import { homeReducer } from './reducers/homeReducer'
import { markupReducer } from './reducers/markupReducer'
import { profileReducer } from './reducers/profileReducer'
import { topupReducer } from './reducers/topupReducer'
import { tripReducer } from './reducers/tripReducer'
import { myTransferReducer } from './reducers/myTransferReducer'
import { gettReducer } from './reducers/gettReducer'
import { currencyReducer } from './reducers/currencyReducer'
import {homeReducer} from './reducers/homeReducer'

//import reducers

import { authReducer } from './reducers/userReducer'


const appReducer = combineReducers({
    auth: authReducer,
    trip:tripReducer,
    topup:topupReducer,
    markup:markupReducer,
    profile:profileReducer,
    booking:bookingReducer,
    currency:currencyReducer,
    mytransfer: myTransferReducer,
    gett: gettReducer,
    home:homeReducer
})

const reducer = (state, action) => {
    if (action.type === 'RESET_USER') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}




const middleware = [ thunk ];

const store = createStore(reducer, compose(applyMiddleware(...middleware), Reactotron.createEnhancer()));

export default store; 