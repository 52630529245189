export const NEW_TRIP_SUCCESS = 'NEW_TRIP_SUCCESS'
export const NEW_TRIP_FAIL = 'NEW_TRIP_FAIL'

export const RESET_ERROR = 'RESET_ERROR'

export const TRIP_LIST_SUCCESS = 'TRIP_LIST_SUCCESS';
export const TRIP_LIST_FAIL = 'TRIP_LIST_FAIL';

export const COURSE_LIST_SUCCESS = 'TRIP_LIST_SUCCESS';
export const COURSE_LIST_FAIL = 'TRIP_LIST_FAIL';

export const HOLIDAY_LIST_SUCCESS = 'HOLIDAY_LIST_SUCCESS';
export const HOLIDAY_LIST_FAIL = 'HOLIDAY_LIST_FAIL';

export const UPDATE_TRIP_SUCCESS = 'UPDATE_TRIP_SUCCESS'
export const UPDATE_TRIP_FAIL = 'UPDATE_TRIP_FAIL'

export const DELETE_TRIP_SUCCESS = 'DELETE_TRIP_SUCCESS'
export const DELETE_TRIP_FAIL = 'DELETE_TRIP_FAIL'

export const TRIP_LIST_BYDATE_SUCCESS='TRIP_LIST_BYDATE_SUCCESS'
export const TRIP_LIST_BYDATE_FAIL='TRIP_LIST_BYDATE_FAIL'



export const IP_LIST_SUCCESS='IP_LIST_SUCCESS'
export const IP_LIST_FAIL='IP_LIST_FAIL'