import React, { useState, useEffect , useRef} from 'react'
import Grid from '@mui/material/Unstable_Grid2';
import CourseBookingList from './CourseBookingList';
import TransactionList from './TransactionList';
import MyBookingLists from './MyBookingLists';
import IPBookingList from './IPBookingList';
import profilepic from '../../assets/images/profile.png'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCourseBooking, getGolfHolidayBooking ,getInstantPackageBooking} from '../../Redux/actions/tripActions'
import { getPaymenyHistory} from '../../Redux/actions/bookingActions'
import { faEye, faEyeSlash ,faChevronUp ,faChevronDown} from '@fortawesome/free-solid-svg-icons';


import {updatePassword , updateProfile } from '../../Redux/actions/profileActions';
import { toast } from 'react-toastify'
import moment from 'moment';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import HolidayBookingList from './HolidayBookingList';
import PhoneInput from 'react-phone-input-2';
import { useLocation, useNavigate ,Redirect } from 'react-router-dom'
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const MyprofileDetails = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    // ----date--------------
    const navigate = useNavigate()

    // // Step 1: Set up the initial state
    // const [activeTab, setActiveTab] = useState('mydetails');

    // // Step 2: Create a function to handle tab changes
    // const handleTabChange = (tab) => {
    //     setActiveTab(tab);
    // };

    const [activeTab, setActiveTab] = useState('mydetails');
    const [showMenu, setShowMenu] = useState(false);
    const [holidayItineryDay, setHolidayItineryDay] = useState([]);


    const handleTabChange = (tab) => {
        setActiveTab(tab);
        // Do not close the menu on click
    };

    const handleMouseEnter = () => {
        setShowMenu(true);
    };

    const handleMouseLeave = () => {
        // Optionally, you can hide the menu when the mouse leaves the button
        // setShowMenu(false);
    };

    const handleMenuOptionClick = (option) => {
        // Handle menu option click
        console.log(`Selected ${option}`);
        setActiveTab('mybooking'); // Optionally set 'mybooking' as active
    };

    const { tripLists, deleteSuccess, error } = useSelector(state => state.trip)

    const { updatepasswordSuccess } = useSelector(state => state.profile)
    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (updatepasswordSuccess) {
            setOpen(true)

            dispatch({
                type: RESET_ERROR
            })
            //dispatch(getSliderLists())

        }
    }, [])

    const [profile, setProfile] = useState('mydetails');
    useEffect(() => {
        let params = {
            customer_id: user?.id
        }

        let id = user?.id
        dispatch(getCourseBooking(id))
        dispatch(getGolfHolidayBooking(id))
        dispatch(getInstantPackageBooking(id))
        dispatch(getPaymenyHistory(params))
    }, [])
    useEffect(() => {

        console.log("tripLists", tripLists)
    }, [activeTab])
    const handleChange = (e) => {
        // const { name, value, type, checked } = e.target;
        // setProfile(prevProfile => ({
        //     ...prevProfile,
        //     [name]: type === 'checkbox' ? checked : value
        // }));
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // Handle form submission
    //     console.log('Profile updated:', profile);
    // };


    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };



   

    const [page, setPage] = useState(0);
    const rowsPerPage = 10;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const getBackgroundColor = (status) => {
        switch (status) {
            case 'Pending':
                return '#684B2A';
            case 'Paid':
                return '#598B28';
            default:
                return '#000000';
        }
    };

    const [open, setOpen] = useState(false);
    
    const [password, setPassword] = useState('');
    const [cpassword, setCpassword] = useState('');
    const [preview, setPreview] = useState(null);
    const [value, setValue] = useState(null);

    const [firstname, setFirstName] = useState(user?.name);
    const [lastname, setLastName] = useState(user?.lastname);
    const [countryCode, setCountryCode] = useState(user?.country_code);
    const [mobile, setMobile] = useState(user?.mobile);
    const [gender, setGender] = useState(user?.gender);
    const [countryId, setCountryId] = useState(user?.country_id);
    const [code, setCode] = useState(user?.code);



    const [nationalities , setNationalities] = useState([]);
    const [email, setEmail] = useState(null);
    const [country, setCountry] = useState(null);
    const [travel_preference, setTravelPre] = useState(false);
    const [email_sub, setEmailub] = useState(false);
    const [app, setApp] = useState(false);
    const {dbCountry } = useSelector(state => state.currency);

    useEffect(() => {
        if(dbCountry){
            setNationalities(dbCountry)
    }
      }, [dbCountry]);

console.log(user)

    const handleMobileChange = (value) => {
        const phoneNumberObj = parsePhoneNumberFromString(`+${value}`);
        
        if (phoneNumberObj) {
            const countryCode = phoneNumberObj.countryCallingCode;
            const mobileNumber = phoneNumberObj.nationalNumber;
            setMobile(mobileNumber);
            setCode('+' + countryCode);
        }
    }
    const handleNationChange = (value) => {
     setCountryId(value?.id)
     setCountryCode(value?.country_code)
    }
console.log(countryCode);
    const handleFileChange = (e) => {

        let image = e.target.files && e.target.files[0];
        if (image && image.size < 2e6) {
            setValue(e.target.files[0])
            const objectUrl = URL.createObjectURL(e.target.files[0])
            setPreview(objectUrl)
        }
        if (image.size > 2e6) {
            alert('Please upload a file less than 2 MB')
        }
    }
    const ChangePasowrd = (e) => {
        e.preventDefault()
        if (password.length >= 8) {
            if (password === cpassword) {
                let formData = new FormData();
                formData.append("password", password);
                formData.append("userID", user.id);
                dispatch(updatePassword(formData))
                setPassword("")
                setCpassword("")
                toast.success("Password updated successfully!")
            } else {
                toast.error("Password doesn't match!")
            }
        } else {
            toast.error("Password lenght must be greater than 8 characters!")
        }
    };

    
  


    const fileRef = useRef(null)

    const [showSubMenu, setShowSubMenu] = useState(false);

    const handleMyBookingClick = () => {
        setShowSubMenu(!showSubMenu);
        // handleTabChange('mybooking');
    };

    const handleButtonClick = (tabName) => {
        if (tabName !== 'mybooking') {
            setShowSubMenu(false); // Close submenu if any button other than "My Booking" is clicked
        }
        handleTabChange(tabName);
    };

    const handleSubmit = async  (e) => {

        e.preventDefault();
        let formData = new FormData();
        if (value) {
            formData.append("image", value);
        }

        formData.append("userID", user.id);

        formData.append("name", firstname);
        formData.append("lastname", lastname);
        formData.append("country_code", countryCode);
        formData.append("mobile", mobile);
        formData.append("gender", gender);
        formData.append("country_id", countryId);
        formData.append("code", code);
        await dispatch(updateProfile(formData))
        alert('Profile updated successfully')
    };

    const functionnavigate = (path) =>{
        navigate(path)
        window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
      }

    return (
        <div className='listitem'>
            <div className='myacc-container'>
                <Grid container spacing={2} className="grid-no-mar">
                    <Grid xs={2} md={2.5} lg={2} className="max-width-full">
                        <h4 className='side-head'>My Account</h4>
                        <div className="tabs">
                            <button onClick={() => handleButtonClick('mydetails')} className={activeTab === 'mydetails' ? 'active' : ''}> <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM18.36 16.83C16.93 15.09 13.46 14.5 12 14.5C10.54 14.5 7.07 15.09 5.64 16.83C4.62 15.49 4 13.82 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.82 19.38 15.49 18.36 16.83ZM8.5 9.5C8.5 7.56 10.06 6 12 6C13.94 6 15.5 7.56 15.5 9.5C15.5 11.44 13.94 13 12 13C10.06 13 8.5 11.44 8.5 9.5Z" fill="white" />
                            </svg></span>My Profile</button>
                            <button onClick={() => handleButtonClick('changepassword')} className={activeTab === 'changepassword' ? 'active' : ''}> <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                <path d="M18.75 8V10.1C18.31 10.04 17.81 10.01 17.25 10V8C17.25 4.85 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.19 10.01 5.69 10.04 5.25 10.1V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 18.75 5.1 18.75 8Z" fill="#292D32" />
                                <path d="M18.75 10.1C18.31 10.04 17.81 10.01 17.25 10H6.75C6.19 10.01 5.69 10.04 5.25 10.1C2.7 10.41 2 11.66 2 15V17C2 21 3 22 7 22H17C21 22 22 21 22 17V15C22 11.66 21.3 10.41 18.75 10.1ZM8.71 16.71C8.52 16.89 8.26 17 8 17C7.87 17 7.74 16.97 7.62 16.92C7.49 16.87 7.39 16.8 7.29 16.71C7.11 16.52 7 16.26 7 16C7 15.87 7.03 15.74 7.08 15.62C7.13 15.5 7.2 15.39 7.29 15.29C7.39 15.2 7.49 15.13 7.62 15.08C7.99 14.92 8.43 15.01 8.71 15.29C8.8 15.39 8.87 15.5 8.92 15.62C8.97 15.74 9 15.87 9 16C9 16.26 8.89 16.52 8.71 16.71ZM12.92 16.38C12.87 16.5 12.8 16.61 12.71 16.71C12.52 16.89 12.26 17 12 17C11.73 17 11.48 16.89 11.29 16.71C11.2 16.61 11.13 16.5 11.08 16.38C11.03 16.26 11 16.13 11 16C11 15.73 11.11 15.48 11.29 15.29C11.66 14.92 12.33 14.92 12.71 15.29C12.89 15.48 13 15.73 13 16C13 16.13 12.97 16.26 12.92 16.38ZM16.71 16.71C16.52 16.89 16.26 17 16 17C15.74 17 15.48 16.89 15.29 16.71C15.11 16.52 15 16.27 15 16C15 15.73 15.11 15.48 15.29 15.29C15.67 14.92 16.34 14.92 16.71 15.29C16.75 15.34 16.79 15.39 16.83 15.45C16.87 15.5 16.9 15.56 16.92 15.62C16.95 15.68 16.97 15.74 16.98 15.8C16.99 15.87 17 15.94 17 16C17 16.26 16.89 16.52 16.71 16.71Z" fill="#292D32" />
                            </svg></span>Change Password</button>
                            {/* <button
                                onClick={handleMyBookingClick}
                            > <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                        <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM15.75 15.75H8.25C7.84 15.75 7.5 15.41 7.5 15C7.5 14.59 7.84 14.25 8.25 14.25H15.75C16.16 14.25 16.5 14.59 16.5 15C16.5 15.41 16.16 15.75 15.75 15.75ZM15.75 9.75H8.25C7.84 9.75 7.5 9.41 7.5 9C7.5 8.59 7.84 8.25 8.25 8.25H15.75C16.16 8.25 16.5 8.59 16.5 9C16.5 9.41 16.16 9.75 15.75 9.75Z" fill="#292D32" />
                                    </svg>
                                </span>
                                My Booking <span className='arrow-toggle-btn'>{showSubMenu ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>
                            </button> 
                            {showSubMenu && (
                                <div className="submenu">
                                    <button
                                        onClick={() => handleTabChange('golfcourse')}
                                        className={activeTab === 'golfcourse' ? 'active' : ''}
                                    >
                                        Course
                                    </button>
                                    <button
                                        onClick={() => handleTabChange('golfHoliday')}
                                        className={activeTab === 'golfHoliday' ? 'active' : ''}
                                    >
                                        Holiday
                                    </button>
                                    <button
                                        onClick={() => handleTabChange('instantPackage')}
                                        className={activeTab === 'instantPackage' ? 'active' : ''}
                                    >
                                        Instant Package
                                    </button>
                                </div>
                            )} */}
                             <button onClick={() => handleButtonClick('mybookings')} className={activeTab === 'mybookings' ? 'active' : ''}>
                                <span> <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                        <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM15.75 15.75H8.25C7.84 15.75 7.5 15.41 7.5 15C7.5 14.59 7.84 14.25 8.25 14.25H15.75C16.16 14.25 16.5 14.59 16.5 15C16.5 15.41 16.16 15.75 15.75 15.75ZM15.75 9.75H8.25C7.84 9.75 7.5 9.41 7.5 9C7.5 8.59 7.84 8.25 8.25 8.25H15.75C16.16 8.25 16.5 8.59 16.5 9C16.5 9.41 16.16 9.75 15.75 9.75Z" fill="#292D32" />
                                    </svg></span>My Bookings
                            </button>
                            <button onClick={() => handleButtonClick('mytransaction')} className={activeTab === 'mytransaction' ? 'active' : ''}>
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                    <path d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM6.25 14.5C6.25 14.91 5.91 15.25 5.5 15.25C5.09 15.25 4.75 14.91 4.75 14.5V9.5C4.75 9.09 5.09 8.75 5.5 8.75C5.91 8.75 6.25 9.09 6.25 9.5V14.5ZM12 15C10.34 15 9 13.66 9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12C15 13.66 13.66 15 12 15ZM19.25 14.5C19.25 14.91 18.91 15.25 18.5 15.25C18.09 15.25 17.75 14.91 17.75 14.5V9.5C17.75 9.09 18.09 8.75 18.5 8.75C18.91 8.75 19.25 9.09 19.25 9.5V14.5Z" fill="#232323" />
                                </svg></span>Transactions
                            </button>
                        </div>
                    </Grid>
                    <Grid xs={10} md={9.3} lg={10} className="myacc-cont-rgt">
                        <div className="content">
                            {activeTab === 'mydetails' &&
                                <div>
                                    <div className="profile-page">
                                        <div className="profile-header">
                                            <div className="profile-circle">
                                                <img src={preview ? preview : (user?.image ? user.image : profilepic)}  alt='alt' className="profile-img" />
                                            </div>
                                            <div className="profile-info">
                                                <h2>{user?.name} {user?.lastname}</h2>
                                                <p>Joined on  {moment(user?.created_at).format("MMM Do YYYY")}</p>
                                                <button className="edit-profile-btn"  onClick={() => fileRef.current.click()}><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                                                    <path d="M13.491 1.66602H6.50768C3.47435 1.66602 1.66602 3.47435 1.66602 6.50768V13.4827C1.66602 16.5244 3.47435 18.3327 6.50768 18.3327H13.4827C16.516 18.3327 18.3243 16.5244 18.3243 13.491V6.50768C18.3327 3.47435 16.5243 1.66602 13.491 1.66602ZM9.12435 14.591C8.88268 14.8327 8.42435 15.066 8.09102 15.116L6.04102 15.4077C5.96602 15.416 5.89102 15.4243 5.81602 15.4243C5.47435 15.4243 5.15768 15.3077 4.93268 15.0827C4.65768 14.8077 4.54102 14.4077 4.60768 13.966L4.89935 11.916C4.94935 11.5743 5.17435 11.1243 5.42435 10.8827L9.14102 7.16602C9.20768 7.34102 9.27435 7.51602 9.36602 7.71602C9.44935 7.89102 9.54102 8.07435 9.64102 8.24102C9.72435 8.38268 9.81602 8.51602 9.89102 8.61602C9.98268 8.75768 10.091 8.89102 10.1577 8.96602C10.1993 9.02435 10.2327 9.06602 10.2493 9.08268C10.4577 9.33268 10.6993 9.56602 10.9077 9.74102C10.966 9.79935 10.9993 9.83268 11.016 9.84102C11.141 9.94102 11.266 10.041 11.3743 10.116C11.5077 10.216 11.641 10.3077 11.7827 10.3827C11.9493 10.4827 12.1327 10.5743 12.316 10.666C12.5077 10.7493 12.6827 10.8243 12.8577 10.8827L9.12435 14.591ZM14.4743 9.24102L13.7077 10.016C13.6577 10.066 13.591 10.091 13.5243 10.091C13.4993 10.091 13.466 10.091 13.4493 10.0827C11.7577 9.59935 10.4077 8.24935 9.92435 6.55768C9.89935 6.46602 9.92435 6.36602 9.99102 6.30768L10.766 5.53268C12.0327 4.26602 13.241 4.29102 14.4827 5.53268C15.116 6.16602 15.4243 6.77435 15.4243 7.40768C15.416 8.00768 15.1077 8.60768 14.4743 9.24102Z" fill="#292D32" />
                                                </svg></span>Edit Profile Picture</button>
                                                <input
                                                    style={{ display: 'none' }}
                                                    ref={fileRef}
                                                    type="file"
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit} className="profile-form">
                                            <div className="form-row">
                                                <div className="form-col">
                                                    <label>
                                                        First Name 
                                                        <input type="text" name="firstName" placeholder='Enter your First name here'
                                                            value={firstname} onChange={(e)=>setFirstName(e.target.value)} />
                                                    </label>
                                                    <label>
                                                        Last Name
                                                        <input type="text" name="lastName" placeholder='Enter your Last name here' value={lastname} onChange={(e)=>setLastName(e.target.value)} />
                                                    </label>
                                                    <label>
                                                        Mobile Number
                                                        {/* <input type="tel" name="mobile" placeholder='Enter you mobile number here' value={user?.mobile} onChange={(e)=>setMobile(e.target.value)} /> */}
                                                        <PhoneInput
                                                            country={'us'}
                                                            value={user?.code + user?.mobile}
                                                            onChange={(value) => handleMobileChange(value)}
                                                        />
                                                    </label>
                                                    <label className='check-bx-condi'>
                                                        <input type="checkbox" name="terms" checked={user?.terms} />
                                                        Travel preferences selections
                                                    </label>
                                                    <label className='preference-in'>
                                                        <select name="gender" value={user?.gender}>
                                                            <option value="">Select Preferences</option>
                                                            <option value="pre1">pre1</option>
                                                            <option value="pre2">pre2</option>
                                                            <option value="pre3">Other</option>
                                                        </select><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                                            <path d="M17.9207 8.17969H11.6907H6.08072C5.12072 8.17969 4.64073 9.33969 5.32073 10.0197L10.5007 15.1997C11.3307 16.0297 12.6807 16.0297 13.5107 15.1997L15.4807 13.2297L18.6907 10.0197C19.3607 9.33969 18.8807 8.17969 17.9207 8.17969Z" fill="#292D32" />
                                                        </svg></span>
                                                    </label>
                                                </div>
                                                <div className="form-col">
                                                    <label className='gender-in'>
                                                        Gender
                                                        <select name="gender" value={user?.gender} onChange={(e)=>setGender(e.target.value)}>
                                                            <option value="">Select Gender</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Other">Other</option>
                                                        </select><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                                            <path d="M17.9207 8.17969H11.6907H6.08072C5.12072 8.17969 4.64073 9.33969 5.32073 10.0197L10.5007 15.1997C11.3307 16.0297 12.6807 16.0297 13.5107 15.1997L15.4807 13.2297L18.6907 10.0197C19.3607 9.33969 18.8807 8.17969 17.9207 8.17969Z" fill="#292D32" />
                                                        </svg></span>
                                                    </label>
                                                    <label>
                                                        Email
                                                        <input readOnly type="email" name="email" placeholder='Enter you Email here' value={user?.email} onChange={(e)=>setFirstName(e.target.value)} />
                                                    </label>
                                                    <label>
                                                        Country
                                                        <select name="country" value={countryId}   onChange={(e) => {
                                                            const selectedNation = nationalities.find(nation => nation.id === parseInt(e.target.value));
                                                            handleNationChange(selectedNation);  // Sending the whole object
                                                        }}>
                                                        <option value="">Select Nationality</option>
                                                        {nationalities.map((nation, index) => (
                                                            <option key={index} value={nation?.id}>{nation?.name}</option>
                                                        ))}
                                                        </select><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                                            <path d="M17.9207 8.17969H11.6907H6.08072C5.12072 8.17969 4.64073 9.33969 5.32073 10.0197L10.5007 15.1997C11.3307 16.0297 12.6807 16.0297 13.5107 15.1997L15.4807 13.2297L18.6907 10.0197C19.3607 9.33969 18.8807 8.17969 17.9207 8.17969Z" fill="#292D32" />
                                                        </svg></span>
                                                    </label>
                                                    <div>
                                                        <p className='para-ter-con'>By submitting information, I agree to Golftripz <span style={{ "cursor": 'pointer' }}  onClick={()=>functionnavigate('/termsconditions')}>T&Cs </span>
                                                            and <span style={{ "cursor": 'pointer' }} onClick={()=>functionnavigate('/privacy-policy')}>Privacy Policy</span>.</p>
                                                        <p className='para-ter-con'>I would like to receive marketing promotions from the
                                                            following communication channels:-</p>
                                                        <div className='myacc-side'>
                                                            <div>
                                                                <input type="checkbox" value="" />
                                                                <label for="">Email</label>
                                                            </div>
                                                            <div>
                                                                <input type="checkbox" value="" />
                                                                <label for="">Online Messaging Apps</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" className="update-btn">Update</button>
                                        </form>
                                    </div>
                                </div>}
                            {activeTab === 'changepassword' &&
                                <div>
                                    <div className="profile-page">
                                        <div className="profile-header">
                                            <div className="profile-circle">
                                                <img src={profilepic} alt="Profile" className="profile-img" />
                                            </div>
                                            <div className="profile-info">
                                                <h2>{user?.name} {user?.lastname}</h2>
                                                <p>Joined on {moment(user?.created_at).format("MMM Do YYYY")}</p>
                                                {/* <button className="edit-profile-btn"><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                                                    <path d="M13.491 1.66602H6.50768C3.47435 1.66602 1.66602 3.47435 1.66602 6.50768V13.4827C1.66602 16.5244 3.47435 18.3327 6.50768 18.3327H13.4827C16.516 18.3327 18.3243 16.5244 18.3243 13.491V6.50768C18.3327 3.47435 16.5243 1.66602 13.491 1.66602ZM9.12435 14.591C8.88268 14.8327 8.42435 15.066 8.09102 15.116L6.04102 15.4077C5.96602 15.416 5.89102 15.4243 5.81602 15.4243C5.47435 15.4243 5.15768 15.3077 4.93268 15.0827C4.65768 14.8077 4.54102 14.4077 4.60768 13.966L4.89935 11.916C4.94935 11.5743 5.17435 11.1243 5.42435 10.8827L9.14102 7.16602C9.20768 7.34102 9.27435 7.51602 9.36602 7.71602C9.44935 7.89102 9.54102 8.07435 9.64102 8.24102C9.72435 8.38268 9.81602 8.51602 9.89102 8.61602C9.98268 8.75768 10.091 8.89102 10.1577 8.96602C10.1993 9.02435 10.2327 9.06602 10.2493 9.08268C10.4577 9.33268 10.6993 9.56602 10.9077 9.74102C10.966 9.79935 10.9993 9.83268 11.016 9.84102C11.141 9.94102 11.266 10.041 11.3743 10.116C11.5077 10.216 11.641 10.3077 11.7827 10.3827C11.9493 10.4827 12.1327 10.5743 12.316 10.666C12.5077 10.7493 12.6827 10.8243 12.8577 10.8827L9.12435 14.591ZM14.4743 9.24102L13.7077 10.016C13.6577 10.066 13.591 10.091 13.5243 10.091C13.4993 10.091 13.466 10.091 13.4493 10.0827C11.7577 9.59935 10.4077 8.24935 9.92435 6.55768C9.89935 6.46602 9.92435 6.36602 9.99102 6.30768L10.766 5.53268C12.0327 4.26602 13.241 4.29102 14.4827 5.53268C15.116 6.16602 15.4243 6.77435 15.4243 7.40768C15.416 8.00768 15.1077 8.60768 14.4743 9.24102Z" fill="#292D32" />
                                                </svg></span>Edit Profile Picture</button> */}
                                            </div>
                                        </div>
                                        <form onSubmit={ChangePasowrd} className="change-pass">
                                            {/* <label>
                                                Email Address
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter your Email here"
                                                    value={profile.email}
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                            </label> */}
                                            <label>
                                                New Password
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="newPassword"
                                                    value={password}
                                                    placeholder="Enter your Password here"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} className='eye-icon'>
                                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                                </span>
                                            </label>
                                            <label>
                                                Confirm Password
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="confirmPassword"
                                                    value={cpassword}
                                                    placeholder="Enter your Password here"
                                                    onChange={(e) => setCpassword(e.target.value)}
                                                />
                                                <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} className='eye-icon'>
                                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                                </span>
                                            </label>
                                            <button type="submit" className="update-btn">Update</button>
                                        </form>
                                    </div>
                                </div>}
                            {activeTab === 'golfcourse' &&
                            <CourseBookingList />
                              }
                            {activeTab === 'mytransaction' &&
                            <TransactionList />
                            }
                            {activeTab === 'mybookings' &&
                            <MyBookingLists />
                            }

                            {/* Golf holiday tab start  */}
                            {activeTab === 'golfHoliday' &&
                            <HolidayBookingList/>
                            
                               }
                            
                            {/* Golf holiday tab end  */}
                            
                            {activeTab === 'instantPackage' &&
                            <IPBookingList />
                            // <>hi</>
                              }
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default MyprofileDetails;
