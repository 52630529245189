import { useRouteError } from "react-router-dom";
import Header from "../components/Header";
import Layout from "./Layout";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <Layout head={"Error"}>
       <div id="error-page">
      <h1>Oops!</h1>
      <h2>Permission Denied.</h2>
      {/* <p>
        <i>{error.statusText || error.message}</i>
      </p> */}
    </div>
    </Layout>
   
  );
}