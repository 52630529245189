import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import './style.scss'
import DatePicker from 'react-datepicker';
import successpg from '../../assets/images/successpg.gif'
import cached from '../../assets/images/cached.png'
import { useForm } from 'react-hook-form';
   
    const PaymentSuccess = ({ pageContents, loading }) => {
    return (
        <div className="payment-success-page">
      <div className="success-content">
      <div class="svg-container">    
    <svg class="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
        <defs>
            <linearGradient id="circleGradient" gradientTransform="rotate(94)">
                <stop offset="-35.57%" stop-color="#00acbf" />
                <stop offset="119.86%" stop-color="#9fd650" />
            </linearGradient>
        </defs>
      
        <circle class="circle" fill="url(#circleGradient)" cx="24" cy="24" r="22"/>
        <path class="tick" fill="none" stroke="#FFF" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M14 27l5.917 4.917L34 17"/>
    </svg>
</div>

        <h1>Payment Successful!</h1>
        <p>Your transaction was completed successfully. Thank you for your purchase!</p>
        <button onClick={() => window.location.href = '/Home'} className="btn-home">
          Go to Home
        </button>
      </div>
    </div>
    )
}

export default PaymentSuccess;
