import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker';
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import { Page, Text, View, Document, StyleSheet,Image, Fon } from "@react-pdf/renderer";
import voucherbg from '../../assets/images/voucherbg.png'
import voucherlogo from '../../assets/images/voucherlogo.png'



const VoucherPage = ({ pageContents ,loading }) => {

     // ----date--------------
    const [startDate, setStartDate] = useState(new Date());

    // -------------------------------
    const data = [
        {
          date: '12 Apr 2024',
          packageDescription: 'Moonah Links - Legends Course',
          price: '$100',
          golfers: 4,
          total: '$400',
        },
        {
          date: '12 Apr 2024',
          packageDescription: 'Moonah Links - Legends Course',
          price: '$150',
          golfers: 3,
          total: '$450',
        },
        {
          date: '12 Apr 2024',
          packageDescription: 'Moonah Links - Legends Course',
          price: '$150',
          golfers: 3,
          total: '$450',
        },
        {
          date: '12 Apr 2024',
          packageDescription: 'Moonah Links - Legends Course',
          price: '$150',
          golfers: 3,
          total: '$450',
        },
        {
          date: '12 Apr 2024',
          packageDescription: 'Moonah Links - Legends Course',
          price: '$150',
          golfers: 3,
          total: '$450',
        },
        
      ];


    return (
        <Document>
            <Page size="A4">
                <div className='voucher-header-sec'>
                    <div className='voucher-bgimg'>
                        <img src={voucherbg} alt="voucherbg"/>
                    </div>
                    <div className='voucher-logoimg'>
                        <img src={voucherlogo} alt="voucherbg"/>
                    </div>
                    <h2>INVOICE</h2>
                    <p>UCOMMUNE, 67 Ayer Rajah Crescent #02-10/17 Singapore - 139950</p>
                </div>
                <div className='voucher-to'>
                    <h4>To</h4>
                    <div className='to-address'>
                        <div className='to-passdetails'>
                            <p>Andrew Tate</p>
                            <p>andrewtate@gmail.com</p>
                            <p>+91 9263712321</p>
                        </div>
                        <div className='to-invoicedetails'>
                            <p>Invoice No : <span>5741</span></p>
                            <p>Date : <span>02/07/24</span></p>
                        </div>
                    </div>
                </div>
                <div className="invoice-container">
                    <table className="invoice-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Package Description</th>
                                <th>Price</th>
                                <th>Golfers</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.date}</td>
                                    <td>{row.packageDescription}</td>
                                    <td>{row.price}</td>
                                    <td>{row.golfers} Golfers</td>
                                    <td>{row.total}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='invoice-footerdetails'>
                    <div className='invoice-paymentmethod'>
                         <h4>Payment</h4>   
                         <p>Payment Method : <span>Online</span></p>
                         <p className='amt-paid-color'>Amount Paid : <span>$452.99</span></p>
                    </div>
                    <div className='invoice-total'>
                        <h4>Grand Total: <span>$140.90</span></h4>
                    </div>
                </div>
            </Page>
        </Document>
    )
}

export default VoucherPage;
