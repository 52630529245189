import React, { useState, useEffect } from 'react'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
const PageContent = ({ pageContents ,loading }) => {

    return (
        <div>
        <Container>
            <Grid container spacing={2} className="container_topspace">
                <Grid item md={12} xs={12}>
                    <div className="contactinfo_wrapper">
                        <h3 className="companyname_head">{pageContents.name}</h3>
                        {loading? "Please Wait":
                          
                             <div dangerouslySetInnerHTML={{__html:pageContents.body}} /> 
                        }              
                    </div>

                </Grid>
            </Grid>

        </Container>
        </div>

    )
}

export default PageContent;
