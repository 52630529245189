import {CURRENCYRATE , CURRENCYDB , USERDEFAULTCURRENCYCODE ,ERRORCURRENCYRATE ,COUNTRYDB , TESTIMONIALDB} from '../constants/currencyConstant'
import { LOADING } from '../constants/bookingConstants'
import axios from '../../CustomAxios'
import { API_URL } from '../../config/Constants'
import { json } from 'react-router-dom';


export const getCurrencyFromDb = (params) => async (dispatch, getState) => {
    dispatch({
        type: LOADING,
        payload: true
    });

    try {
        const response = await axios.get(API_URL + '/get-currency', {
            headers: {
                'Content-Type': 'application/json'
            },
            params: params // Use params to send query parameters with GET request
        });

        dispatch({
            type: LOADING,
            payload: false
        });

        const data = response.data;

        if (data) {
            dispatch({
                type: CURRENCYDB,
                payload: data.data
            });
        }
    } catch (error) {
        dispatch({
            type: LOADING,
            payload: false
        });
        console.error("Error fetching currencies:", error);
        // Optionally, you can dispatch an error action here if needed.
    }
};

export const getUserCurrencyCode = (data) => async (dispatch, getState) => {
    const userCurrency = JSON.parse(localStorage.getItem('userCurrency'))
    if (data && data !== '') {
        localStorage.setItem('userCurrency', JSON.stringify(data));
        dispatch({
            type: USERDEFAULTCURRENCYCODE,
            payload: data
        });
    }
    else if (userCurrency && userCurrency !== '') {
        localStorage.setItem('userCurrency', JSON.stringify(userCurrency));
        dispatch({
            type: USERDEFAULTCURRENCYCODE,
            payload: userCurrency
        });
    }
    else{
        dispatch({
            type: LOADING,
            payload: true
        });
            const currency_api_key = process.env.REACT_APP_CURRENCY_API_KEY
        try {
            const ipResponse = await fetch('https://api.ipify.org/?format=json');
            if (!ipResponse.ok) {
                await errorFunction()(dispatch, getState);
                throw new Error('Failed to fetch IP');
            }
            const ipData = await ipResponse.json();
            const ipAddress = ipData.ip;
            if(!ipAddress || ipAddress == ''){
                await errorFunction()(dispatch, getState);
            }
    
            const ipDataResponse = await fetch(`https://api.ipdata.co/${ipAddress}/currency?api-key=${currency_api_key}`);
            if (!ipDataResponse.ok) {
                await errorFunction()(dispatch, getState);
                throw new Error('Failed to fetch currency data');
            }
            const currencyData = await ipDataResponse.json();
            const baseCurrency = currencyData?.code;
            console.log(baseCurrency)
            localStorage.setItem('userCurrency', JSON.stringify(baseCurrency));

            dispatch({
                type: USERDEFAULTCURRENCYCODE,
                payload:baseCurrency
            })   
        } catch (error) {
            await errorFunction()(dispatch, getState);
            console.error('Error fetching data:', error);
        } finally {
            dispatch({
                type: LOADING,
                payload: false
            });
        }
    }
   
};


export const getCurrencyRate = (data) => async (dispatch, getState) => {
    dispatch({
        type: LOADING,
        payload: true
    });
        const currency_app_id = process.env.REACT_APP_CURRENCY_APP_ID
    try {
        const exchangeRatesResponse = await fetch(`https://openexchangerates.org/api/latest.json?app_id=${currency_app_id}&base=${data}`);
        if (!exchangeRatesResponse.ok) {
            throw new Error('Failed to fetch exchange rates');
        }
        const exchangeRatesData = await exchangeRatesResponse.json();
        dispatch({
            type: CURRENCYRATE,
            payload:exchangeRatesData?.rates
        })   
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        dispatch({
            type: LOADING,
            payload: false
        });
    }
};

 const errorFunction = (data) => async (dispatch, getState) => {
    const defaultCurrencyCode = process.env.REACT_APP_DEFAULT_CURRENCY_CODE
        dispatch({
        type: ERRORCURRENCYRATE,
        payload:defaultCurrencyCode
    }) 
    dispatch({
        type: LOADING,
        payload: false
    });
}


//for getting a countries for nationalities
export const getCountries = (params) => async (dispatch, getState) => {
    dispatch({
        type: LOADING,
        payload: true
    });

    try {
        const response = await axios.get(API_URL + '/get-country', {
            headers: {
                'Content-Type': 'application/json'
            },
            params: params 
        });

        dispatch({
            type: LOADING,
            payload: false
        });

        const data = response.data;

        if (data) {
            dispatch({
                type: COUNTRYDB,
                payload: data.data
            });
        }
    } catch (error) {
        dispatch({
            type: LOADING,
            payload: false
        });
        console.error("Error fetching currencies:", error);
        // Optionally, you can dispatch an error action here if needed.
    }
};


