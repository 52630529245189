import {TESTIMONIALDB , HOMEOTHERDETAIL , GETSEARCHDATA , SETSEARCHDATA} from '../constants/homeConstant'

export const homeReducer = (state = {}, action) => {
  switch (action.type) {
      case TESTIMONIALDB:
        return {
          ...state,
          dbTestimonial: action.payload,
        };
      case HOMEOTHERDETAIL:
        return {
          ...state,
          otherDetail: action.payload,
        };
      case GETSEARCHDATA:
        return {
          ...state,
          searchData: action.payload,
        };
      case SETSEARCHDATA:
        return {
          ...state,
          userSearchData: action.payload,
        };
      default:
          return state;
  }
}