import React, { useState, useEffect } from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import CutomPages from '../CutomPages';
import SuccessPagePopup from './SuccessPagePopup';
import { API_URL, BASE_URL } from '../../config/Constants'
import axios from 'axios';

const ContactUs = () => {
     const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(true)
   
    return (
        <Layout head={"Contact Us"} details={details}>
            <SuccessPagePopup/>
            {/* <CutomPages pageContents={details} loading={loading} />    */}
        </Layout>
    )
}

export default ContactUs
