import React , {useEffect} from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import GolfExperianceDetails from './GolfExperianceDetails';
import { useSelector, useDispatch } from 'react-redux'
import {getGolfHolidayResults } from '../../Redux/actions/bookingActions'

const SearchDetailsPage = () => {

    const dispatch = useDispatch();

    useEffect(()=>{
        getHoliday();
      },[])
      const getHoliday = async() =>{
        await dispatch(getGolfHolidayResults());
      }

    return (
        <Layout>
            <GolfExperianceDetails/>
        </Layout>
    )
}

export default SearchDetailsPage;
