import React, { useState, useEffect } from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import CutomPages from '../CutomPages';
import { API_URL, BASE_URL } from '../../config/Constants'
import axios from 'axios';
import PaymentPage from './PaymentPage';

const ContactUs = () => {
     const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(true)
    // useEffect(() => {
    //     GetAboutUContent(); 
    // }, [])
    // const GetAboutUContent = () => {
   
    //     const payload = {
    //         slug: "contactus"
    //       }
    //     axios(API_URL+'/get-single-page',{
    //         method: 'POST',
    //         data: payload,
    //         headers: {
    //          // 'Authorization': `bearer ${token}`,
    //          'Content-Type': 'application/json'
    //        }
    //       })
    //         .then(function(response) {
               
    //             setDetails(response.data);
    //             setLoading(false)
    //           }).then(function(body) {
    //             console.log(body);
    //           });
    //       }
    return (
        <Layout head={"Contact Us"} details={details}>
            <PaymentPage/>
            {/* <CutomPages pageContents={details} loading={loading} />    */}
        </Layout>
    )
}

export default ContactUs
