import React, { useState, useEffect, useRef } from 'react'
import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; 
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import navlogo from '../../src/assets/images/golfnewlogo.png'
import whatsapp from '../../src/assets/images/_whatsapp_.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faUser} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux'
import { RESET_USER } from '../Redux/constants/userConstants';
import { RESET_BOOKING } from '../Redux/constants/bookingConstants';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { getCurrencyFromDb, getUserCurrencyCode, getCurrencyRate, getCountries } from '../Redux/actions/currencyAction'
import { getUserProfile } from '../Redux/actions/userActions'
import { useLocation } from 'react-router-dom';
import { API_URL } from '../config/Constants'
import axios from 'axios'

const HomeTopBar = () => {

  const dispatch = useDispatch();
  const location = useLocation();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { cartCount } = useSelector(state => state.booking)

  const [cartcount, setCartcount] = useState(cartCount);
  useEffect(() => {
    var cartitems = JSON.parse(localStorage.getItem("cartData")) || [];
    setCartcount(cartitems?.length || 0)

  }, [cartCount, cartcount])

  useEffect(() => {
    var cartitems = JSON.parse(localStorage.getItem("cartData")) || [];
    setCartcount(cartitems?.length || 0)

  }, [])


  const { availableHolidays , availableCourses } = useSelector(state => state.booking)
const [dbCourses , setDbCourses] = useState([])
const [dbHolidays , setDbHolidays] = useState([])
  const { dbCurrencyCodes, userCurrencyCode } = useSelector(state => state.currency);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [currencies, setCurrencies] = useState([])
  const [activeMenu, setActiveMenu] = useState(null);

  useEffect(() => {
    const path = location.pathname;

    switch (path) {
      case '/GolfHoliday':
        setActiveMenu(1);
        break;
      case '/GolfHolidayDetailsPage':
        setActiveMenu(1);
        break;
      case '/SearchResult':
        setActiveMenu(2);
        break;
      case '/CourseDetailsPage':
        setActiveMenu(2);
        break;
      case '/PassangerDetailsPage':
        setActiveMenu(2);
        break;
      case '/InstantPackages':
        setActiveMenu(3);
        break;
      case '/InstantDetailsPage':
        setActiveMenu(3);
        break;
      case '/GolfEvents':
        setActiveMenu(4);
        break;
      case '/GolfExperiences':
        setActiveMenu(5);
        break;
      case '/contactus':
        setActiveMenu(6);
        break;
      case '/CartPage':
        setActiveMenu(7);
        break;
      default:
        setActiveMenu(null);
    }
  }, [location]);


  useEffect(() => {
    const getCurrencyFromDbFunction = async () => {
      await dispatch(getCurrencyFromDb());
    };
    const getCountryFromDbFunction = async () => {
      await dispatch(getCountries());
    };
    getCurrencyFromDbFunction();
    getCountryFromDbFunction();

  }, [dispatch]);

  const getCurrencyForUserFunction = async (selectedCurrency) => {
    await dispatch(getUserCurrencyCode(selectedCurrency));
  };

  useEffect(() => {
    getCurrencyForUserFunction(selectedCurrency);
  }, [dispatch]);



  const getCurrencyRayeList = async (selectedCurrency) => {
    await dispatch(getCurrencyRate(selectedCurrency));
  };
  useEffect(() => {
    if (selectedCurrency !== '') {
      getCurrencyRayeList(selectedCurrency);
    }

  }, [dispatch, selectedCurrency]);


  useEffect(() => {
    if (dbCurrencyCodes && dbCurrencyCodes.length > 0) {
      setCurrencies(dbCurrencyCodes);
    }

  }, [dbCurrencyCodes]);

  useEffect(() => {
    if (userCurrencyCode) {
      setSelectedCurrency(userCurrencyCode)
    }
  }, [userCurrencyCode])

  const [isOpen, setIsOpen] = useState(false);

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
    getCurrencyForUserFunction(currency);
    setIsOpen(false);
  };


  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { isAuthenticated, previousRoute, user } = useSelector(state => state.auth)
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const Home = () => {
    setAnchorElNav(null);
    navigate('/home')
  };
  const AboutUs = () => {
    setAnchorElNav(null);
    navigate('/aboutus')
  };
  const CartPageDetails = () => {
    setAnchorElNav(null);
    navigate('/CartPage')
  };
  const Voucher = () => {
    setAnchorElNav(null);
    navigate('/Voucher')
  };
  const HomeNew = () => {
    setAnchorElNav(null);
    navigate('/')
  };
  const Done = () => {
    setAnchorElNav(null);
    navigate('/Done')
  };
  const Services = () => {
    setAnchorElNav(null);
    navigate('/services')
  };
  const ContactUs = () => {
    setAnchorElNav(null);
    navigate('/contactus')
  };
  const InstantPackages = () => {
    setAnchorElNav(null);
    navigate('/InstantPackages')
  };
  const handleNavMenu1 = () => {
    setAnchorElNav(null);
    navigate('/dashboard')
  };
  const handleNavMenu2 = () => {
    setAnchorElNav(null);
    navigate('/wallet')
  };
  const handleNavMenu3 = () => {
    setAnchorElNav(null);
    navigate('/topup')
  };
  const handleNavMenu4 = () => {
    setAnchorElNav(null);
    navigate('/profile')
  };
  const handleNavMenu5 = () => {
    setAnchorElNav(null);
    navigate('/markup')
  };
  const handleNavMenu6 = () => {
    setAnchorElNav(null);
    navigate('/user')
  };
  const handleNavMenu7 = () => {
    setAnchorElNav(null);
    navigate('/role')
  };
  const handleNavMenu8 = async () => {
    setAnchorElNav(null);
    dispatch({
      type: RESET_USER
    })
    await localStorage.removeItem("user")
    navigate('/home')
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const CustomerRegister = () => {
    setAnchorElNav(null);
    navigate('/customerregistration')
  };

  const CompanyRegister = () => {
    setAnchorElNav(null);
    navigate('/b2bregistration')
  };

  const SearchResult = () => {
    setAnchorElNav(null);
    navigate('/golf-courses')
  };
  const GolfHoliday = () => {
    setAnchorElNav(null);
    navigate('/golf-holidays')
  };
  const GolfEvents = () => {
    setAnchorElNav(null);
    navigate('/golf-events')
  };
  const GolfExperiences = () => {
    setAnchorElNav(null);
    navigate('/golf-experiences')
  };
  const Myaccount = () => {
    setAnchorElNav(null);
    navigate('/Myacc')
  };
  const GolfHolidayNew = () => {
    setAnchorElNav(null);
    navigate('/GolfHolidayNew')
  };


  const resetdata = () => {
    dispatch({
      type: RESET_BOOKING,
    })



  }

  useEffect(()=>{
    if(user){
      dispatch(getUserProfile(user?.id))
    }
},[])

  const logout = async () => {
    dispatch({
      type: RESET_USER
    })
    await localStorage.removeItem("user")
    window.location.href = '/signin';
    // navigate('/signin')
    // BrowserRouter.push("/signin");
  }

  const [regions, setRegions] = useState({});

  function regionForHoliday(data) {
    const result = {};
    
    data.forEach(item => {
      if (item.golf_holidays) {
        item.golf_holidays.country_id.forEach((countryObj, index) => {
          const country = countryObj.name;
          const city = item.golf_holidays.city_id[index]?.name;
          
          if (country && city) {
            if (!result[country]) {
              result[country] = new Set(); 
            }
            result[country].add(city);
          }
        });
      }
    });

    return Object.fromEntries(
      Object.entries(result).map(([country, cities]) => [
        country,
        Array.from(cities).sort()
      ])
    );
  }

  function regionForEvent(data) {
    const result = {};
    
    data.forEach(item => {
      if (item.golf_holidays && item?.golf_holidays?.event_checkbox === 1) {
        item.golf_holidays.country_id.forEach((countryObj, index) => {
          const country = countryObj.name;
          const city = item.golf_holidays.city_id[index]?.name;
          
          if (country && city) {
            if (!result[country]) {
              result[country] = new Set(); 
            }
            result[country].add(city);
          }
        });
      }
    });

    return Object.fromEntries(
      Object.entries(result).map(([country, cities]) => [
        country,
        Array.from(cities).sort()
      ])
    );
  }

  function regionForExperience(data) {
    const result = {};
    
    data.forEach(item => {
      if (item.golf_holidays && item?.golf_holidays?.experience_checkbox === 1) {
        item.golf_holidays.country_id.forEach((countryObj, index) => {
          const country = countryObj.name;
          const city = item.golf_holidays.city_id[index]?.name;
          
          if (country && city) {
            if (!result[country]) {
              result[country] = new Set(); 
            }
            result[country].add(city);
          }
        });
      }
    });

    return Object.fromEntries(
      Object.entries(result).map(([country, cities]) => [
        country,
        Array.from(cities).sort()
      ])
    );
  }

  const getGolfResults = async () => {
    axios(API_URL + '/golf-courses', {
        method: 'POST',
        data: {date:new Date(new Date()).toISOString().split('T')[0],search:""},
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(function (response) {

            const { data } = response.data;

            if (data?.length > 0) {
                setDbCourses(data)
            }

        })

}
  const getHolidayResults = async () => {
    axios(API_URL + '/golf-holidays', {
        method: 'POST',
        data: {date:new Date(new Date()).toISOString().split('T')[0],search:""},
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(function (response) {

            const { data } = response.data;

            if (data?.length > 0) {
              setDbHolidays(data)
            }

        })

}

  const [courseRegions, setCourseRegions] = useState({});
  const [eventRegions, setEventRegions] = useState({});
  const [experienceRegions, setExperienceRegions] = useState({});

  function regionForCourse(data) {
    const result = {};
    
    data.forEach(item => {
      const country = item?.golf_courses?.country_id;
      const city = item?.golf_courses?.city_id;
      
      if (country && city) {
        if (!result[country]) {
          result[country] = new Set(); 
        }
        result[country].add(city);
      }
    });
  
    return Object.fromEntries(
      Object.entries(result).map(([country, cities]) => [
        country,
        Array.from(cities).sort()
      ])
    );
  }

  useEffect(() => {
    getGolfResults()
    getHolidayResults()
  }, []);

  useEffect(()=>{
    if(dbCourses && dbCourses.length > 0){
      setCourseRegions(regionForCourse(dbCourses))
    }
  },[dbCourses])

  useEffect(()=>{
    if(dbHolidays && dbHolidays.length > 0){
      setRegions(regionForHoliday(dbHolidays))
      setEventRegions(regionForEvent(dbHolidays))
      setExperienceRegions(regionForExperience(dbHolidays))
    }
  },[dbHolidays])

  const [showMegaMenu, setShowMegaMenu] = useState(false);

  const handleMouseEnter = (menuId) => {
    setActiveMenu(menuId);
    setShowMegaMenu(true);
  };

  const handleMouseLeave = () => {
    setShowMegaMenu(false);
    setActiveMenu(null);
  };

  const menuRef = useRef(null);

  // Toggle dropdown based on menu click
  const toggleMenu = (menuId) => {
    if (activeMenu === menuId) {
      // If the same menu is clicked, toggle its visibility
      setShowMegaMenu((prevShowMegaMenu) => !prevShowMegaMenu);
    } else {
      // Open the new menu and set the dropdown to visible
      setActiveMenu(menuId);
      setShowMegaMenu(true);
    }
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMegaMenu(false); // Close dropdown
        setActiveMenu(null);    // Reset active menu
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);




  // ----------------new menu---------------------------

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const mobileMenu = (
    <Drawer anchor="left" sx={{ width: '100%', '& .MuiDrawer-paper': { width: '80%' } }} open={mobileMenuOpen} onClose={handleDrawerToggle} className='menulistbox-new'>
      <List className='appbar-mob'>
        <ListItem className='menunew-closeicon'>
          <IconButton color="inherit" edge="end" onClick={handleDrawerToggle}>
            {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />} {/* Toggle between Menu and Close icon */}
          </IconButton>
        </ListItem>
        <ListItem onClick={() => toggleMenu('1')}>{activeMenu === 1 ? <Typography className='active'>Golf Holidays</Typography> : <Typography className='inactive'>Golf Holidays</Typography>}</ListItem>
        <ListItem onClick={() => toggleMenu('2')}>{activeMenu === 2 ? <Typography className='active'>Golf Courses</Typography> : <Typography className='inactive'>Golf Courses</Typography>}</ListItem>
        <ListItem onClick={() => toggleMenu('4')}>{activeMenu === 4 ? <Typography className='active'>Events</Typography> : <Typography className='inactive'>Events</Typography>}</ListItem>
        <ListItem onClick={() => toggleMenu('5')}>{activeMenu === 5 ? <Typography className='active'>Experiences</Typography> : <Typography className='inactive'>Experiences</Typography>}</ListItem>
        <ListItem onClick={() => toggleMenu('6')}>{activeMenu === 6 ? <Typography className='active'>Contact Us</Typography> : <Typography className='inactive'>Contact Us</Typography>}</ListItem>
        <ListItem onClick={() => navigate('/InstantPackages')}>{activeMenu === 3 ? <Typography className='active instant-nav'>Instant Package</Typography> : <Typography className='inactive instant-nav'>Instant Package</Typography>}</ListItem>
      </List>
    </Drawer>
  );



  return (

    <AppBar position="static" color="default" className="header-navbar">
    <div className='header-section'>
      <div className='full-con-width'>
        <Toolbar className='df-headcontent'>
          <div onClick={HomeNew} style={{cursor:"pointer"}}className="logo">
            <img src={navlogo} alt="logo" />
          </div>
          <Box sx={{ display: 'flex', alignItems: 'center' }} className="newmenu-border">
            {!isMobile && (
              
              <>
                <Button onClick={() => toggleMenu('1')}>{activeMenu === 1 ? <Typography className='active'>Golf Holidays</Typography> : <Typography className='inactive'>Golf Holidays</Typography>}</Button>
                <Button onClick={() => toggleMenu('2')}>{activeMenu === 2 ? <Typography className='active'>Golf Courses</Typography> : <Typography className='inactive'>Golf Courses</Typography>}</Button>
                <Button onClick={() => toggleMenu('4')}>{activeMenu === 4 ? <Typography className='active'>Events</Typography> : <Typography className='inactive'>Events</Typography>}</Button>
                <Button onClick={() => toggleMenu('5')}>{activeMenu === 5 ? <Typography className='active'>Experiences</Typography> : <Typography className='inactive'>Experiences</Typography>}</Button>
                <Button onClick={() => toggleMenu('6')}>{activeMenu === 6 ? <Typography className='active'>Contact Us</Typography> : <Typography className='inactive'>Contact Us</Typography>}</Button>
                <Button onClick={() => navigate('/InstantPackages')}>{activeMenu === 3 ? <Typography className='active instant-nav'>Instant Package</Typography> : <Typography className='inactive instant-nav'>Instant Package</Typography>}</Button>
              </>
            )}
            <div className='log-reg-btn'>
              {!isAuthenticated && (
                <Button color="inherit" onClick={() => navigate("/signin")}><FontAwesomeIcon icon={faUser} /></Button>
              )}
              {isAuthenticated && (
                <div className="dropdown">
                  <button onClick={toggleDropdown}><FontAwesomeIcon icon={faUser} />{user?.name.substr(0, 6)} !</button>
                  {isDropdownOpen && (
                    <div className="dropdown-menu">
                      <a onClick={() => navigate("/myacc")}>My Profile</a>
                      <a onClick={() => navigate("/myacc")}>My Booking</a>
                      <a onClick={logout}>Logout</a>
                    </div>
                  )}
                </div>
              )}
            </div>
            <IconButton color="inherit" className='cartcircle-head' onClick={CartPageDetails}>
              {activeMenu === '7' ? (
                <Typography className='active'>
                  <FontAwesomeIcon icon={faCartShopping} />
                  <span className='cartcount-header'>{cartcount}</span>
                </Typography>
              ) : (
                <Typography className='inactive'>
                  <FontAwesomeIcon icon={faCartShopping} />
                  <span className='cartcount-header'>{cartcount}</span>
                </Typography>
              )}
            </IconButton>
            <div>
            <ul className='remove-list-style'>
                          <div className="header-currency">
                            <div className="cur-sel-item" onClick={() => setIsOpen(!isOpen)}>
                              <li value={selectedCurrency}>{selectedCurrency}</li>
                              <div className="down-icon-top">
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                                    <path d="M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z" fill="#684B2A" />
                                  </svg>
                                </span>
                              </div>
                            </div>
                            {isOpen && (
                              <ul className="dropdown-menu">
                                {currencies.map((currency) => (
                                  <li value={currency?.currencyCode} onClick={() => handleCurrencyChange(currency?.currencyCode)}>
                                    {currency.currencyCode}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </ul>
            </div>
              {isMobile && (
                <>
                  <IconButton color="inherit" edge="end" onClick={handleDrawerToggle}>
                    {<MenuIcon />}
                    {/* {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />} */}
                  </IconButton>
                  {mobileMenuOpen && mobileMenu}
                </>
              )}
          </Box>
        </Toolbar>
        <div className='navbar-dropdown-final'>
          {activeMenu === '1' && showMegaMenu && (
        <div>
          <div className={`finalheader-dropdown ${showMegaMenu ? 'open' : ''}`} ref={menuRef}>
          {Object.keys(regions).length > 0 && (
          Object.entries(regions).map(([country, cities]) => (
              <div className="finalheader-dropdown-column" key={country}>
                <h4>{country}</h4>
                {cities?.length > 0 && (
                  <ul>
                    {cities.map((city) => (
                      <li key={`${country}-${city}`} onClick={()=>{navigate(`/golf-holidays/${city}`)}}>{city}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))
          )}
          </div>
        </div>
          )}
          {activeMenu === '2' && showMegaMenu && (
        <div>
          <div className={`finalheader-dropdown ${showMegaMenu ? 'open' : ''}`}>
          {Object.keys(courseRegions).length > 0 && (
          Object.entries(courseRegions).map(([country, cities]) => (
              <div className="finalheader-dropdown-column" key={country}>
                <h4>{country}</h4>
                {cities?.length > 0 && (
                  <ul>
                    {cities.map((city) => (
                      <li key={`${country}-${city}`} onClick={()=>{navigate(`/golf-courses/${city}`)}}>{city}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))
          )}
          </div>
        </div>
          )}
          {activeMenu === '4' && showMegaMenu && (
        <div>
          <div className={`finalheader-dropdown ${showMegaMenu ? 'open' : ''}`}>
          {Object.keys(eventRegions).length > 0 && (
          Object.entries(eventRegions).map(([country, cities]) => (
              <div className="finalheader-dropdown-column" key={country}>
                <h4>{country}</h4>
                {cities?.length > 0 && (
                  <ul>
                    {cities.map((city) => (
                      <li key={`${country}-${city}`} onClick={()=>{navigate(`/golf-events/${city}`)}}>{city}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))
          )}
          </div>
        </div>
          )}
          {activeMenu === '5' && showMegaMenu && (
        <div>
          <div className={`finalheader-dropdown ${showMegaMenu ? 'open' : ''}`}>
          {Object.keys(experienceRegions).length > 0 && (
          Object.entries(experienceRegions).map(([country, cities]) => (
              <div className="finalheader-dropdown-column" key={country}>
                <h4>{country}</h4>
                {cities?.length > 0 && (
                  <ul>
                    {cities.map((city) => (
                      <li key={`${country}-${city}`} onClick={()=>{navigate(`/golf-experiences/${city}`)}}>{city}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))
          )}
          </div>
        </div>
          )}
          </div>
      </div>
    </div>
  </AppBar>
  );
};
export default HomeTopBar;
