import React, { useEffect, useState } from 'react'
import { env } from '../config/Constants';
import { Autocomplete, FormGroup, IconButton, Input, ListItem, TextField, Typography } from '@mui/material';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { makeStyles, styled, withStyles } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import { usePlace } from '../hooks/usePlace';
import { useDebounce } from 'use-debounce';
import reactotron from '../ReactronConfig';


const useStyles = makeStyles(() => ({
    noBorder: {
        borderRadius: 0,
        border: 0,
    },
    resize: {
        fontSize: 12,
        paddingLeft: 2
    }
}));



const AutoComplete = ({ fieldName, placeholder, control, error, onPlaceChange, values, suggList }) => {

    const classes = useStyles();

    const [place, setPlace] = useState('')

    const [search, setSearch] = React.useState(null);

    const [value] = useDebounce(search, 400);

    reactotron.log({values})

    useEffect(() => {
        getPlacePredictions({ input: value });
    }, [value])



    

    const [options, setOptions] = useState([])


    useEffect(() => {

        if(values){
            
            setPlace(values)
            setOptions(suggList ? [values, ...suggList] : [values])
        }
        else{
            setPlace(null)
            setOptions(suggList ? suggList : [])
        }
    }, [values])
    

   


    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: env === "client" ? process.env.REACT_APP_GOOGLE_KEY_LIVE : process.env.REACT_APP_GOOGLE_KEY_DEV
    });


    const placeSelect = (place)  => {
        if (place?.place_id){
            placesService?.getDetails(
                {
                    placeId: place?.place_id,
                },
                (placeDetails) => {
                    
                    let index = placeDetails?.address_components?.map((res) => res).map((res) => res?.types.includes('country')).indexOf(true)
                    let result = placeDetails?.address_components?.[index]

                    let details = {
                        address: place?.description,
                        latitude: placeDetails.geometry.location.lat(),
                        longitude: placeDetails.geometry.location.lng(),
                        type: placeDetails.types.includes('airport') ? 'airport' : 'point',
                        countryCode: result?.short_name,
                        countryName: result?.long_name,
                        utcOffset: placeDetails?.utc_offset_minutes,
                        place_id: place?.place_id,
                        description: place?.description,
                        offset: placeDetails?.utc_offset_minutes,
                    }
                    onPlaceChange(details)
                }
            );
        }
    }



    useEffect(() => {
        // fetch place details for the first element in placePredictions array
        if (place?.place_id){
            placesService?.getDetails(
                {
                    placeId: place?.place_id,
                },
                (placeDetails) => {

                    reactotron.log({placeDetails})

                    let index = placeDetails?.address_components?.map((res) => res).map((res) => res?.types.includes('country')).indexOf(true)
                    let result = placeDetails?.address_components?.[index]
                    let details = {
                        address: placeDetails?.formatted_address,
                        latitude: placeDetails.geometry.location.lat(),
                        longitude: placeDetails.geometry.location.lng(),
                        type: placeDetails.types.includes('airport') ? 'airport' : 'point',
                        countryCode: result?.short_name,
                        countryName: result?.long_name,
                        utcOffset: placeDetails?.utc_offset_minutes,
                        place_id: place?.place_id,
                        description: placeDetails?.formatted_address,
                        offset: placeDetails?.utc_offset,
                    }

                    reactotron.log({details, placeDetails})
                    onPlaceChange(details)
                }
            );
            //onPlaceChange(values)
        }
        //onPlaceChange(values)
           
    }, [place?.place_id]);


    // useEffect(() => {
    //   if(place){
    //     //onPlaceChange(place)
    //   }
    // }, [place])
    







    return (
        <FormGroup style={{ marginRight: 5, marginLeft: 5, marginBottom: 1 }} >
            <Typography sx={{ fontSize: '12px' }} >{placeholder}</Typography>
            <Controller
                name={fieldName}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                    //setValues([value])
                    return (
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={placePredictions?.length > 0 ? placePredictions : options}
                            value={place}
                            onChange={(event, newValue) => {
                                //reactotron.log({ newValue})
                                placeSelect(newValue)
                            }}
                            filterOptions={(x) => x}
                            getOptionLabel={(item) => item?.description ? item?.description : ''}
                            renderOption={(props, option) => (
                                <Typography key={option?.place_id} {...props} padding={0.5} style={{ cursor: 'pointer' }} className={classes.resize}>{option?.description}</Typography>
                            )}
                            sx={{
                                // border: "1px solid blue",
                                "& .MuiOutlinedInput-root": {
                                    // border: "1px solid yellow",
                                    borderRadius: "0",
                                    padding: "0"
                                },
                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                    border: "0px solid #eee"
                                }
                            }}
                            popupIcon={null}
                            renderInput={(params) => <TextField fullWidth {...params}
                                variant='standard'
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    classes: {
                                        input: classes.resize,
                                    }
                                }}
                                placeholder={placeholder}
                                style={{ backgroundColor: '#fff', paddingLeft: 5, border: '1px solid #ccc', borderRadius: 4, width: '98%', height: 30, justifyContent: 'center' }}
                                //label={params?.formatted_address} 
                                onChange={(evt) => {
                                    //reactotron.log({evt})
                                    setSearch(evt.target.value);
                                }}
                                // onChange={(evt) => {
                                //     getPlacePredictions({ input: evt.target.value, types: ['geocode', 'establishment'] });
                                //     //setValue(evt.target.value);
                                // }} 
                            />}
                            

                        />
                       )
            }}
            /> 
            {error && <Typography color={"red"} fontSize={10}>{error?.message}</Typography>}
        </FormGroup>
    )
}

export default AutoComplete