import { isArray } from "lodash";
import { VEHICLETYPE_IMG } from "../config/Constants";
import { calculateExtra } from "./extraCharges";
import { calculateReturnExtra } from "./extraReturnCharges";

export const vehicleRates = (datas, bookingData, additionalStops, user, markupLists) => {
    let pricePlans = [];
    console.log("rates",datas);
    datas?.map(item => {
        let agentmarkup = 0;
        let adminMarkup = 0;
        let tax = 0;
        let total = 0;
        let additionalStop = 0;
        let adminDiscount = 0;
        let extraSeat = item?.extraSeatRate ?  Math.ceil(item?.extraSeatRate) : 0
        let totalWithoutTax = 0;
        let returnTotalWithoutTax = 0;

        let addons = []

        if(item?.day == 1 && bookingData?.type === "hour"){
            addons = item?.addOnServices?.map(adds => {
                return {
                    ...adds,
                    rate: Math.ceil((parseFloat(bookingData?.duration)/24).toFixed(0) * parseFloat(adds?.rate))
                }
            })
        }
        else{
            addons = item?.addOnServices
        }

        let toll = item?.tollrate ? Math.ceil(item?.tollrate) : 0;

        if(additionalStops){
            additionalStop = isArray(bookingData?.stops) ? bookingData?.stops?.length * additionalStops : 0;
        }

        let rate = bookingData?.type === "hour" ? Math.ceil((parseFloat(item?.rate) * parseFloat(bookingData?.duration)) + additionalStop) : Math.ceil(item?.rate) + additionalStop
       
        let extras = calculateExtra(item, bookingData?.pickupdate, bookingData?.type, bookingData?.duration, parseFloat(item?.rate), 'price');
        let extraArray = calculateExtra(item, bookingData?.pickupdate, bookingData?.type, bookingData?.duration, parseFloat(item?.rate), 'array');
       
        if(user?.role === "agent" && markupLists){
            if(markupLists?.type === "fixed"){
                agentmarkup = Math.ceil(markupLists?.value);
            }
            else{
                agentmarkup = Math.ceil(((rate + extras + toll + extraSeat) / 100) * parseFloat(markupLists?.value))
            }
        }

        if (item?.markUpDetails && item?.markUpDetails?.length > 0) {
            if (item?.markUpDetails[0]?.value_type === "Percentage") {
                adminMarkup = Math.ceil(((rate + extras + toll + extraSeat) / 100) * parseFloat(item?.markUpDetails[0]?.value))
            }
            else {
                adminMarkup = Math.ceil(item?.markUpDetails[0]?.value)
            }
        }
        else if (item?.defaultMarkup) {
            adminMarkup = Math.ceil(((rate + extras + toll + extraSeat) / 100) * parseFloat(item?.defaultMarkup?.value))
        }

        if(item?.discountData){
            let ddata = item?.discountData;
            if(ddata?.discount_type === "percentage"){
                let totalAmount = rate + adminMarkup + agentmarkup + toll + extras + extraSeat;
                adminDiscount = Math.ceil((totalAmount/100) * parseFloat(ddata?.discount))
            }
            else{
                adminDiscount += Math.ceil(ddata?.discount)
            }
        }


        totalWithoutTax = Math.ceil(rate + adminMarkup + agentmarkup + extras + toll - adminDiscount + extraSeat);

        tax = Math.ceil(((totalWithoutTax) / 100) * parseFloat(item?.tax?.value))

        total = Math.ceil(totalWithoutTax + tax);
       
        let returnAdminMarkup = 0, returnAgentMarkup = 0, returnAdminDiscount = 0, returnTax = 0, returnTotal = 0;
        let returnExtra = 0, returnExtraArray = [];
        if (bookingData?.bookingType === "return") {
            // total =0;
            let returntoll = item?.return?.tollrate ? Math.ceil(item?.return?.tollrate) : 0;
            let returnrate = bookingData?.type === "hour" ? Math.ceil((parseFloat(item?.return?.rate) * parseFloat(bookingData?.duration)) + additionalStop) : Math.ceil(item?.return?.rate) + additionalStop
            returnExtra = calculateReturnExtra(item, bookingData?.returndate, bookingData?.type, bookingData?.duration, parseFloat(item?.return?.rate), 'price');
            returnExtraArray = calculateReturnExtra(item, bookingData?.returndate, bookingData?.type, bookingData?.duration, parseFloat(item?.return?.rate), 'array');
            if(user?.role === "agent"){
                if(markupLists?.type === "fixed"){
                    returnAgentMarkup = Math.ceil(markupLists?.value);
                }
                else{
                    returnAgentMarkup = Math.ceil(((returnrate + returnExtra + returntoll + extraSeat) / 100) * parseFloat(markupLists?.value))
                }
            }

            if (item?.return?.markUpDetails && item?.return?.markUpDetails?.length > 0) {
                if (item?.return?.markUpDetails[0]?.value_type === "Percentage") {
                    returnAdminMarkup = Math.ceil(((returnrate + returnExtra + returntoll + extraSeat) / 100) * parseFloat(item?.return?.markUpDetails[0]?.value))
                }
                else {
                    returnAdminMarkup = Math.ceil(item?.return?.markUpDetails[0]?.value)
                }
            }
            else if (item?.return?.defaultMarkup) {
                returnAdminMarkup = Math.ceil(((returnrate + returnExtra + returntoll + extraSeat) / 100) * parseFloat(item?.return?.defaultMarkup?.value))
            }
          

            if(item?.return?.discountData){
                let ddata = item?.return?.discountData;
                if(ddata?.discount_type === "percentage"){
                    let totalAmount = returnrate + returnAdminMarkup + returnAgentMarkup + returntoll + returnExtra + extraSeat;
                    returnAdminDiscount = Math.ceil((totalAmount/100) * parseFloat(ddata?.discount))
                }
                else{
                    returnAdminDiscount = Math.ceil(ddata?.discount)
                }
            }
           
            returnTotalWithoutTax = Math.ceil(returnrate + returnAdminMarkup + returnAgentMarkup + returnExtra + returntoll - returnAdminDiscount + extraSeat);
          
            returnTax = Math.ceil(((returnTotalWithoutTax) / 100) * parseFloat(item?.return?.tax?.value))
          
            // let returnPrice=item?.return?.rate;
            returnTotal = Math.ceil(returnTotalWithoutTax + returnTax);
          
        }
       

        let finaltotal =total + returnTotal;
    

        let data = {
            id: item?.id,
            vehicleType: item?.vehicledetails?.vehicle_type?.vehicle_type,
            vehicleTypePrice: `${item?.vehicledetails?.vehicle_type?.vehicle_type} (S$${total})`,
            baseRate: bookingData?.type === "hour" ? Math.ceil(parseFloat(item?.rate) * parseFloat(bookingData?.duration)) : Math.ceil(item?.rate),
            additionalStop,
            agentmarkup,
            adminMarkup,
            adminDiscount,
            extras,
            extraArray,
            extraSeat,
            toll,
            totalWithoutTax,
            tax,
            total,
            returnBaseRate: bookingData?.bookingType === "return" ? bookingData?.type === "hour" ? Math.ceil(parseFloat(item?.rate) * parseFloat(bookingData?.duration)) : Math.ceil(item?.rate) : 0,
            returnAdditionalStop: additionalStop,
            returnAgentMarkup,
            returnAdminMarkup,
            returnAdminDiscount,
            returnExtra,
            returnExtraArray,
            returnExtraSeat: extraSeat,
            returnToll: bookingData?.bookingType === "return" ? toll : 0,
            returnTotalWithoutTax,
            returnTax,
            returnTotal,
            finaltotal,
            vehicleTypeId: item?.vehicledetails?.vehicle_type?.id ? item?.vehicledetails?.vehicle_type?.id : null,
            vehicleId: item?.vehicledetails?.veh_details?.vehicle_id ? item?.vehicledetails?.veh_details?.vehicle_id : null,
            driverId: item?.vehicledetails?.veh_details?.driver_id ? item?.vehicledetails?.veh_details?.driver_id : null,
            description: item?.vehicledetails?.description,
            passengers: item?.vehicledetails?.vehicle_type?.no_of_passenger,
            checkin: item?.vehicledetails?.vehicle_type?.no_check_in,
            vehicleImage : `${VEHICLETYPE_IMG}${item?.vehicledetails?.vehicle_type?.image}`,
            priceplan: item,
            supplier_id: item?.supplier_id ? item?.supplier_id : null,
            currencyId: item?.currencyId ? item?.currencyId : "SGD", 
            taxPer: parseFloat(item?.tax?.value),
            vehicletype: item?.vehicledetails?.vehicle_type?.vehicle_type,
            service_type: item?.service_type,
            addOnServices: addons,
            currencySymbol: item?.currencySymbol,
            no_hand_carry: item?.vehicledetails?.vehicle_type?.no_hand_carry,
            bookingType: "PAL",
            day: item?.day
        }


        pricePlans.push(data);
        

    })


    return pricePlans;
}