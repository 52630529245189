import React, { useState } from 'react'
import { Grid, Modal, Typography, Button, Box, FormGroup, IconButton } from '@mui/material'
import InputField from '../../components/InputField'
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import DropdownInput from '../../components/DropdownInput';
import { useDispatch, useSelector } from 'react-redux';
import { addNewUser } from '../../Redux/actions/userActions';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import BorderBox from '../../components/BorderBox';
import PhoneInputs from '../../components/PhoneInputs';

const AddUser = ({ openAddModal, closeAddModal }) => {
    const location = useLocation();
    const { user, loading } = useSelector(state => state.auth)
            const [country, setCountry] = useState(null);
    const dispatch = useDispatch()

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: '25px',
        border: '1px solid #fff',
        p: 4,
        height: '671',
        width: '35%'
    };
    const schema = yup.object({
        name: yup.string().required('User name required').max(30, 'Maximum  character exceeded'),
        email: yup.string().required('Email required').max(30, 'Maximum  character exceeded'),
        phone: yup.string().required('Phone required').max(30, 'Maximum  character exceeded')
    }).required();

    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            customer_type: user?.customer_type,
            is_corporate: user?.is_corporate,
            parent_id: user?.id
        },
        mode: 'onChange'
    });
    const onSubmit = (data) => {
        let values = {
            customer_type: user?.customer_type,
            is_corporate: user?.is_corporate,
            parent_id: user?.id,
            name:data.name,
            email:data.email,
            phone:country.phonenumber,
            country_code:country.callingCode
        }
        dispatch(addNewUser(values))
    };

    const changePhone = (value) => {
        setCountry(value)
    }

    return (
        <Modal open={openAddModal} onClose={closeAddModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"  >
            <Box sx={style} >
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '1%' }}>

                    <IconButton onClick={closeAddModal} style={{ alignSelf: 'flex-end', flex: 0.05 }}>
                        <CloseIcon />
                    </IconButton>
                </div>


                <BorderBox title=" ADD NEW USER">

                    <Grid container spacing={0.5} >
                        <Grid item xs={12} md={6}>
                            <InputField
                                fieldLabel="Name"
                                fieldName="name"
                                control={control}
                                error={errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputField
                                fieldLabel="Email"
                                fieldName="email"
                                control={control}
                                error={errors.email}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <PhoneInputs
                                fieldLabel="Phone"
                                fieldName="phone"
                                control={control}
                                error={errors.phone}
                                required
                                onChangePhone={changePhone}
                            />
                        </Grid>
                    </Grid>


                </BorderBox>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 20 }}>
                    <Button type="submit" disabled={loading} variant="filled" style={{ backgroundColor: '#333333', borderRadius: 4, height: 30, padding: 5 }} onClick={handleSubmit(onSubmit)}>
                        <Typography className='buttonText'>{loading ? 'LOADING...' : "SAVE"}</Typography>
                    </Button>
                </div>





            </Box>
        </Modal>
    )
}

export default AddUser
