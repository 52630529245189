import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout';
import GolfEventsView from './GolfEventsView';
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants'
import {getGolfHolidayResults } from '../../Redux/actions/bookingActions'
import { useLocation, useParams } from 'react-router-dom';


const GolfEventsList = () => {
     const [details, setDetails] = useState({'title':'Golftripz Events','meta_desc':'Golftripz Events','meta_keyword':'golftripz , events'})
     const dispatch = useDispatch();
     const location = useLocation()
    const { country } = useParams();
    useEffect(() => {
        if (location.pathname.startsWith("/golf-events/")) {
            const country = location.pathname.split("/golf-events/")[1];
            functionForCountry(country);
        }
        else {
            getevents()
        }
        
      }, [country]);

      const functionForCountry = async(country) => {
        const Apidata = {
            search: country,
        };
             await  dispatch(getGolfHolidayResults(Apidata));
     }

      const getevents=async()=>{
        let bookinData = JSON.parse(localStorage.getItem("eventsearch"));
        var searchdate=new Date();
        if(bookinData?.holidaystartdate){
            searchdate =bookinData?.holidaystartdate;
        }
        const ApiData = {
            'search':bookinData?.eventsearch,
            'date':new Date(searchdate).toISOString().split('T')[0]
        }
         dispatch({
             type: BOOKING_DATA,
             payload: ApiData
         })
         await  dispatch(getGolfHolidayResults(ApiData));
      }
    return (
        <Layout head={"GolfEvents List"} details={details} type="golfEvents">
            <GolfEventsView/>
        </Layout>
    )
}

export default GolfEventsList;
