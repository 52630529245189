import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout';
import GolfHolidayView from './GolfHolidayView';
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants'
import {getGolfHolidayResults } from '../../Redux/actions/bookingActions'
import { useLocation , useParams } from 'react-router-dom';

const GolfHolidayList = () => {
     const [details, setDetails] = useState({'title':'Golftripz holidays','meta_desc':'Golftripz holidays','meta_keyword':'holidays , golftripz'})
     const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(new Date());
    const location = useLocation();
    const { country } = useParams();
   
    const { userSearchData }  = useSelector(state => state.home)
     
     var search = localStorage.getItem("holidaysearch");	
     const getGolfCourseData = async() => {
        let bookinData = JSON.parse(localStorage.getItem("holidaysearch"));
        
        if (bookinData === null || bookinData === '') {

             var data = {
                 'search':"",
                 'date':new Date(new Date()).toISOString().split('T')[0]
             }
             dispatch({
                 type: BOOKING_DATA,
                 payload: data
             })
             await dispatch(getGolfHolidayResults(data));
         }else{
            const ApiData = {
                'search':bookinData.holidaysearch,
                'date':new Date(bookinData.holidaystartdate).toISOString().split('T')[0]
            }
             dispatch({
                 type: BOOKING_DATA,
                 payload: ApiData
             })
             await  dispatch(getGolfHolidayResults(ApiData));
         }
        
     }
     const getGolfCourseData2 = async() => {
        const holidaySearch = userSearchData?.search;
        const holidayDate = userSearchData?.date;
        const data = {
            holidaysearch: holidaySearch,
            holidaystartdate: holidayDate
        };
        const Apidata = {
            search: holidaySearch,
            date: new Date(holidayDate).toISOString().split('T')[0]
        };
        localStorage.setItem("holidaysearch", JSON.stringify(data));
             dispatch({
                 type: BOOKING_DATA,
                 payload: data
             })
             await  dispatch(getGolfHolidayResults(Apidata));
     }
     const functionForCountry = async(country) => {
        const Apidata = {
            search: country,
        };
             await  dispatch(getGolfHolidayResults(Apidata));
     }

     useEffect(() => {
        if (location.pathname.startsWith("/golf-holidays/")) {
            const country = location.pathname.split("/golf-holidays/")[1];
            functionForCountry(country);
        }
        else if (userSearchData && userSearchData !== '' && (userSearchData?.type === 'holiday' || userSearchData?.type ==='country')) {
            getGolfCourseData2()
        } 
        else{
        getGolfCourseData()

        }
      }, [userSearchData , country]);



    return (
        <Layout details={details} type='golfHoliday' className='golf-pad-remove'>
            <GolfHolidayView/>
        </Layout>
    )
}

export default GolfHolidayList;
