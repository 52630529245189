import React, { useEffect , useState } from 'react';
import { Grid } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch , useSelector } from 'react-redux';
import { SELECTED_PACKAGES, SET_SELECTED_PACKAGE } from '../../Redux/constants/bookingConstants';
import instantli2 from '../../assets/images/image 135.png';
import btnimg1 from '../../assets/images/btn1.png';
import btnimg2 from '../../assets/images/btn2.png';
import moment from 'moment';
import Noimage from "../../assets/images/no-img.png";
const PackageList = ({ list }) => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const { InstantPackagesInputs }  = useSelector(state => state.booking)

    const {userCurrencyCode , currencyRate} = useSelector(state => state.currency);
    const [currencyCode , setCurrencyCode] = useState('')
    const [currencyRateList , setCurrencyRateList] = useState([]);
    const [hotellist , setHotels] = useState([]);
  
    useEffect(() => {
      if(userCurrencyCode){
          setCurrencyCode(userCurrencyCode)
    }
    }, [userCurrencyCode]);
  
    useEffect(() => {
      if(currencyRate){
        setCurrencyRateList(currencyRate)
    }
    }, [currencyRate]);


    const fnctionConvertingPriceWithCurrency = (price , currency) =>{  
        const currencyRate = currencyRateList[currency];
        const convertedPrice =  price / currencyRate
        return convertedPrice.toFixed(2);
    }

    useEffect(() => {
        // checkWeekEnd()
        // console.log("inputs",InstantPackagesInputs)
        // console.log("list",list)
        // if(list?.golf_hotel?.length ===1){
        //     var hotels=[];
        //     var duplicate =list?.golf_hotel;
        //     // hotels.push(duplicate)
        //     var duplicates =list?.golf_hotel;
        //     hotels=[...duplicate,...duplicates]
        //     console.log("hotels",hotels)
        //     setHotels(hotels)
        // }else{
            setHotels(list?.golf_hotel)
        // }
    }, [list]);

    const InstantDetailsPage = (hotelid) => {
      
       
        var hotel_Details=list?.golf_hotel?.filter(hotel => hotel.id === hotelid);
      
        var packageData={
            id:list?.id,
            // value:list?.pack_type,
            value:InstantPackagesInputs?.days?.length+ "Nights | " +list?.golf_course?.length + "Rounds",
            supplier_id:list?.golf_hotel?.supplier_id,
            state_id:list?.golf_hotel?.state_id,
            city_id:list?.golf_hotel?.city_id,
            cover_image:hotel_Details?.thumbnail_images,
            description:list?.description,
            terms_conditions:list?.terms_conditions,
            slug:list?.slug,
            meta_title:hotel_Details?.meta_title,
            meta_keyword:hotel_Details?.meta_keyword,
            meta_description:hotel_Details?.meta_description,
            status:list?.status,
            country_relation:list?.country_relation,
            state_relation:list?.state_relation,
            city_relation:list?.city_relation,
            supplier_relation:list?.supplier_relation,
            // price:hotel_Details?.room[0]?.roomrates?.weekdays_cost_price,
            golf_hotel:hotel_Details,
            golf_course:list?.golf_course
        }
        console.log(hotel_Details)
        dispatch({
            type: SELECTED_PACKAGES,
            payload: packageData
        });
        dispatch({
            type: SET_SELECTED_PACKAGE,
            payload: {
                selectedPackage: packageData
            }
        });
        setAnchorElNav(null);
        navigation('/InstantDetailsPage');
    };

    const eventsettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const isWeekday = date => date.getDay() % 6 !== 0;
    const isWeekend = date => date.getDay() % 6 === 0;
    const checkWeekEnd=()=> { 
        // var init_date=InstantPackagesInputs[0]?.date;
       
        // alert(isWeekday(new Date(init_date)));
    } 
    return (
        <Grid container spacing={1} className='instant-list-boxes'>
            <Grid item xs={12} sm={12} lg={2}>
                <Grid item xs={12} sm={12} className='ins-head-buttoncombo'>
                    <div className='btncombo'>
                        <div className='btn1-ins'>
                            <img onError={(e) => {e.target.onerror = null;e.target.src = Noimage; }} src={btnimg1} alt="" />
                        </div>
                        <div className='btn2-ins'>
                            <img onError={(e) => {e.target.onerror = null;e.target.src = Noimage; }} src={btnimg2} alt="" />
                        </div>
                        <button> {list?.pack_type}</button>
                   </div>
                    
                </Grid>
                <Grid item xs={12} sm={12} className='ins-box-firstins'>
                    <p>Courses Included:</p>
                    {/* <ul>
                    {list?.golf_instant_golf_relation?.map((courses, index) => (
                        <li key={index}><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <rect width="14" height="14" rx="7" fill="#598B28" />
                            <path d="M6.33333 9.25L4 7.01596L4.81667 6.23404L6.33333 7.68617L10.1833 4L11 4.78192L6.33333 9.25Z" fill="white" />
                        </svg></span>{courses?.golf_relation?.golf_course_name}</li>
                    ))}
                </ul> */}

                    <ul>
                        {list?.golf_course?.slice(0, 5).map((courses, index) => (
                            <li key={index}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <rect width="14" height="14" rx="7" fill="#598B28" />
                                        <path d="M6.33333 9.25L4 7.01596L4.81667 6.23404L6.33333 7.68617L10.1833 4L11 4.78192L6.33333 9.25Z" fill="white" />
                                    </svg>
                                </span>
                                {courses?.golf_course_name}
                            </li>
                        ))}
                        {list?.golf_course?.length > 5 && (
                            <li>
                                <span>+{list.golf_course.length - 5} more</span>
                            </li>
                        )}
                    </ul>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} lg={10} className='ins-list-secbx'>
                <Grid item xs={12} className='ins-head-buttoncombo'>
                    <h5>{InstantPackagesInputs?.days?.length} Nights | {list?.golf_course?.length} Rounds</h5>
                </Grid>
                <Grid item xs={4} lg={12} sm={4} className='ins-list-slider without'>
                {list?.golf_hotel?.length <= 2 || list?.golf_hotel?.length <= 1 ?
                    hotellist?.map((hotels, index) => (
                        <Grid item xs={12} sm={12} lg={3.7} key={index} className="instant-list-box">
                            <div className='ins-li-img'>
                                <img onError={(e) => {e.target.onerror = null;e.target.src = Noimage; }} src={hotels?.thumbnail_images || instantli2} alt="hotel" />
                            </div>
                            <div className='ins-listcard-full'>
                                <div className='ins-licard-head'>
                                    <div className='ins-licard-head1'>
                                        <h4>{hotels?.hotel_name}</h4>
                                    </div>
                                </div>
                                <div className="instant-book-sec">
                                    <div className='ins-li-ratedis'>
                                        <p>From</p>
                                       
         <p className='ins-li-pacrate'>{currencyCode}<strong>{fnctionConvertingPriceWithCurrency(hotels?.room[0]?.roomrates?.weekdays_cost_price , hotels?.room[0]?.roomrates?.currency?.currencyCode)}</strong></p>
                                    </div>
                                    <button onClick={()=>InstantDetailsPage(hotels?.id)}>More Info</button>
                                </div>
                            </div>
                        </Grid>
                    )):""
                    }  
                    </Grid>
                <Grid item xs={12} lg={12} sm={12} className='ins-list-slider'>
                    
                    {list?.golf_hotel?.length > 2 ?
                    <Slider {...eventsettings}>
                        {hotellist?.map((hotels, index) => (
                            <div key={index} className="instant-list-box">
                                <div className='ins-li-img'>
                                    <img onError={(e) => {e.target.onerror = null;e.target.src = Noimage; }} src={hotels?.thumbnail_images || instantli2} alt="hotel" />
                                </div>
                                <div className='ins-listcard-full'>
                                    <div className='ins-licard-head'>
                                        <div className='ins-licard-head1'>
                                            <h4>{hotels?.hotel_name}</h4>
                                        </div>
                                    </div>
                                    <div className="instant-book-sec">
                                        <div className='ins-li-ratedis'>
                                            <p>From</p>
                                           
             <p className='ins-li-pacrate'>{currencyCode}<strong>{fnctionConvertingPriceWithCurrency(hotels?.room[0]?.roomrates?.weekdays_cost_price , hotels?.room[0]?.roomrates?.currency?.currencyCode)}</strong></p>
                                        </div>
                                        <button onClick={()=>InstantDetailsPage(hotels?.id)}>More Info</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                    : list?.golf_hotel?.length <=0 ? ("No Hotels found in this Package") :""
                    }
                </Grid>
                <Grid item xs={12} className='ins-last-conditionpara'>
                    <p>Wish to book only golf & transfers? Click on customize to select only golf</p>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default PackageList;
