import React, { useState, useEffect } from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import GolfEventsDetails from './GolfEventsDetails';
import {getGolfHolidayResults } from '../../Redux/actions/bookingActions'
import { useSelector, useDispatch } from 'react-redux'

const SearchDetailsPage = () => {
    const [details, setDetails] = useState({'title':'GolfHoliday','meta_desc':'GolfHoliday details','meta_keyword':'GolfHoliday , golftripz , GolfHoliday'})

    const dispatch = useDispatch();

    useEffect(()=>{
        getHoliday();
      },[])
      const getHoliday = async() =>{
        await dispatch(getGolfHolidayResults());
      }

    return (
        <Layout head={"GolfEvent Details"} type='golfHoliday' details={details}>
            <GolfEventsDetails/>
            {/* <>Event details</> */}
        </Layout>
    )
}

export default SearchDetailsPage;
