import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
// import Banner from '../../components/Banner'
// import HomeHeader from '../../components/HomeHeader';
// import HomeTopBar from '../../components/HomeTopBar';
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout';
import PassangerDetails from './PassangerDetails';


const PassangerDetailsPage = () => {
     const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(true)
    const {courseBookingInputs,coureBookingSuccess,selectedCourse } =useSelector(state => state.booking)
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (!selectedCourse) {
    //         getLocalStorageData()
    //     }
    // }, [])
    // const getLocalStorageData = async () => {
       
    //     if (selectedCourse || courseBookingInputs) {
    //         navigate("/SearchResult");
    //     }
       
    // }

    return (
        <Layout head={"Contact Us"} details={details}>
            <PassangerDetails/>
        </Layout>
    )
}

export default PassangerDetailsPage
