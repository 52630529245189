import React, { useState } from 'react'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import './style.scss'
import { Button, Grid, Typography, Stack } from '@mui/material'
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ErrorIcon from '@mui/icons-material/Error';
import InputField from '../../components/InputField'
import { fontWeight } from '@mui/system'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TopBar from '../../components/TopBar'
import PersonalDetails from './PersonalDetails'
const Profile = ({ head }) => {
    const [details, setDetails] = useState({'title':'Golftripz Dashboard','meta_desc':'Golftripz Dashboard','meta_keyword':'Dashboard , golftripz'})
    return (
        <Layout head={head} details={details}>
            {/* <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflow: 'hidden' }}>
                <h1 className='subheading'><span class='underline'>Personal</span> Details</h1>
            </Grid> */}
              <Stack className="container_topspace" >
              <h1 class='underline'>Personal Details</h1>
            <PersonalDetails />
            </Stack>
        </Layout>
    )
}

export default Profile
