import React, { useState, useEffect ,useCallback} from 'react'
import TopBar from '../../components/TopBar'
import { Container } from '@mui/system'
import TableRow from '@mui/material/TableRow'
import HomeHeader from '../../components/HomeHeader'
import { Grid, Typography, Box, Button } from '@mui/material'
import './style.scss'
import { useSelector, useDispatch } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { CLEAR_PREVIOUS_ROUTE } from '../../Redux/constants/userConstants';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import modifysearchbg from '../../assets/images/modifysearchbg.png'

const ModifySearchInstant = () => {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [golfers, setGolfers] = useState(1);
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [isContinueClicked, setIsContinueClicked] = useState(false);
    const [daysArray, setDaysArray] = useState([]);
    const { previousRoute } = useSelector(state => state.auth);
    const location = useLocation();
    const dispatch = useDispatch();
    // useEffect(() => {
    //     var start = moment("2024-08-05", "YYYY-MM-DD");
    //     var end = moment("2024-08-10", "YYYY-MM-DD");
    //     //Difference in number of days
    //    alert(moment.duration(end.diff(start)).asDays());
    //   }, [])
    const handleSearch = (e) => {
        var start = moment(startDate, "YYYY-MM-DD");
        var end = moment(endDate, "YYYY-MM-DD");
        alert(moment.duration(end.diff(start)).asDays());
        var days = moment.duration(end.diff(start)).asDays();
        e.preventDefault();
        var daysArray = [];
        for (let i = 0; i < days; i++) {

            daysArray.push(i)
        }
        alert(daysArray)
        setDaysArray(daysArray)
        setIsSearchClicked(true); // Show the .full-con-width div
    };

    const handleContinue = () => {
        setIsSearchClicked(false);
        setIsContinueClicked(true);
    };

    const InstantDetailsPage = () => {
        setAnchorElNav(null);
        navigation('/InstantDetailsPage')
    };

    const navigation = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const { user } = useSelector(state => state.auth);

    const handleScroll = useCallback(_.throttle(() => {
        const header = document.querySelector('.modifysearchstick');
        const toggleClass = 'is-sticky';
        const currentScroll = window.pageYOffset;
        if (header) {
            if (currentScroll > 100) {
             
                header.classList.add(toggleClass);
            } else {
                header.classList.remove(toggleClass);
            }
        }
    }, 100), []);
    
      useEffect(() => {
        // Set header data
       
        if (previousRoute && previousRoute === location.pathname) {
            dispatch({ type: CLEAR_PREVIOUS_ROUTE });
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [previousRoute, location.pathname, dispatch, handleScroll]);

    return (
        <div className='modifysearchstick ins-modi'>
            <form className='modifysearch-new'>
                <div className='modisearch-bg'>
                    <img src={modifysearchbg} alt="img" />
                </div>
                <div className='home-content modify-search'>
                    <div className="search-box homesearch">
                        <div className="search-option">
                            <div>
                                <label className='search-lab' htmlFor="search">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                            <path d="M12.0009 13.4295C13.724 13.4295 15.1209 12.0326 15.1209 10.3095C15.1209 8.58633 13.724 7.18945 12.0009 7.18945C10.2777 7.18945 8.88086 8.58633 8.88086 10.3095C8.88086 12.0326 10.2777 13.4295 12.0009 13.4295Z" stroke="#292D32" strokeWidth="1.5" />
                                            <path d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z" stroke="#292D32" strokeWidth="1.5" />
                                        </svg>
                                    </span>
                                </label>
                                <input type="text" name="search" placeholder='Search' />
                            </div>
                            <div className='searchbx-comdiv'>
                            <div className="searchbx-date">
                                <div className="date-pick">
                                    <label htmlFor="date-picker" id='date-picker'>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                                <path d="M8 2V5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16 2V5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M3.5 9.08984H20.5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.6937 13.6992H15.7027" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.6937 16.6992H15.7027" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.9945 13.6992H12.0035" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.9945 16.6992H12.0035" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.29529 13.6992H8.30427" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.29529 16.6992H8.30427" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </span>
                                    </label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="dd - MM - yy"
                                        placeholderText="From Date"
                                        isClearable
                                        className="date-picker-input"
                                        minDate={new Date()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='searchbx-comdiv'>
                            <div className="searchbx-date">
                                <div className="date-pick">
                                    <label htmlFor="date-picker" id='date-picker'>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                                <path d="M8 2V5" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16 2V5" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M3.5 9.08984H20.5" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.6937 13.6992H15.7027" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.6937 16.6992H15.7027" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.9945 13.6992H12.0035" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.9945 16.6992H12.0035" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.29529 13.6992H8.30427" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.29529 16.6992H8.30427" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        dateFormat="dd - MM - yy"
                                        placeholderText="To Date"
                                        isClearable
                                        className="date-picker-input"
                                        minDate={new Date()}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='searchbx-comdiv'>
                            <div className="searchbx-golfers">
                                <label htmlFor="date-picker" id='date-picker'>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                            <path d="M8 2V5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16 2V5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M3.5 9.08984H20.5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M15.6937 13.6992H15.7027" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M15.6937 16.6992H15.7027" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M11.9945 13.6992H12.0035" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M11.9945 16.6992H12.0035" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.29529 13.6992H8.30427" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.29529 16.6992H8.30427" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                </label>
                                <select value={golfers} onChange={(e) => setGolfers(e.target.value)} className="golfers-dropdown">
                                    {[...Array(10).keys()].map(num => (
                                        <option key={num + 1} value={num + 1}>{num + 1} Golfer{num + 1 > 1 ? 's' : ''}</option>
                                    ))}
                                </select>
                                <span className="dropdown-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                        <path d="M7 10l5 5 5-5H7z" fill="#292D32" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        </div>
                        <div className="search-btn">
                            <button type="submit" onClick={handleSearch}>Search</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ModifySearchInstant;
